import React from 'react';
import SLDSDatepicker from '../Datepicker/SLDSDatepicker';
import cx from 'classnames';
import { Controller, Control } from 'react-hook-form';

import styles from './DateSelect.module.css';

type Props = {
	control: Control;
	name: string;
	label: string;
	placeholder: string;
	optional?: boolean;
	disabled?: boolean;
	value?: Date
};

const DateSelect: React.FC<Props> = ({
	control,
	name,
	label,
	placeholder,
	value,
	...rest
}) => {
	const { optional } = rest;

	return (
		<Controller
			name={name}
			control={control}
			render={(props: any) => {
				const {
					field,
					fieldState: { error },
				} = props;
				return (
					<div>
						<SLDSDatepicker
							{...field}
							{...rest}
							placeholder={placeholder}
							label={label}
							controlled
							onChange={(v: any) => {
								field.onChange(v.formattedDate);
							}}
							className={cx({
								'slds-has-error': !!error,
							})}
							optional={optional}
							value = {value}
						/>

						{error && (
							<div className="slds-has-error">
								<div className={cx(styles.helper, 'slds-form-element__help')}>
									{error.message}
								</div>
							</div>
						)}
					</div>
				);
			}}
		/>
	);
};

export default DateSelect;
