import {
	Icon,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import { ReactComponent as VoidIcon } from 'assets/icons/ic-void.svg';
import cx from 'classnames';
import styles from './TransactionTable.module.css';

export default function transactionColumnConfig({
	handleView,
	handleVoid,
	canVoid,
}) {
	const getContentRow = (status = '') => {
		if (status) {
			if (status.toLocaleLowerCase() === 'voided') {
				return 'Voided';
			}
		}

		return 'Void';
	};

	const getContainerWithStyle = (value) => {
		if (value) {
			switch (value.toLocaleLowerCase()) {
				case 'success':
					return <div className={styles.pill_approved}>{value}</div>;
				case 'processing':
					return <div className={styles.pill_processing}>{value}</div>;
				case 're-processing':
					return <div className={styles.pill_reprocess}>{value}</div>;
				case 'voided':
					return <div className={styles.pill_rejected}>{value}</div>;
				case 'failed':
					return <div className={styles.pill_rejected}>{value}</div>;
				default:
					return <div>{value}</div>;
			}
		}

		return <div></div>;
	};

	return [
		{
			Header: 'Transaction Date and Time',
			id: 'created_at',
			sortable: true,
			width: '15%',
		},
		{
			Header: 'Client Reference No.',
			id: 'crn',
			sortable: true,
			width: '15%',
		},
		{
			Header: 'Transaction Reference No.',
			id: 'trn',
			sortable: true,
			width: '15%',
		},
		{
			Header: 'TPAID',
			id: 'tpaid',
			sortable: true,
		},
		{
			Header: 'Channel Name',
			id: 'channel_name',
			sortable: true,
		},
		{
			Header: 'Biller Name',
			id: 'biller_name',
			sortable: true,
		},
		{
			Header: 'Account No.',
			id: 'arn',
			sortable: true,
		},
		{
			Header: 'Amount',
			id: 'amount',
			sortable: true,
		},
		{
			Header: 'Status',
			id: 'status',
			sortable: true,
			Cell: ({ value, row }) => {
				return getContainerWithStyle(value);
			},
		},
		{
			Header: 'Actions',
			id: 'actions',
			accessor: ({ id }) => id,
			Cell: ({ value, row }) => {
				return (
					<>
						<div>
							<Tooltip
								id="tooltip"
								align="bottom"
								content="View"
								triggerClassName={styles.toolTip}
							>
								<button
									className={styles.viewDetails}
									onClick={() => handleView(value)}
								>
									<Icon
										assistiveText={{ label: 'Account' }}
										category="utility"
										name="picklist_type"
										size="small"
										className={cx(styles.iconBtn, styles.viewDetailsIcon)}
									/>
								</button>
							</Tooltip>
							<Tooltip
								id="tooltip"
								align="bottom"
								content={getContentRow(row.original.status)}
								triggerClassName={styles.toolTip}
							>
								<button
									className={cx(styles.viewDetails)}
									onClick={() => {
										const _status = getContentRow(row.original.status);
										if (_status !== 'Voided') {
											// checkRoles(value);
											handleVoid(value);
										}
									}}
								>
									<VoidIcon
										className={cx(styles.iconBtn, styles.viewVoidIcon)}
									/>
								</button>
							</Tooltip>
						</div>
					</>
				);
			},
		},
	];
}
