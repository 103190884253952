import PanelPlaceholder from '../Placeholders/PanelPlaceholder/PanelPlaceholder';
import ChannelDetails from 'components/ChannelDetails/ChannelDetails';
import PrimaryTab from 'components/Tabs/PrimaryTab/PrimaryTab';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
const VIEW_CHANNEL_SCOPE_NAME = 'bip.get.channel.primary.info';

const ChannelTab: React.FC = () => {
	const scopes: any = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	);

	const viewChannelScope = useMemo(
		() =>
			(scopes as { scope: string }[]).find(
				({ scope }) => scope === VIEW_CHANNEL_SCOPE_NAME
			),
		[scopes]
	);
	// const roles = JSON.parse(localStorage.getItem('userInfo'))
	const panelPlaceholderProps = {
		title: 'No Selected Channel',
		subtitle: 'Please select a channel.',
	};
	const primaryTabProps = {
		tabs: [
			{
				title: 'Channel Performance',
				component: <PanelPlaceholder {...panelPlaceholderProps} />,
			},
			{
				title: 'Channel Details',
				component: <ChannelDetails />,
			},
			{
				title: 'Audit Trail',
				component: <PanelPlaceholder {...panelPlaceholderProps} />,
			},
		],
		defaultIndex: 1,
	};

	const indexing = primaryTabProps.tabs.splice(
		primaryTabProps.tabs.findIndex((v) => v.title === 'Channel Details'),
		1
	);
	const storage = localStorage.getItem('userInfo');
	if (storage != null) {
		if (viewChannelScope) {
			primaryTabProps.tabs.splice(1, 0, {
				title: 'Channel Details',
				component: <ChannelDetails />,
			});
		} else {
			indexing;
		}
	}
	return <PrimaryTab {...primaryTabProps} />;
};

export default ChannelTab;
