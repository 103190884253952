import React, { useEffect, useRef, useState } from 'react';

type Toggle = {
	value: boolean;
	valueOn: () => void;
	valueOff: () => void;
	toggleValue: () => void;
};
export const useToggle = (initialValue = false): Toggle => {
	const [value, setValue] = useState(initialValue);
	const valueOn = () => setValue(true);
	const valueOff = () => setValue(false);
	const toggleValue = () => setValue(!value);
	return {
		value,
		valueOn,
		valueOff,
		toggleValue,
	};
};

type UseStateInitialValue<T> = T | null | undefined;

type SingleSelection<T> = {
	value: UseStateInitialValue<T>;
	setValue: React.Dispatch<React.SetStateAction<UseStateInitialValue<T>>>;
	resetValue: () => void;
	clearValue: () => void;
};
type SingleSelectionArgs<T> = {
	initialValue?: any;
	valueOnClear?: UseStateInitialValue<T>;
};
export const useSingleSelection = <T>({
	initialValue = undefined,
	valueOnClear = undefined,
}: SingleSelectionArgs<T> = {}): SingleSelection<T> => {
	const [value, setValue] = useState<UseStateInitialValue<T>>(initialValue);
	return {
		value,
		setValue,
		resetValue: () => setValue(initialValue),
		clearValue: () => setValue(valueOnClear),
	};
};

interface DropdownProps {
	isJoin?: boolean;
	joinChar?: string;
	triggerFn?: (v: any) => any;
}

type DropdownType = {
	value: Array<any>;
	onChange: (v: any) => Array<any> | string;
};

export const useDropdownState = ({
	isJoin = false,
	joinChar = '|',
	triggerFn,
}: DropdownProps): DropdownType => {
	const [ddState, setDDstate] = useState<Array<any>>([]);

	return {
		value: ddState,
		onChange: (v?: string | number) => {
			let array: Array<string | number | undefined> = [];
			if (setDDstate.length > 0) {
				array = [...ddState];
				const index = array.indexOf(v);

				if (index !== -1) {
					array.splice(index, 1);
					setDDstate(array);
				} else {
					array.push(v);
					setDDstate(array);
				}
			} else {
				array.push(v);
				setDDstate(array);
			}
			const returnValue = isJoin ? array.join(joinChar) : array;

			triggerFn && triggerFn(returnValue);

			return isJoin ? array.join(joinChar) : array;
		},
	};
};

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
