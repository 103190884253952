import {
	Modal,
	DataTable,
	DataTableColumn,
	Button,
} from '@salesforce/design-system-react/module/components';
import Grid from 'components/Grid/Grid';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import BranchCheckbox from 'components/Checkbox/BranchCheckbox';
import TAPIDCheckbox from 'components/Checkbox/TAPIDCheckbox';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FixMeLater } from 'types';
import { ChannelLevel, WalletType } from 'utils/lookup';
import HTTP from 'helpers/ApiClient';
import styles from './WalletDetails.module.css';
import { useToggle } from 'utils/hooks';
import {
	ContactNumberTagInput,
	EmailTagInput,
	MobileNumberTagInput,
} from 'components/TagInput/TagInput';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { yupResolver } from '@hookform/resolvers/yup';
import yup, {
	multipleContactNumberSchema,
	multipleEmailSchema,
	selectDefaultRequiredTemplate,
} from 'utils/formSchemas/common';
import successIcon from 'assets/icons/ic-success.svg';
import failedIcon from '../../../../assets/icons/ic-failed.svg';
import ResponseModal from 'components/Modal/ResponseModal';
import { isANumber, isPositive } from 'components/Format/NumberFormatter';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../../redux/modules/viewWalletDetails';
import { ReactComponent as EditIcon } from 'assets/icons/ic-edit.svg';
import { ReactComponent as EditBlackIcon } from 'assets/icons/ic-edit-black.svg';
import walletListForm from 'utils/formSchemas/walletListForm/walletListForm';
import { resolveValue, formatDate } from 'utils/common';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { showAccessDeniedModal } from 'redux/modules/access';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { ReducerStateType } from 'redux/modules/reducers';
import { EDIT_CHANNEL_WALLET_PERMISSION } from 'utils/permissions';
import ProductTypeCheckbox from 'components/Checkbox/ProductTypeCheckbox';

type Props = {
	open?: boolean;
	onClose?: () => void;
	state: any;
	audit_logs: any;
};

const Row: React.FC<{ gutters?: boolean }> = ({ gutters, children }) => (
	<Grid container gutters={gutters} className={styles.vspaced}>
		{children}
	</Grid>
);

const columns = {
	audit: [
		<DataTableColumn
			label="Date & Time"
			key="date_created"
			property="date_created"
		/>,
		<DataTableColumn label="Action" key="action" property="action" />,
		<DataTableColumn
			label="Old Values"
			key="old_values"
			property="old_values"
		/>,
		<DataTableColumn
			label="New Values"
			key="new_values"
			property="new_values"
		/>,
		<DataTableColumn
			label="Logged By"
			key="created_by"
			property="created_by"
		/>,
		<DataTableColumn label="Source IP" key="source_ip" property="source_ip" />,
		<DataTableColumn label="Remarks" key="remarks" property="remarks" />,
	],
};

async function getBranches({
	page = 1,
	limit = 25,
	name = '',
	channel_id = '',
} = {}) {
	const result = await HTTP.get(
		`/v1/cws/util/accounts/${channel_id}/branches`,
		{
			params: {
				name: resolveValue(name),
				page,
				limit,
			},
		}
	);
	return result.data;
}

type BranchParams = {
	name?: string;
	page?: number;
	limit?: number;
	channel_id?: string;
};

const WalletDetails: React.FC<Props> = ({
	state,
	open,
	audit_logs,
	onClose = () => {},
}) => {
	const schema = yup.object().shape({
		internalEmail: multipleEmailSchema.label('Internal Email Address'),
		internalMobileNumber: multipleContactNumberSchema.label(
			'Internal Mobile Number'
		),
		externalEmail: multipleEmailSchema.label('External Email Address'),
		externalMobileNumber: multipleContactNumberSchema.label(
			'External Mobile Number'
		),
		thresholdAmount: yup
			.string()
			.label('Threshold Amount')
			.required('Please enter a threshold amount value.')
			.transform((curr) => curr.replaceAll(',', ''))
			.test('is-number', 'You can only input numeric characters', (value) =>
				isANumber(value)
			),
		remarks: yup.string().label('Remarks').max(255).when('showRemarks', {
			is: true,
			then: yup.string().required(),
		}),
	});

	const {
		control,
		setValue,
		clearErrors,
		formState: { isDirty, isValid, errors },
		handleSubmit,
		reset,
		setError,
		watch,
	} = useForm({
		mode: 'all',
		resolver: yupResolver(schema),
	});
	const [response, setResponse] = useState({
		isOpen: false,
		icon: successIcon,
		bodyText: '',
		bodyHeader: "Success! You've added a channel wallet.",
		respondButton: {
			name: 'Done',
			event: () => {},
		},
		respondButton2: {
			name: '',
			event: () => {},
		},
		isRespondButton2Shown: false,
	});

	const [channels, setChannels] = useState<FixMeLater>([]);
	const [branches, setBranches] = useState<FixMeLater>([]);
	const [tpaIds, setTPAIds] = useState<FixMeLater>([]);
	const [auditLogs, setAuditLogs] = useState<FixMeLater>([]);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const {
		value: isFetchingChannels,
		valueOn: startFetchingChannels,
		valueOff: stopFetchingChannels,
	} = useToggle();

	const { value: showRemarks, toggleValue: toggleRemarks } = useToggle();
	const {
		value: isSaveLoading,
		valueOn: toggleStartSaveLoading,
		valueOff: toggleStopSaveLoading,
	} = useToggle();

	const walletType = watch('walletType');
	const channelLevel = watch('channelLevel');
	const channelId = watch('channelId');
	const dispatch = useDispatch();

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const handleClose = () => {
		dispatch(closeModal());
		setBranches([]);
		setTPAIds([]);
		reset();
	};

	const handleDone = () => {
		window.location.reload();
	};

	const handleCloseResponseModal = () => {
		setResponse({
			...response,
			isOpen: false,
			respondButton: {
				name: 'Done',
				event: () => {},
			},
		});
	};

	const handleSave = (values) => {
		toggleStartSaveLoading();
		const {
			selectedChannel,
			channelId,
			thresholdAmount,
			channelLevel,
			walletType: type,
			internalEmail,
			internalMobileNumber,
			externalEmail,
			externalMobileNumber,
			productName,
			remarks,
		} = values;

		const user = JSON.parse(localStorage.getItem('userInfo') || '');

		const join = (arr: string[]): string => arr.join('|');
		const data = {
			channelId: +channelId,
			channelName: selectedChannel?.value?.name,
			channelLevel: channelLevel || '',
			thresholdAmount,
			productName,
			branches: branches ? branches.filter((b) => b.checked === true) : [],
			tpaIds: tpaIds ? tpaIds.filter((t) => t.checked === true) : [],
			type,
			internalEmailAddresses: join(internalEmail),
			internalContactNumber: join(internalMobileNumber),
			externalEmailAddresses: join(externalEmail),
			externalContactNumber: join(externalMobileNumber),
			remarks,
		};

		HTTP.put(`/v1/wallets/${state.id}`, data)
			.then((res) => {
				toggleStopSaveLoading();
				if (res.data.message === 'Wallet edited successfully') {
					handleClose();
					toggleRemarks();
					setResponse({
						...response,
						isOpen: true,
						icon: successIcon,
						bodyHeader: "Success! You've updated a channel wallet.",
						respondButton: {
							name: 'Done',
							event: handleDone,
						},
					});
				}
				if (res.data.message === 'No changes on wallet') {
					setError(
						'remarks',
						{ type: 'manual', message: res.data.message + '.' },
						{ shouldFocus: true }
					);
				}
			})
			.catch((e) => {
				const errData = e.response.data && e.response.data.message;
				if (
					errData &&
					(errData.includes('cannot be edited') ||
						errData.includes('field is empty'))
				) {
					toggleStopSaveLoading();
					setError(
						'remarks',
						{
							type: 'manual',
							message: errData.includes('went wrong')
								? errData.slice(22, errData.length) + '.'
								: errData + '.',
						},
						{ shouldFocus: true }
					);
					return;
				}

				toggleStopSaveLoading();
				handleClose();
				toggleRemarks();
				setResponse({
					isOpen: e.response.status === 403 ? false : true,
					icon: failedIcon,
					bodyHeader: 'Failed to update a channel wallet!',
					bodyText:
						'Something went wrong with your request. Would you like to retry?',
					respondButton: {
						name: 'Retry',
						event: () => {
							handleSave(values);
							handleCloseResponseModal();
						},
					},
					respondButton2: {
						name: 'Cancel',
						event: () => {
							handleCloseResponseModal();
							handleClose();
						},
					},
					isRespondButton2Shown: true,
				});
			});
	};

	const channelOptions = channels.map(({ name, id }) => ({
		label: name,
		value: {
			name,
			id,
		},
	}));

	const fetchBranches = async ({
		name,
		page = 1,
		limit = 25,
		channel_id,
	}: BranchParams = {}) => {
		try {
			const res = await getBranches({ name, page, limit, channel_id });
			const data: any = res.result || [];

			if (state.branches && state.branches[0] != undefined) {
				const saveBranches = state.branches;
				data.forEach((list) => {
					saveBranches.forEach((check) => {
						if (check.id === list.id) {
							list.checked = true;
						}
					});
				});
			} else {
				data.forEach((list) => {
					list.checked = false;
				});
			}
			setBranches(data);
		} catch (e) {
			console.log(e);
			setBranches([]);
		}
	};

	useEffect(() => {
		if (!open) setIsEdit(false);
	}, [open]);

	useEffect(() => {
		if (channelId) {
			const selectedChannel = channelOptions.find(
				({ value: { id } }) => channelId === id
			);

			setValue('selectedChannel', selectedChannel);
		}
	}, [channelId, channelOptions]);

	useEffect(() => {
		if (channelId && channelLevel === 'BRANCH') {
			fetchBranches({ channel_id: channelId });
		}

		if (state.channel_name && state.channel_level === 'BRANCH') {
			fetchBranches({ channel_id: state.channel_id });
		}
	}, [
		state.channel_id,
		state.channel_level,
		state.wallet_identifier,
		walletType,
		channelLevel,
		channelId,
	]);
	useEffect(() => {
		(async () => {
			if (
				(state.channel_id && state.channel_level === 'TPA_ID') ||
				(channelId && channelLevel === 'TPA_ID')
			) {
				const {
					data: { result },
				} = await HTTP.get(
					`/v1/cws/util/accounts/${state.channel_id || channelId}/terminals`,
					{
						params: {
							name: '',
							page: 1,
							limit: 25,
						},
					}
				);
				const savedBranch: any = state.branches || branches;
				const res = result;

				savedBranch.forEach((branches) => {
					res.forEach((element) => {
						if (branches.id === element.branch_id) {
							const terminals = branches.terminals || [];
							terminals.forEach((term) => {
								if (element.id === term) {
									element.checked = true;
								}
							});
						}
					});
				});

				setTPAIds(res);
			}
		})();
	}, [
		state.channel_id,
		state.channel_level,
		state.wallet_identifier,
		walletType,
		walletType,
		channelLevel,
		channelId,
	]);

	useEffect(() => {
		const logs: any = audit_logs.logs;
		const logList: any = [];

		if (logs) {
			logs.forEach((element) => {
				const obj: any = {
					...element,
					date_created: formatDate(element.date_created),
				};
				logList.push(obj);
			});

			setAuditLogs(logList);
		}

		if (state) {
			const num = new Number(state.threshold_amount);
			setValue('productName', state.product_name);

			setValue('productType', state.product_type);

			setValue(
				'thresholdAmount',
				TextFieldFormatter.decimalPlaces()(num.toString())
			);

			setValue(
				'internalEmail',
				state.internal_email_addresses &&
					state.internal_email_addresses.split('|')
			);
			setValue(
				'internalMobileNumber',
				state.internal_contact_number &&
					state.internal_contact_number.split('|')
			);
			setValue(
				'externalEmail',
				state.external_email_addresses &&
					state.external_email_addresses.split('|')
			);
			setValue(
				'externalMobileNumber',
				state.external_contact_number &&
					state.external_contact_number.split('|')
			);
		}
	}, [state, audit_logs]);

	const handleBranchClick = (branch, checked) => {
		const updatedBranches = (prevBranches) =>
			prevBranches.map((b) => {
				if (b.id === branch.id)
					return {
						...b,
						checked,
					};
				return b;
			});
		setBranches(updatedBranches);
		setValue('branches', updatedBranches);
	};

	const handleTPAhClick = (tpa, checked) => {
		const updatedTPA = (prevState) =>
			prevState.map((t) => {
				if (t.id === tpa.id)
					return {
						...t,
						checked,
					};
				return t;
			});
		setTPAIds(updatedTPA);
		setValue('tpaIds', updatedTPA);
	};

	useEffect(() => {
		(async () => {
			const isWalletTypeCWS = walletType === 'cws';

			if (walletType) {
				startFetchingChannels();
				const res = await HTTP.get(`/v1/cws/util/accounts`, {
					params: { walletType },
				});
				setChannels(res.data.result);
				stopFetchingChannels();
			}
			if (isWalletTypeCWS || (state.type === 'cws' && !isEdit)) {
				setValue('showProductName', true);
			} else {
				setValue('showProductName', false);
			}
		})();
	}, [state.type, walletType]);

	useEffect(() => {
		setValue('showRemarks', showRemarks);
		setValue('remarks', '');
	}, [showRemarks]);

	const handleShowEditModal = () => {
		if (verifyPermission(scopes, EDIT_CHANNEL_WALLET_PERMISSION)) {
			!isEdit && setIsEdit(true);
		} else {
			dispatch(showAccessDeniedModal());
		}
	};

	type StatusType = {
		name: string;
		code: string;
		className: string;
	};

	const STATUS: StatusType[] = [
		{
			name: 'Active',
			code: 'ACTIVE',
			className: styles.statusActive,
		},
		{
			name: 'Deactivated',
			code: 'DEACTIVATED',
			className: styles.statusDeactivated,
		},
		{
			name: 'Inactive',
			code: 'INACTIVE',
			className: styles.statusInactive,
		},
	];

	const match = STATUS.find(
		(stat) => stat.name === state.status || stat.code === state.status
	);
	const classNameBtn = match ? match.className : '';

	return (
		<>
			<FullPageLoader
				open={isSaveLoading}
				message="Please wait while channel wallet is being updated"
			/>

			{open && (
				<>
					<Modal
						isOpen={open}
						onRequestClose={handleClose}
						headerClassName={styles.headerContainer}
						contentClassName={styles.modal}
						footer={
							isEdit && (
								<div className={styles.footer}>
									<OutlineButton onClick={handleClose} className={styles.btn}>
										Cancel
									</OutlineButton>

									<PrimaryButton
										onClick={handleSubmit(() => toggleRemarks())}
										className={styles.btn}
										disabled={!isEdit}
									>
										Save
									</PrimaryButton>
								</div>
							)
						}
						size="small"
					>
						<div>
							<h1 className={styles.headerH1}>{`${
								isEdit ? 'Edit' : 'View'
							} Wallet Details`}</h1>
							<Button
								onClick={handleShowEditModal}
								disabled={isEdit}
								fullWidth
								className={styles.editButton}
							>
								{!isEdit ? <EditIcon /> : <EditBlackIcon />} Edit
							</Button>
							<br />
							<br />
							<Grid container vertical>
								<Row>
									<Grid column size={2} of={2}></Grid>
									<Grid column size={1} of={2}>
										<label className={styles.headerWallet}>Wallet ID:</label>
										<h5 className={styles.headerRightId}>
											{state.wallet_identifier}
										</h5>
									</Grid>
								</Row>
								<Row>
									<Grid column size={2} of={2}></Grid>
									<Grid column size={1} of={2}>
										<label className={styles.header}>Status:</label>
										<Button className={classNameBtn} disabled>
											{match?.name}
										</Button>
									</Grid>
								</Row>
							</Grid>
							<Grid container vertical>
								<Row gutters>
									<Grid column size={1} of={1}>
										<h2 className={styles.sectionLabel}>Wallet Details</h2>
									</Grid>
								</Row>
								<Row>
									<Grid column size={1} of={1}>
										<h5 className={styles.header}>Date Created</h5>
										<label className={styles.colon}>:</label>
										<h5 className={styles.headerItems}>{state.date_created}</h5>
									</Grid>
								</Row>
								<Row>
									<Grid column size={1} of={1}>
										<h5 className={styles.header}>Created By</h5>
										<label className={styles.colon}>:</label>
										<h5 className={styles.headerItems}>{state.created_by}</h5>
									</Grid>
								</Row>
								<Row>
									<Grid column size={1} of={1}>
										<h5 className={styles.header}>Wallet Balance</h5>
										<label className={styles.colon}>:</label>
										<h5 className={styles.headerItems}>
											{state.wallet_balance}
										</h5>
									</Grid>
								</Row>
							</Grid>
						</div>
						<br />
						<Grid container vertical>
							<Row gutters>
								<Grid column size={1} of={1}>
									<SelectField
										label="Wallet Type"
										name="walletType"
										control={control}
										placeholder="Select Wallet Type"
										options={WalletType}
										defaultValue={state.type}
										disabled
									/>
								</Grid>
							</Row>

							{(walletType === 'prefunded' || walletType === 'bonded') && (
								<Row>
									<Grid column container vertical>
										<h3 className={styles.textLabel}>Product Type</h3>
										<div>
											<ProductTypeCheckbox
												entries={branches}
												onEntryClick={handleBranchClick}
												getEntryValue={(branch) => branch.name}
												disabled={!isEdit}
											/>
										</div>
									</Grid>
								</Row>
							)}

							{
								<Grid container vertical>
									{walletType === 'cws' || (state.type === 'cws' && !isEdit) ? (
										<>
											<Row>
												<TextField
													label="Product Type"
													name="productType"
													control={control}
													disabled
												/>
											</Row>
											<Row>
												<TextField
													name="productName"
													control={control}
													label="Product Name"
													required
													className="productName"
													multiLine
													disabled
												/>
											</Row>
											<Row gutters>
												<Grid column size={1} of={2}>
													<TextField
														name="thresholdAmount"
														control={control}
														label="Threshold Amount"
														formatValue={TextFieldFormatter.commaSeparated}
														onBlur={(e) => {
															setValue(
																'thresholdAmount',
																TextFieldFormatter.decimalPlaces()(
																	e.target.value
																)
															);
														}}
														disabled={!isEdit}
													/>
												</Grid>
											</Row>
										</>
									) : (
										<>
											<Row>
												<SelectField
													label="Channel Name"
													name="channelId"
													control={control}
													getOptionValue={(v) => v.value.id}
													getComparator={({ value }, id) => value.id == id}
													isLoading={isFetchingChannels}
													placeholder="Select Channel Name"
													options={channelOptions}
													defaultValue={state.channel_name || undefined}
													disabled
												/>
											</Row>
											<Row gutters>
												<Grid column size={1} of={2}>
													<SelectField
														label="Channel Level"
														name="channelLevel"
														control={control}
														isLoading={isFetchingChannels}
														placeholder="Select Channel Level"
														options={ChannelLevel}
														defaultValue={state.channel_level}
														disabled
													/>
												</Grid>
												<Grid column size={1} of={2}>
													<TextField
														name="thresholdAmount"
														control={control}
														label="Threshold Amount"
														formatValue={TextFieldFormatter.commaSeparated}
														onBlur={(e) => {
															setValue(
																'thresholdAmount',
																TextFieldFormatter.decimalPlaces()(
																	e.target.value
																)
															);
														}}
														disabled={!isEdit}
													/>
												</Grid>
											</Row>
										</>
									)}
									{(walletType !== 'cws' ||
										(state.type !== 'cws' && !isEdit)) && (
										<>
											{(channelLevel === 'BRANCH' ||
												(state.channel_level === 'BRANCH' && !isEdit)) && (
												<Row>
													<Grid column container vertical>
														<h3 className={styles.headerLabel}>Branch</h3>

														<div className={styles.subtext}>
															<em>
																*Please select branches to be covered by this
																wallet.
															</em>
														</div>
														<div>
															<BranchCheckbox
																entries={branches}
																onEntryClick={handleBranchClick}
																getEntryValue={(branch) => branch.name}
																disabled={!isEdit}
															/>
														</div>
													</Grid>
												</Row>
											)}

											{(channelLevel === 'TPA_ID' ||
												(state.channel_level === 'TPA_ID' && !isEdit)) && (
												<Row>
													<Grid column container vertical>
														<label
															className="slds-form-element__label"
															htmlFor="internal_email"
														>
															TPA ID
														</label>
														<div className={styles.subtext}>
															<em>
																*Please select TPA IDs to be covered by this
																wallet.
															</em>
														</div>
														<div>
															<TAPIDCheckbox
																entries={tpaIds}
																onEntryClick={handleTPAhClick}
																getEntryValue={(tpa) => tpa.name}
																disabled={!isEdit}
															/>
														</div>
													</Grid>
												</Row>
											)}
										</>
									)}

									<Row>
										<h2 className={styles.sectionLabel}>
											Internal Channels Contact Person(s)
										</h2>
									</Row>
									<Row>
										<EmailTagInput
											control={control}
											name="internalEmail"
											disabled={!isEdit}
										/>
									</Row>
									<Row>
										<ContactNumberTagInput
											label="Mobile Number"
											control={control}
											name="internalMobileNumber"
											subtext="*Can input mobile numbers separated by commas."
											validateType="639"
											disabled={!isEdit}
										/>
									</Row>
									<Row>
										<h2 className={styles.sectionLabel}>
											External Channels Contact Person(s)
										</h2>
									</Row>
									<Row>
										<EmailTagInput
											control={control}
											name="externalEmail"
											disabled={!isEdit}
										/>
									</Row>
									<Row>
										<ContactNumberTagInput
											label="Mobile Number"
											control={control}
											name="externalMobileNumber"
											subtext="*Can input mobile numbers separated by commas."
											validateType="639"
											disabled={!isEdit}
										/>
									</Row>
									<Row>
										<h1 className={styles.datatableHeader}>Last 5 Actions</h1>
									</Row>
									<Row>
										<DataTable
											items={auditLogs}
											className={styles.datatable}
											noRowHover
										>
											{columns.audit}
										</DataTable>
									</Row>
								</Grid>
							}
						</Grid>
					</Modal>

					<ConfirmModal
						open={showRemarks}
						disableClose={false}
						onClose={() => toggleRemarks()}
						headerText="Update Wallet Details"
						bodyText={[
							() => (
								<>
									<p>
										Are you sure you want to update channel wallet details for{' '}
										<strong>{state.wallet_identifier}?</strong> If yes, please
										provide remarks.
									</p>
									<div className={styles.remarksMultiline}>
										<TextField
											control={control}
											label="Remarks"
											name="remarks"
											required
											multiLine
											rows={4}
											autoFocus
										/>
									</div>
								</>
							),
						]}
						confirmButton={{
							name: 'Update',
							event: handleSubmit(handleSave),
						}}
						cancelButton={{
							name: 'Cancel',
							event: toggleRemarks,
						}}
					/>
				</>
			)}
			<ResponseModal {...response} />
		</>
	);
};

export default connect((state: any) => ({
	state: state.viewWalletDetails.walletData,
	audit_logs: state.viewWalletDetails.data,
}))(WalletDetails);
