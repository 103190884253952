import React, { ReactNode, useEffect, useState } from 'react';
import DraftFilter from './DraftFilter';
import _ from 'lodash';
import DraftTable from './DraftTable';
import HTTP from 'helpers/ApiClient';
import { useToggle } from 'utils/hooks';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { formatDate } from 'utils/common';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './Draft.module.css';
import { showAccessDeniedModal } from 'redux/modules/access';
import { connect, useDispatch } from 'react-redux';
import { IconSettings, Button, Tooltip } from '@salesforce/design-system-react';
import cx from 'classnames';
import PartnerModal from 'components/PartnerModal/PartnerModal';
import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';
import { status_option } from 'components/PartnerStatusSelect/PartnerStatusSelect';
import { AddressDefaultValue, ContactDefaultValue } from 'utils/lookup';
import { mapViewPartner } from 'components/PartnerDetails/PartnerDetails';
import { FixMeLater } from 'types';

const INITIAL_VALUES = {
	dateCreated: undefined,
	createdBy: '',
	dateUpdated: undefined,
	partnerName: '',
};

const Cell: React.FC<{ value: any }> = ({ value }) => {
	return <div className={styles.rowBreak}>{value}</div>;
};

type GetDraftsArgs = {
	page?: number;
	pageSize?: number;
	limit?: number;
	sortBy?: string;
	sort?: string;
	createdAt?: string | null;
	createdBy?: string | null;
	updatedAt?: string | null;
	partnerName?: string;
	mainPartnerName?: string;
	onClick?: any;
};

type FetchDraftsArgs = Omit<GetDraftsArgs, 'dateCreatedFrom' | 'dateCreatedTo'>;

type Props = {
	username: any;
	product_type_id: any;
};

const Drafts: React.FC<Props> = ({ username, product_type_id }) => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [filterParams, setFilterParams] = useState<GetDraftsArgs>({});
	const [isSearch, setIsSearch] = useState(false);
	const [sortBy, setSortBy] = useState('date_created');
	const [sort, setSort] = useState('desc');
	const [lastParams, setLastParams] = useState<any>({});
	const [draftData, setDraftData] = useState({});
	const [createdByOptions, setCreatedByOptions] = useState([username]);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isPartnerModalShown,
		valueOn: showPartnerModal,
		valueOff: hidePartnerModal,
	} = useToggle();

	const dispatch = useDispatch();

	const DRAFT_COLUMNS: Array<any> = [
		{
			Header: 'Date & Time Created',
			id: 'created_at',
			sortable: true,
			width: '14%',
			accessor: ({ created_at }) => formatDate(created_at),
		},
		{
			Header: 'Created By',
			id: 'created_by',
			sortable: true,
			width: '13%',
			Cell,
		},
		{
			Header: 'Partner Name',
			id: 'name',
			sortable: true,
			width: '17%',
			Cell,
		},
		{
			Header: 'Date Updated',
			id: 'updated_at',
			sortable: true,
			width: '16%',
			Cell,
			accessor: ({ updated_at }) => formatDate(updated_at),
		},
		{
			Header: 'Updated by',
			id: 'updated_by',
			sortable: true,
			Cell,
		},
		{
			Header: 'Status',
			id: 'status',
			sortable: true,
			accessor: ({ status }) => {
				return status_option.find(({ value }) => value === status);
			},
			Cell: ({ cell, value }) => {
				if (!value) {
					return null;
				}
				return (
					<div {...cell.getCellProps()} className={value.className}>
						{value.label}
					</div>
				);
			},
		},
		{
			Header: 'Remarks',
			id: 'remarks',
			sortable: false,
			Cell: ({ value }) => {
				return (
					<>
						<Tooltip
							id="tooltip"
							align="bottom left"
							content={value}
							triggerClassName={styles.toolTip}
							position="overflowBoundaryElement"
						>
							<div>{value}</div>
						</Tooltip>
					</>
				);
			},
		},
		{
			Header: (props) => (
				<div className={cx(styles.actionHeader, styles.centerAlign)}>
					Action
				</div>
			),
			id: 'actions',
			accessor: (values) => values,
			Cell: ({ value }) => {
				return (
					<>
						<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
							<div
								className={cx(
									styles.actionCell,
									'slds-grid',
									styles.centerAlign
								)}
							>
								<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
									<Button
										assistiveText={{ icon: 'Icon Bare Small' }}
										className={styles.crossIcon}
										iconCategory="utility"
										iconName="picklist_type"
										iconSize="small"
										iconVariant="bare"
										iconClassName={cx(styles.iconBtn, styles.iconOrange)}
										onClick={() => {
											getDraftValues(value.id, value.updated_by);
										}}
										variant="icon"
									/>
								</div>
							</div>
						</IconSettings>
					</>
				);
			},
		},
	];

	const fetchDrafts = async (
		values: FetchDraftsArgs = {},
		retry = false,
		withLoader = true
	) => {
		const doRequest = async (p) => {
			try {
				withLoader && showLoading();
				setLastParams(p);
				const result = await getDrafts(p);
				const { data, meta } = result;
				setData(data);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e) {
				if (withLoader) {
					showErrorMessage(
						'Timeout Error!',
						'An error occurred while trying to load draft list',
						() => {
							if (lastParams) {
								fetchDrafts(lastParams, true);
							}
						}
					);
					return;
				}
				throw e;
			} finally {
				setIsSearch(false);
				withLoader && hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const { ...rest } = values;

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...rest,
			...values,
		};
		await doRequest(params);
	};

	const initialFetch = async () => {
		try {
			showLoading();
			await fetchDrafts({}, false, false);
		} catch (e: any) {
			if (e.response.status === 403) {
				dispatch(showAccessDeniedModal());
			} else {
				showErrorModal();
			}
		} finally {
			hideLoading();
		}
	};

	const getDrafts = async function (values: GetDraftsArgs = {}) {
		const {
			page = 1,
			limit = 25,
			sortBy,
			sort,
			createdAt,
			createdBy,
			updatedAt,
			partnerName,
		} = values;

		const params = {
			sortBy,
			sort,
			page,
			limit,
			createdAt,
			createdBy,
			updatedAt,
			partnerName: partnerName,
		};
		const result = await HTTP.get(`/v2/products/${product_type_id}/drafts`, {
			params,
		});
		return result.data;
	};

	const getDraftValues = async function (id: string, updated_by: string) {
		showLoadingMessage(
			"Please wait while the selected request's details is being loaded"
		);
		await HTTP.get(`/v2/products/${product_type_id}/${id}/drafts-details`)
			.then((resp) => {
				const result = resp.data.data;
				const data = result;
				if (!data) return;

				const formattedValues: FixMeLater = {
					id: data.id,
					status: data.status,
					logo_url: data.logo_url,
					code: data.code,
					...mapViewPartner(data, true),
					updated_at: data.updated_at,
					updated_by: updated_by,
				};
				setDraftData(formattedValues);
				showPartnerModal();
			})
			.catch(() => {
				showErrorMessage(
					'Timeout Error!',
					"An error occurred while trying to load selected partner's details",
					() => {
						getDraftValues(id, updated_by);
					}
				);
			})
			.finally(() => {
				hideLoading();
			});
	};

	useEffect(() => {
		initialFetch();
	}, []);

	const handlePageChange = async (_page, _pageSize) => {
		const { page: _, pageSize: __, ...rest } = filterParams;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			await fetchDrafts({ page: _page, limit: _pageSize, ...rest });
		}
	};

	const handleSort = async (sortBy, sort) => {
		setSortBy(sortBy);
		setSort(sort);
		setPage(1);
		await fetchDrafts({ sortBy, sort, page: 1, ...filterParams });
	};

	const handleFilterSubmit = async (values, _isSearch) => {
		const { createdAt, createdBy, updatedAt, partnerName, mainPartnerName } =
			values;
		const params = {
			//partnerName,
			createdAt,
			createdBy,
			updatedAt,
			partnerName: mainPartnerName,
			page: 1,
			pageSize,
		};

		setPage(1);
		setFilterParams(params);
		await fetchDrafts(params);
		setIsSearch(_isSearch);
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (
		header: any,
		message: any,
		instruction?: any,
		onRetryBtnClick?: () => void
	) => {
		setErrorHeader(header);
		setErrorMessage(message);
		setErrorInstruction(instruction || 'Please try again.');
		showErrorModal();
		setRetryBtnOnClick({
			action: () => {
				onRetryBtnClick && onRetryBtnClick();
			},
		});
	};

	const showSuccessMessage = (message: any, onDoneBtnClick?: () => void) => {
		setSuccessMessage(message);
		showSuccessModal();
		setDoneBtnOnClick({
			action: () => {
				onDoneBtnClick && onDoneBtnClick();
			},
		});
	};

	return (
		<>
			<DraftFilter
				initialValues={INITIAL_VALUES}
				onSubmit={handleFilterSubmit}
				createdByOptions={createdByOptions}
			/>
			<DraftTable
				data={data}
				count={count}
				pageSize={pageSize}
				onPageChange={handlePageChange}
				onSort={handleSort}
				sort={sort}
				sortBy={sortBy}
				page={page}
				isSearch={isSearch}
				columns={DRAFT_COLUMNS}
			/>
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
			{isSuccessModalShown && (
				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>{errorHeader}</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>{errorInstruction}</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{isPartnerModalShown && (
				<PartnerModal
					open={isPartnerModalShown}
					onClose={() => {
						hidePartnerModal();
						fetchDrafts({}, false, true);
					}}
					data={draftData}
					action={'DRAFT'}
					disabled={true}
				/>
			)}
		</>
	);
};

export default connect((state: any) => ({
	username: state.userInfo.username,
	product_type_id: state.sidebar.itemId,
}))(Drafts);
