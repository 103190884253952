import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Label.module.css';

type Props = {
	children: ReactNode;
	required?: boolean;
	optional?: boolean;
	className?: string;
	subLabel?: string;
};

const Label: React.FC<Props> = ({
	children,
	required,
	optional,
	className = '',
	subLabel,
}) => {
	return (
		<div className={cx(styles.label, className)}>
			<div className={cx({ [styles.required]: required })}>{children}</div>
			{subLabel && (
				<div className={styles.optional}>
					<em>{subLabel}</em>
				</div>
			)}
			{optional && (
				<div className={styles.optional}>
					<em>(Optional)</em>
				</div>
			)}
		</div>
	);
};

export default Label;
