import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import { FaCheckCircle } from 'react-icons/fa';
import cx from 'classnames';
import styles from '../BillerForm/BillerForm.module.css';
import PrimaryInformation, {
	PrimaryInformationFormData,
} from './Tabs/PrimaryInformation/PrimaryInformation';
import { FixMeLater } from 'types';
import { TBillerStatus } from 'components/BillerForm/BillerStatusSelect/BillerStatusSelect';
import {
	PrimaryInfoSaveResponse,
	SuccessResponse,
} from 'components/BillerModal/BillerModal';
import { TabLabel } from 'components/BillerForm/BillerForm';

export type ChannelFormProps = {
	tabStatus?: TabStatus;
	disabled?: boolean;
	contentClassName?: string;
	onSubmit?: (
		values: PrimaryInformationFormData
	) => Promise<PrimaryInfoSaveResponse>;
	initialValues: {
		status?: {
			uat?: TBillerStatus;
			staging?: TBillerStatus;
			production?: TBillerStatus;
		};
		primaryInformation?: PrimaryInformationFormData;
		contractDetails?: FixMeLater;
		serviceFeeSettings?: FixMeLater;
		businessRules?: FixMeLater;
		billerUploadSettings?: FixMeLater;
	};
	onChange?: FixMeLater;
};
type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

type TabStatusValue = { finished?: boolean; disabled?: boolean };

export type TabStatus = PartialRecord<
	keyof ChannelFormProps['initialValues'],
	TabStatusValue
>;

const initTabStatus = {
	primaryInformation: { disabled: false, finished: false },
	contractDetails: { disabled: true },
};

const ChannelForm: React.FC<ChannelFormProps> = ({
	tabStatus = initTabStatus,
	contentClassName = '',
	initialValues,
	onChange,
	...props
}) => {
	return (
		<Tabs className={styles.tabs}>
			<TabsPanel
				label={
					<TabLabel
						label="Primary Information"
						done={tabStatus.primaryInformation?.finished}
					/>
				}
				disabled={tabStatus.primaryInformation?.disabled}
			>
				<div className={cx(styles.content, contentClassName)}>
					<PrimaryInformation
						{...props}
						onChange={onChange}
						initialValues={
							initialValues.primaryInformation as PrimaryInformationFormData
						}
						tabStatus={tabStatus}
					/>
				</div>
			</TabsPanel>
			<TabsPanel
				label="Contract Details"
				disabled={tabStatus.contractDetails?.disabled}
			>
				<div className={cx(styles.content, contentClassName)}></div>
			</TabsPanel>
		</Tabs>
	);
};

export default ChannelForm;
