import { Checkbox as LDCheckbox } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import styles from './Checkbox.module.css';
import { Control, Controller } from 'react-hook-form';
import ExpandedSection from 'components/ExpandableRow/ExpandedSection';

export interface ICheckbox {
	name?: string;
	control?: Control<any>;
	label: string;
	checked?: boolean;
	disabled?: boolean;
	showErrorMessage?: boolean;
	onChange?: (arg0?: boolean) => void;
	[mainProps: string]: any;
	isSubOptionChecked?: boolean;
}

const ExpandedCheckbox: React.FC<ICheckbox> = ({
	name = '',
	control,
	className = '',
	label,
	subText,
	subRow,
	checked,
	isSubOptionChecked,
	disabled = false,
	showErrorMessage = true,
	onChange = () => {},
	subOnChange = () => {},
	...mainProps
}) => {
	if (name && control)
		return (
			<>
				<Controller
					name={name}
					control={control}
					render={(props) => {
						const {
							field,
							fieldState: { error },
						} = props;
						return (
							<div>
								<LDCheckbox
									className={cx(
										styles.checkbox,
										{ [styles.checked]: field.value },
										className
									)}
									labels={{ label }}
									onChange={(v) => {
										onChange && onChange(v);
										field.onChange(!field.value);
									}}
									checked={field.value}
									inputRef={field.ref}
									disabled={disabled || undefined}
									{...mainProps}
								/>

								{showErrorMessage && error?.message && (
									<div
										className={cx({
											'slds-has-error': !!error,
										})}
									>
										<div
											className={cx(styles.helper, 'slds-form-element__help')}
										>
											{error?.message}
										</div>
									</div>
								)}
							</div>
						);
					}}
				/>
			</>
		);

	return (
		<>
			<LDCheckbox
				className={cx(
					styles.checkbox,
					{ [styles.checked]: checked },
					className
				)}
				labels={{ label }}
				checked={checked}
				disabled={disabled || undefined}
				onChange={onChange}
				{...mainProps}
			/>
			{subText && (
				<div className={styles.var}>
					<div className={styles.expandedCheckbox}>
						<ExpandedSection
							checked={isSubOptionChecked}
							disabled={disabled}
							subRow={subRow}
							onChange={(e) => {
								subOnChange(e.target.checked);
							}}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default ExpandedCheckbox;
