import { Modal } from '@salesforce/design-system-react/module/components';
import RenderByType from '../common/RenderByType';
import { ReactComponent as Icon } from 'assets/icons/ic-success.svg';
import styles from './SuccessModal.module.css';
import { ReactNode } from 'react';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';

type SuccessModalSectionProps = {
	children: ReactNode;
};

export const SuccessModalBody: React.FC<SuccessModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};
export const SuccessModalActions: React.FC<SuccessModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};

const SuccessIcon: React.FC = () => <Icon className={styles.successIcon} />;

type SuccessModalProps = {
	open?: boolean;
	onClose?: () => void;
	Icon?: React.FC;
	children: ReactNode;
	larger?: boolean;
};

export const SuccessText: React.FC = ({ children }) => (
	<div className={styles.successModalText}>{children}</div>
);
export const SuccessSubText: React.FC = ({ children }) => (
	<div className={styles.successModalSubtext}>{children}</div>
);

const SuccessModal: React.FC<SuccessModalProps> = ({
	open,
	onClose,
	Icon = SuccessIcon,
	children,
	larger = false,
}) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={onClose}
			headerClassName={styles.successModalHeader}
			size="small"
			containerClassName={styles.successModalContainer}
			contentClassName={cx(styles.successModal, larger ? styles.larger : '')}
		>
			<Grid vertical verticalAlign="center" className={styles.successModalContent}>
				{Icon && (
					<Grid column className={styles.successModalIconContainer}>
						<Icon/>
					</Grid>
				)}
				<Grid column className={styles.successModalBody}>
					<RenderByType type={SuccessModalBody}>{children}</RenderByType>
				</Grid>
				<Grid column className={styles.successModalActions}>
					<RenderByType type={SuccessModalActions}>{children}</RenderByType>
				</Grid>
			</Grid>
		</Modal>
	);
};

export default SuccessModal;
