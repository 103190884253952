import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { StatTimer } from 'pdfjs-dist/types/display/display_utils';
import axios from 'axios';

type TInitialState = {
	data: {
		details: any;
		voidDetails: any;
	};
	selectedId: number;
	selectedVoidId: number;
	showModal: boolean;
	isLoading: boolean;
};
const initialState: TInitialState = {
	data: {
		details: null,
		voidDetails: null,
	},
	selectedId: 0,
	selectedVoidId: 0,
	showModal: false,
	isLoading: false,
};

const voidTransaction = createSlice({
	name: 'voidTransaction',
	initialState,
	reducers: {
		prep: (state, payload: any) => {
			state.data.voidDetails = null;
			state.selectedId = payload.id;
		},
		load: (state, payload: any) => {
			const otherInfo_defaultValue = {
				CheckDetails: {
					BankCode: undefined,
					CheckNo: undefined,
				},
			};

			const other_info =
				Object.keys(payload.result.other_info).length !== 0
					? payload.result.other_info
					: otherInfo_defaultValue;

			state.data.details = {
				...payload.result.data,
				other_info,
				created_at: moment(new Date(payload.result.data.created_at)).format(
					'MM/DD/YYYY h:mm:ss a'
				),
			};
			state.showModal = true;
			state.isLoading = false;
		},
		error: (state) => {
			state.isLoading = false;
		},
		closeModal: (state) => {
			state.showModal = false;
		},
		openLoader: (state) => {
			state.isLoading = true;
		},
		closeLoader: (state) => {
			state.isLoading = false;
		},
		prepVoidDetails: (state, payload: any) => {
			state.data.details = payload.details;
		},
		loadVoidDetails: (state, payload: any) => {
			state.data.voidDetails = {
				...payload.result.data
			};
			state.showModal = true;
			state.isLoading = false;
		},
		errorVoidDetails: (state) => {
			state.isLoading = false;
		},
	},
});

const getViewTransaction = (client: any, state: any) => {
	return client.get(`/v1/transactions/${state.voidTransaction.selectedId}/void`);
};

const viewActionCreator = (id: number): any => {
	return {
		types: [prep.type, load.type, error.type],
		promise: getViewTransaction,
		id,
	};
};

const getViewVoidTransaction = (client: any, state: any) => {
	return client.get(`/v1/void/request/${state.voidTransaction.data.details.void_ref_number}`);
}

const viewVoidActionCreator = (details: any): any => {
	return {
		types: [prepVoidDetails.type, loadVoidDetails.type, errorVoidDetails.type],
		promise: getViewVoidTransaction,
		details,
	};
};


export default voidTransaction.reducer;

const { prep, load, error, closeModal, openLoader, closeLoader, prepVoidDetails, loadVoidDetails, errorVoidDetails } =
	voidTransaction.actions;

export { viewVoidActionCreator, viewActionCreator, closeModal, openLoader, closeLoader };
