import { ReactNode, useEffect } from 'react';
import { Modal, Tabs, TabsPanel } from '@salesforce/design-system-react';
import { partnerModalTabs } from 'constants/partner_modal_tabs';
import { connect, useDispatch } from 'react-redux';
import { setCurrentBillerTab } from 'redux/modules/products';
import {
	getProductBasicInfo,
	getProductBusinessRuleDetail,
	getProductContractDetail,
	getProductReportDetail,
	getProductServiceFeeDetail,
	getProductStatus,
} from './PartnerAuditDetailList';

import folderIcon from '../../assets/icons/ic-folder.svg';
import styles from './PartnerAuditModal.module.css';
import Section from 'components/Section/Section';
import Table from 'components/CWSTable/Table';
import cx from 'classnames';

type Props = {
	data?: any;
	onClose?: () => void;
	isOpen: boolean;
	currentBillerTab?: string;
	setCurrentBillerTab: (v?: string) => void;
};

type Tabs = {
	primaryInformation?: any;
	contractDetails?: any;
	serviceFeeSettings?: any;
	businessRules?: any;
	reports?: any;
};

type PAE = {
	title: string | ReactNode;
	data?: any;
	columns: () => any[];
}

const PartnerAuditPlaceholder: React.FC<{ title: string }> = ({ title }) => {
	return (
		<div
			className={cx(
				'slds-grid slds-grid_align-center slds-grid_vertical-align-center',
				styles.tabMaxheightAndWidth
			)}
		>
			<div>
				<img src={folderIcon} className={styles.placeholderImg} />
				<div className={styles.placeholderTitle}>{title}</div>
				<div className={styles.placeholderSubTitle}>
					There are no changes in this tab.
				</div>
			</div>
		</div>
	);
};

const PartnerAuditEntry: React.FC<PAE> = ({ title, data, columns }) => {
	return (
		<>
			<Section title={title} className={styles.section}>
				<Table
					className={styles.paperContainer}
					columns={columns()}
					data={data}
					showPagination={false}
					preHeader={null}
					modalIsOpen={false}
				/>
			</Section>
		</>
	);
};

const PartnerAuditModal: React.FC<Props> = ({
	data,
	isOpen,
	onClose = () => {},
	currentBillerTab,
	setCurrentBillerTab,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setCurrentBillerTab(partnerModalTabs[0].name));
	}, [isOpen]);

	useEffect(() => {
		return () => {
			setCurrentBillerTab('primary-info');
		};
	}, []);

	function findIndex() {
		const idx = partnerModalTabs.find((tab) => tab.name == currentBillerTab);
		return idx?.index;
	}

	function findIndexName(selected: any): string {
		const idx = partnerModalTabs.findIndex((tab) => tab.index == selected);
		return partnerModalTabs[idx].name;
	}

	const toRemoveStatusInViewLog = [
		'Edited partner details',
		'Added partner profile',
		'Download file',
		'Saved draft',
	].map(actions=>actions.toUpperCase());

	const action = data?.action?.toUpperCase();

	const isRemoveInViewLog = toRemoveStatusInViewLog.some(
		(keyword) => action && action === keyword
	);

	const productStatusData = getProductStatus(data);
	
	const [
		productBasicInfoData,
		partnerAddressData,
		partnerContactDetailsData,
		partnerProductAffiliatesData,
		partnerProductOfficerData,
	] = getProductBasicInfo(data);

	const [
		collectionUploadAndRemittanceData,
		oneTimeFeeData,
		uploadPenaltyData,
		depositPenaltyData,
		otherPenaltiesData,
		partnerProductCashCollectionData,
		partnerProductCheckCollectionData,
		partnerProductTermsDurationData,
		partnerProductDPOData,
		partnerBondData,
		partnerProductAccreditationData,
	] = getProductContractDetail(data);

	const [
		serviceFeeBillingTypeData,
		valueAddedTaxData,
		withNonShareableAmountData,
		serviceFeeSchemeTypeData,
		withSFSettlementDeductionData,
		partnerServiceFeeTiersData,
	] = getProductServiceFeeDetail(data);

	const [
		paymentModeData,
		acceptedCheckTypesData,
		requiredDocumentsData,
		areaRestrictionData,
		amountValidationCashData,
		amountValidationCheckData,
		paymentAcceptedData,
		paymentAcceptancePolicyData,
	] = getProductBusinessRuleDetail(data);

	const [
		partnerProductConfigData,
		partnerReportSFTPFTPData,
		partnerSFTPFTPData,
		partnerSFTPUCData,
		partnerReportSFTPUCData,
		partnerReportSMTPData,
		partnerSMTPData,
		partnerReportSMTP_UCData,
		partnerSMTP_UCData,
	] = getProductReportDetail(data);

	const AUDIT_LOG_COLUMNS = () => [
		{
			Header: 'Field Name',
			id: 'field_name',
			sortable: false,
			width: '28%',
		},
		{
			Header: 'Old Values',
			id: 'old_values',
			sortable: false,
			width: '36%',
		},
		{
			Header: 'New Values',
			id: 'new_values',
			sortable: false,
			width: '36%',
		},
	];

	return (
		<>
			<Modal
				size="medium"
				isOpen={isOpen}
				ariaHideApp={false}
				onRequestClose={onClose}
				contentClassName={styles.partnerAuditModal}
				appElement={document.getElementById('app')}
				containerClassName={styles.partnerAuditModalContainer}
				headerClassName={styles.partnerAuditModalHeaderContainer}
				heading={
					<div className={styles.header}>
						<div className={styles.headerLeftPanel}>
							<div className={styles.headerTitle}>View Logs</div>
						</div>
						<div className={styles.headerRightPanel}></div>
					</div>
				}
			>
				<div className={styles.statusAuditLog}>
					{productStatusData?.length > 0 && !isRemoveInViewLog && (
						<PartnerAuditEntry
							columns={AUDIT_LOG_COLUMNS}
							data={productStatusData}
							title={''}
						/>
					)}
				</div>

				<div className={styles.partnerAuditLog}>
					<Tabs
						className={styles.tabs}
						onSelect={(i) => setCurrentBillerTab(findIndexName(i))}
						selectedIndex={findIndex()}
					>
						<TabsPanel
							disabled={false}
							label="Primary Information"
							className={styles.tabsLabel}
						>
							<div className={cx(styles.content + ' ' + styles.bodyContent)}>
								{productBasicInfoData?.length > 0 ||
								partnerProductAffiliatesData?.length > 0 ||
								partnerAddressData?.length > 0 ||
								partnerContactDetailsData?.length > 0 ||
								partnerProductOfficerData?.length > 0 ? (
									<>
										{(productBasicInfoData?.length > 0 ||
											partnerProductAffiliatesData?.length > 0) && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={[
													...productBasicInfoData,
													...partnerProductAffiliatesData,
												]}
												title={<b>Partner Basic Information</b>}
											/>
										)}
										{partnerAddressData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerAddressData}
												title={<b>Partner Address</b>}
											/>
										)}
										{partnerContactDetailsData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerContactDetailsData}
												title={<b>Partner Contact Details</b>}
											/>
										)}

										{partnerProductOfficerData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerProductOfficerData}
												title={<b>Account Officer</b>}
											/>
										)}
									</>
								) : (
									<>
										<PartnerAuditPlaceholder title="Primary Information" />
									</>
								)}
							</div>
						</TabsPanel>
						<TabsPanel label="Contract Details" disabled={false}>
							<div className={cx(styles.content + ' ' + styles.bodyContent)}>
								{collectionUploadAndRemittanceData?.length > 0 ||
								oneTimeFeeData?.length > 0 ||
								uploadPenaltyData?.length > 0 ||
								depositPenaltyData?.length > 0 ||
								otherPenaltiesData?.length > 0 ||
								partnerProductCashCollectionData?.length > 0 ||
								partnerProductCheckCollectionData?.length > 0 ||
								partnerProductTermsDurationData?.length > 0 ||
								partnerProductDPOData?.length > 0 ||
								partnerBondData?.length > 0 ||
								partnerProductAccreditationData?.length > 0 ? (
									<>
										{collectionUploadAndRemittanceData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={collectionUploadAndRemittanceData}
												title={<b>Collection Upload & Remittance</b>}
											/>
										)}
										{oneTimeFeeData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={oneTimeFeeData}
												title={<b>One Time Fee</b>}
											/>
										)}
										{uploadPenaltyData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={uploadPenaltyData}
												title={<b>Upload Penalty</b>}
											/>
										)}
										{depositPenaltyData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={depositPenaltyData}
												title={<b>Deposit Penalty</b>}
											/>
										)}
										{otherPenaltiesData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={otherPenaltiesData}
												title={<b>Other Penalties</b>}
											/>
										)}
										{partnerProductCashCollectionData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerProductCashCollectionData}
												title={<b>Cash Collection Depository Account</b>}
											/>
										)}
										{partnerProductCheckCollectionData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerProductCheckCollectionData}
												title={<b>Check Collection Depository Account</b>}
											/>
										)}
										{partnerProductTermsDurationData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerProductTermsDurationData}
												title={<b>Terms And Duration</b>}
											/>
										)}
										{partnerProductDPOData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerProductDPOData}
												title={<b>Data Sharing Agreement (DSA)</b>}
											/>
										)}
										{partnerBondData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerBondData}
												title={<b>Bond</b>}
											/>
										)}
										{partnerProductAccreditationData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerProductAccreditationData}
												title={<b>Accreditation Requirements</b>}
											/>
										)}
									</>
								) : (
									<>
										<PartnerAuditPlaceholder title="Contract Details" />
									</>
								)}
							</div>
						</TabsPanel>
						<TabsPanel label="Service Fee Settings" disabled={false}>
							<div className={cx(styles.content + ' ' + styles.bodyContent)}>
								{serviceFeeBillingTypeData?.length > 0 ||
								valueAddedTaxData?.length > 0 ||
								withNonShareableAmountData?.length > 0 ||
								serviceFeeSchemeTypeData?.length > 0 ||
								withSFSettlementDeductionData?.length > 0 ||
								partnerServiceFeeTiersData?.length > 0 ? (
									<>
										{serviceFeeBillingTypeData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={serviceFeeBillingTypeData}
												title={<b>Service Fee Billing Type</b>}
											/>
										)}
										{valueAddedTaxData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={valueAddedTaxData}
												title={<b>Value Added Tax (VAT)</b>}
											/>
										)}
										{withNonShareableAmountData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={withNonShareableAmountData}
												title={<b>With Non-Shareable Amount?</b>}
											/>
										)}
										{serviceFeeSchemeTypeData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={serviceFeeSchemeTypeData}
												title={<b>Service Fee Scheme Type</b>}
											/>
										)}
										{withSFSettlementDeductionData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={withSFSettlementDeductionData}
												title={<b>With SF Settlement Deduction on Collections (Net Settlement)?</b>}
											/>
										)}

										{partnerServiceFeeTiersData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerServiceFeeTiersData}
												title={<b>Service Fee</b>}
											/>
										)}
									</>
								) : (
									<PartnerAuditPlaceholder title="Service Fee Settings" />
								)}
							</div>
						</TabsPanel>
						<TabsPanel label="Business Rules" disabled={false}>
							<div className={cx(styles.content + ' ' + styles.bodyContent)}>
								{paymentModeData?.length > 0 ||
								acceptedCheckTypesData?.length > 0 ||
								requiredDocumentsData?.length > 0 ||
								areaRestrictionData?.length > 0 ||
								amountValidationCashData?.length > 0 ||
								amountValidationCheckData?.length > 0 ||
								paymentAcceptedData?.length > 0 ||
								paymentAcceptancePolicyData?.length > 0 ? (
									<>
										{paymentModeData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={paymentModeData}
												title={<b>Payment Mode</b>}
											/>
										)}
										{acceptedCheckTypesData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={acceptedCheckTypesData}
												title={<b>Accepted Check Types</b>}
											/>
										)}
										{requiredDocumentsData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={requiredDocumentsData}
												title={<b>Required Documents</b>}
											/>
										)}
										{areaRestrictionData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={areaRestrictionData}
												title={<b>Area Restriction</b>}
											/>
										)}

										{amountValidationCashData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={amountValidationCashData}
												title={<b>Amount Validation - Cash</b>}
											/>
										)}
										{amountValidationCheckData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={amountValidationCheckData}
												title={<b>Amount Validation - Check</b>}
											/>
										)}
										{paymentAcceptedData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={paymentAcceptedData}
												title={<b>Payment Accepted</b>}
											/>
										)}
										{paymentAcceptancePolicyData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={paymentAcceptancePolicyData}
												title={<b>Payment Acceptance Policy</b>}
											/>
										)}
									</>
								) : (
									<PartnerAuditPlaceholder title="Business Rule" />
								)}
							</div>
						</TabsPanel>
						<TabsPanel label="Reports" disabled={false}>
							<div className={cx(styles.content, styles.bodyContent)}>
								{partnerProductConfigData?.length > 0 ||
								partnerReportSFTPFTPData?.length > 0 ||
								partnerSFTPFTPData?.length > 0 ||
								partnerReportSFTPUCData?.length > 0 ||
								partnerSFTPUCData?.length > 0 ||
								partnerReportSMTPData?.length > 0 ||
								partnerSMTPData?.length > 0 ||
								partnerReportSMTP_UCData?.length > 0 ||
								partnerSMTP_UCData?.length > 0 ? (
									<>
										{partnerProductConfigData?.length > 0 && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={partnerProductConfigData}
												title={<b>SFTP/FTP</b>}
											/>
										)}
										{(partnerReportSFTPFTPData?.length > 0 ||
											partnerSFTPFTPData?.length > 0) && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={[
													...partnerReportSFTPFTPData,
													...partnerSFTPFTPData,
												]}
												title={<b>
													SFTP/FTP - Collection Summary Report (CSR) Configuration
												</b>}
											/>
										)}

										{(partnerReportSFTPUCData?.length > 0 ||
											partnerSFTPUCData?.length > 0) && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={[
													...partnerReportSFTPUCData,
													...partnerSFTPUCData,
												]}
												title={<b>SFTP/FTP - Upload Configuration</b>}
											/>
										)}

										{(partnerReportSMTPData?.length > 0 ||
											partnerSMTPData?.length > 0) && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={[...partnerReportSMTPData, ...partnerSMTPData]}
												title={<b>SMTP - Collection Summary Report (CSR) Configuration</b>}
											/>
										)}
										{(partnerReportSMTP_UCData?.length > 0 ||
											partnerSMTP_UCData?.length > 0) && (
											<PartnerAuditEntry
												columns={AUDIT_LOG_COLUMNS}
												data={[
													...partnerReportSMTP_UCData,
													...partnerSMTP_UCData,
												]}
												title={<b>SMTP - Upload Configuration</b>}
											/>
										)}
									</>
								) : (
									<PartnerAuditPlaceholder title="Reports" />
								)}
							</div>
						</TabsPanel>
					</Tabs>
				</div>
			</Modal>
		</>
	);
};

export default connect(
	(state: any) => ({
		currentBillerTab: state.products.currentBillerTab,
	}),
	{ setCurrentBillerTab }
)(PartnerAuditModal);