import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Modal } from '@salesforce/design-system-react/module/components';
import {
	closeModal,
	closeLoader,
	openLoader,
} from '../../redux/modules/voidTransaction';
import Grid from '../Grid/Grid';
import { SectionRow } from 'components/Section/Section';
import TextField from 'components/Inputs/TextField/TextField';
import DateSelect from 'components/Inputs/DateSelect/DateSelect';
import cx from 'classnames';
import { useForm, useFormState } from 'react-hook-form';
import styles from './VoidTransactionModal.module.css';
import Checkbox from 'components/Checkbox/Checkbox';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchableCheckbox from 'components/Inputs/SearchableCheckbox/SearchableCheckBox';
import ConfirmModal from 'components/Modal/ConfirmModal';

import { useToggle } from 'utils/hooks';
import { VoidReason } from 'utils/lookup';
import moment from 'moment';
import voidTransactionSchema from 'utils/formSchemas/voidTransactionForm/voidTransactionForm';
import { yupResolver } from '@hookform/resolvers/yup';
import client from 'helpers/ApiClient';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import UploadAttachments from 'components/UploadAttachments/UploadAttachments';
import { ReducerStateType } from 'redux/modules/reducers';
import { CancelTokenSource } from 'axios';
import { cancelRequest, TimeoutSliceState } from 'redux/modules/timeout';

type VoidTransactionProps = {
	voidTransaction: {
		showModal: boolean;
		data: {
			details: any;
			voidDetails: any;
			logs: any;
		};
		isLoading: boolean;
		selectedId?: any;
		// other_info: string;
	};
};

type UserInfoProps = {
	userInfo: {
		firstname: string;
		lastname: string;
		username: string;
	};
};

type Props = {
	showModal: VoidTransactionProps['voidTransaction']['showModal'];
	details: VoidTransactionProps['voidTransaction']['data']['details'];
	voidDetails: VoidTransactionProps['voidTransaction']['data']['voidDetails'];
	userInfo: UserInfoProps['userInfo'];
	// other_info: VoidTransactionProps['voidTransaction']['other_info'];
	isLoading: boolean;
	closeModal: () => void;
	closeLoader: () => void;
	selectedId?: any;
	openLoader: () => void;
};

type VoidTransactionFormData = any;
type FooterProps = {
	handleSubmit: any;
	validateAttachments: any;
	showConfirmModal: any;
	isLoading: boolean;
	isDisabled: boolean;
};
const Footer: React.FC<FooterProps> = ({
	isLoading,
	handleSubmit,
	validateAttachments,
	showConfirmModal,
	isDisabled,
}) => {
	return (
		<div className={styles.footer}>
			<PrimaryButton
				onClick={showConfirmModal}
				className={styles.btn}
				disabled={isLoading}
			>
				Cancel
			</PrimaryButton>
			<PrimaryButton
				onClick={() => {
					handleSubmit();
					validateAttachments();
				}}
				className={styles.btn}
				disabled={isDisabled}
			>
				Submit
			</PrimaryButton>
		</div>
	);
};

const PillStatus: React.FC<any> = ({ status, ...rest }) => {
	type TStatusObj = {
		colorClass: string;
		name: string;
	};

	const optionStatus: TStatusObj[] = [
		{
			colorClass: 'pill_pending',
			name: 'Pending',
		},
		{
			colorClass: 'pill_approved',
			name: 'Approved',
		},
		{
			colorClass: 'pill_rejected',
			name: 'Rejected',
		},
		{
			colorClass: 'pill_expired',
			name: 'Expired',
		},
	];
	const selected = optionStatus.find(
		(option: TStatusObj) => option.name.toLowerCase() === status?.toLowerCase()
	) || { name: status, colorClass: optionStatus[3].colorClass };

	return (
		<>
			{selected.name && (
				<span
					className={cx(
						'slds-badge',
						'slds-float_right',
						styles[selected.colorClass]
					)}
					{...rest}
				>
					{`${selected?.name[0].toUpperCase()}${selected?.name
						.slice(1)
						.toLowerCase()}`}
				</span>
			)}
		</>
	);
};

const WithinTheDayCancellation: React.FC<{
	billerType: string;
	createdAt: string;
	getIfDateIsToday: (date: string) => boolean;
}> = ({ createdAt, getIfDateIsToday, billerType }) => {
	if (billerType.toUpperCase() === 'RTP') return null;
	return (
		<li>
			<div>For within the Day Cancellation</div>:{' '}
			<span>{getIfDateIsToday(createdAt) ? 'Yes' : 'No'}</span>
		</li>
	);
};

const CashOrCheckTransactionInfo: React.FC<any> = ({
	details,
	getIfDateIsToday,
}): any => {
	if (details.mode_payment === 'CASH')
		return (
			<div className={styles.voidRequestInfoContainer}>
				<ul>
					<li>
						<div>Transaction Date</div>:{' '}
						<span>{details.created_at.toUpperCase()}</span>
					</li>
					<li>
						<div>Transaction Reference Number</div>: <span>{details.trn}</span>
					</li>
					<li>
						<div>Branch</div>: <span>{details.channel_name}</span>
					</li>
					<li>
						<div>TPAID</div>: <span>{details.tpaid}</span>
					</li>
					<li>
						<div>Biller</div>: <span>{details.biller_name}</span>
					</li>
					<li>
						<div>Mode of Payment </div>: <span>Cash</span>
					</li>
					<li>
						<div>Amount</div>: <span>{details.amount}</span>
					</li>
					<li>
						<div>Account Number</div>: <span>{details.arn}</span>
					</li>
					<li>
						<div>Date of Request</div>:{' '}
						<span>
							{moment().format('MM/DD/YYYY HH:mm:ss a').toUpperCase()}
						</span>
					</li>
					<li>
						<div>Biller Type</div>:{' '}
						<span>
							{details.biller_type === 'Batch'
								? 'Non-RTP'
								: details.biller_type}
						</span>
					</li>
					<WithinTheDayCancellation
						billerType={details?.biller_type}
						createdAt={details.created_at}
						getIfDateIsToday={getIfDateIsToday}
					/>
				</ul>
			</div>
		);

	return (
		<div className={styles.voidRequestInfoContainer}>
			<ul>
				<li>
					<div>Transaction Date</div>:{' '}
					<span>{details.created_at.toUpperCase()}</span>
				</li>
				<li>
					<div>Transaction Reference Number</div>: <span>{details.trn}</span>
				</li>
				<li>
					<div>Branch</div>: <span>{details.channel_name}</span>
				</li>
				<li>
					<div>TPAID</div>: <span>{details.tpaid}</span>
				</li>
				<li>
					<div>Biller</div>: <span>{details.biller_name}</span>
				</li>
				<li>
					<div>Mode of Payment </div>: <span>{details.mode_payment}</span>
				</li>
				<li>
					<div>Amount</div>: <span>{details.amount}</span>
				</li>
				<li>
					<div>Account Number</div>: <span>{details.arn}</span>
				</li>
				<li>
					<div>Bank Name</div>:{' '}
					<span>
						{(details.other_info.CheckDetails?.BankName
							? details.other_info.CheckDetails?.BankName
							: '') +
							' - ' +
							(details.other_info.CheckDetails?.BankBranch
								? details.other_info.CheckDetails?.BankBranch
								: '')}
					</span>
				</li>
				<li>
					<div>Check Number</div>:{' '}
					<span>{details.other_info.CheckDetails?.CheckNo}</span>
				</li>
				<li>
					<div>Date of Request</div>:{' '}
					<span>{moment().format('MM/DD/YYYY HH:mm:ss a').toUpperCase()}</span>
				</li>
				<li>
					<div>Biller Type</div>: <span>{details.biller_type}</span>
				</li>
				<WithinTheDayCancellation
					billerType={details?.biller_type}
					createdAt={details.created_at}
					getIfDateIsToday={getIfDateIsToday}
				/>
			</ul>
		</div>
	);
};

const VoidTransactionModal: React.FC<Props> = ({
	showModal,
	closeModal,
	details,
	isLoading,
	openLoader,
	closeLoader,
	selectedId,
	userInfo,
	voidDetails,
}) => {
	const { control, handleSubmit, reset, clearErrors, setValue } =
		useForm<VoidTransactionFormData>({
			mode: 'all',
			resolver: yupResolver(voidTransactionSchema),
			defaultValues: {},
		});

	const { dirtyFields } = useFormState({ control });

	const [isDisabled, setIsDisabled] = useState(true);
	const [fileList, setFileList] = useState([]);

	const requiredFields = [
		'voidReasons',
		'voidRequestorName',
		'voidRequestorContact',
		'incidentReport',
		'isResponsibilityChecked',
	];

	const optionalFields = ['depositoryBankAccountNumber', 'dateOfDeposit'];

	useEffect(() => {
		const removeOptionalFieldsInDirty = (dirty: any) => {
			optionalFields.forEach((element: any) => {
				const i = dirty.indexOf(element);
				if (i >= 0) dirty.splice(i, 1);
			});
		};

		const requiredFieldsChecker = (arr: any, target: any) =>
			target.every((v: any) => arr.includes(v)) && fileList.length > 0;

		if (
			Object.keys(dirtyFields).length !== 0 &&
			dirtyFields.constructor === Object
		) {
			const dirty = Object.keys(dirtyFields);
			removeOptionalFieldsInDirty(dirty);
			const check =
				requiredFieldsChecker(requiredFields, dirty) &&
				dirty.length >= requiredFields.length
					? false
					: true;
			setIsDisabled(check);
		}
	}, [requiredFields, optionalFields, dirtyFields, fileList]);

	useEffect(() => {
		if (voidDetails !== null) {
			setValue('isResponsibilityChecked', true);
			setValue(
				'depositoryBankAccountNumber',
				voidDetails.depository_account_number
			);
		} else {
			setValue('isResponsibilityChecked', false);
			setValue('depositoryBankAccountNumber', '');
		}
	}, [voidDetails]);
	const dispatch = useDispatch();
	const { isInProgress } = useSelector<ReducerStateType>(
		(state) => state.timeout
	) as TimeoutSliceState;

	useEffect(() => {
		closeLoader();
		setFileList([]);

		if (isInProgress) dispatch(cancelRequest());
		if (!showModal) {
			closeLoader();
			hideConfirmModal();
			reset({});
			setIsDisabled(true);
		}
	}, [showModal]);

	const {
		value: isConfirmModalShowing,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const {
		value: isAttachmentError,
		valueOn: attachmentErrorTrue,
		valueOff: attachmentErrorFalse,
	} = useToggle();

	const {
		value: isConfirmVoidShowing,
		valueOn: showConfirmVoidShowing,
		valueOff: hideConfirmVoidShowing,
	} = useToggle();

	const {
		value: isErrorVoidShowing,
		valueOn: showErrorVoidShowing,
		valueOff: hideErrorVoidShowing,
	} = useToggle();

	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const close = () => {
		hideSuccessModal();
	};

	const validateAttachments = () => {
		if (fileList.length == 0) {
			attachmentErrorTrue();
		}
	};

	useEffect(() => {
		if (fileList.length > 0) {
			attachmentErrorFalse();
		}
	}, [fileList]);

	const onSubmit = async (values: any) => {
		const _voidRequestInfo = {
			void_request_info: {
				id: selectedId,
				biller_type: details.biller_type,
				for_cancelation_within_day: getIfDateIsToday(details.created_at)
					? '1'
					: '0',
				reason: values.voidReasons.options.join(',') || '',
				others: values.voidReasons.otherVoidReason || '',
				bank_name: details.other_info.CheckDetails?.BankCode || '',
				check_no: details.other_info.CheckDetails?.CheckNo || '',
				depository_account_number: values.depositoryBankAccountNumber || '',
				deposit_date: values.dateOfDeposit || '',
				requestor_name: values.voidRequestorName,
				requestor_contact_number: values.voidRequestorContact,
				incident_report: values.incidentReport,
				username: userInfo.username,
				attachments: fileList,
			},
		};

		try {
			openLoader();

			await client.post('/v1/void/request', _voidRequestInfo);
			showSuccessModal();
			closeLoader();
			closeModal();
		} catch (error) {
			closeLoader();
			showErrorVoidShowing();
		}
	};

	const getIfDateIsToday = (date: any) => {
		const _today = new Date();
		const _inputDate = new Date(date);

		if (_inputDate.setHours(0, 0, 0, 0) === _today.setHours(0, 0, 0, 0)) {
			return true;
		}

		return false;
	};

	return (
		<>
			<FullPageLoader
				open={isLoading}
				message="Please wait while the void details is loading"
			/>
			<Modal
				style={{ display: 'none' }}
				className={styles.voidTransactionModal}
				isOpen={showModal}
				onRequestClose={voidDetails ? closeModal : showConfirmModal}
				size="small"
				footer={
					voidDetails ? (
						''
					) : (
						<Footer
							handleSubmit={handleSubmit((v) => {
								showConfirmVoidShowing();
							})}
							validateAttachments={validateAttachments}
							showConfirmModal={voidDetails ? closeModal : showConfirmModal}
							isLoading={isLoading}
							isDisabled={isDisabled}
						/>
					)
				}
			>
				<div className={cx('slds-card', styles.container)}>
					<div className={styles.topSticky}>
						{voidDetails && (
							<a onClick={closeModal} style={{ color: 'rgb(60, 144, 223)' }}>
								&lt; Back
							</a>
						)}

						<br />
						<span className={styles.voidHeader}>Void Transaction</span>
						<br />
						<div className={styles.vrText}>
							<div
								className={`slds-grid slds-grid_align-end slds-m-bottom_x-small`}
							>
								<div className="slds-col slds-size_2-of-12">
									<span
										className={`slds-text-body_regular slds-m-top_xx-small`}
									>
										Void Reference ID:
									</span>
								</div>
								<div className="slds-col slds-size_3-of-12">
									<span className={`slds-float_right ${styles.voidRefId}`}>
										{details?.void_ref_number}
									</span>
								</div>
							</div>
							<div className="slds-grid slds-grid_align-end slds-m-bottom_x-small">
								<div className="slds-col slds-size_3-of-12">
									<span
										className={`slds-text-body_regular slds-m-top_xx-small`}
									>
										Void Request Status:
									</span>
								</div>
								<div className="slds-col slds-size_2-of-12">
									<PillStatus
										status={details?.void_request_status}
										style={{
											color: 'white',
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div>
						<span className={styles.voidSubHeader}>
							Void Request Information
						</span>

						<CashOrCheckTransactionInfo
							details={details}
							getIfDateIsToday={getIfDateIsToday}
						/>
					</div>
					<br />
					<fieldset
						disabled={voidDetails != null}
						style={{
							minWidth: '0',
							maxWidth: '100%',
						}}
					>
						<div className={styles.voidRequestFields}>
							<SectionRow>
								<Grid column size={1} of={2}>
									<TextField
										name="depositoryBankAccountNumber"
										label="Depository Bank Account No."
										control={control}
										optional
									/>
								</Grid>
								<Grid column size={1} of={2}>
									<DateSelect
										name="dateOfDeposit"
										label="Date of Deposit"
										placeholder="Date of Deposit"
										control={control}
										value={
											moment(voidDetails && voidDetails?.deposit_date).isValid()
												? moment(
														voidDetails?.deposit_date,
														'YYYY-MM-DD'
												  ).toDate()
												: undefined
										}
										optional
										disabled={voidDetails ? true : false}
									/>
								</Grid>
							</SectionRow>
						</div>

						<SearchableCheckbox
							label="Void Reason"
							name="voidReasons"
							otherTextboxName="otherVoidReason"
							control={control}
							options={VoidReason}
							defaultValue={{
								choices: voidDetails?.reason,
								otherText: voidDetails?.others,
							}}
							required
						/>
						<UploadAttachments
							changeFileList={(val) => setFileList(val)}
							defaultFiles={voidDetails?.attachments}
							displayError={isAttachmentError}
						/>
						<hr className={styles.divider} />
						<h3>Void Requester Information</h3>
						<SectionRow>
							<Grid column size={1} of={2}>
								<TextField
									name="voidRequestorName"
									label="Name of Void Requestor"
									control={control}
									required
									defaultValue={voidDetails?.requestor_name}
								/>
							</Grid>
							<Grid column size={1} of={2}>
								<TextField
									name="voidRequestorContact"
									label="Contact Number of Void Requestor"
									control={control}
									required
									defaultValue={voidDetails?.requestor_contact_number}
								/>
							</Grid>
						</SectionRow>
						<hr className={styles.divider} />
						<h3>Incident Report</h3>
						<TextField
							className={styles.incidentReportBox}
							label="Incident Report"
							placeholder="Incident Report"
							name="incidentReport"
							control={control}
							multiLine
							required
							defaultValue={voidDetails?.incident_report}
							resizable={false}
						/>
						<div className={styles.bottomSticky}>
							<Checkbox
								className={styles.responsibilityCheckbox}
								label="I will take full responsibility in case the customer has any dispute in the posted transaction versus the validated amount in the statement of account or payment form. I will not hold Bayad Center liable for the difference, if any."
								name="isResponsibilityChecked"
								control={control}
							/>
						</div>
					</fieldset>
				</div>
			</Modal>

			<ConfirmModal
				open={isConfirmModalShowing}
				disableClose={false}
				onClose={hideConfirmModal}
				headerText="Close Void Transaction"
				bodyText={[
					() => (
						<>
							<div>
								Are you sure you want to close <b>Void Transaction</b>?
							</div>
							<div>
								Your progress will not be saved, if you wish to continue
							</div>
						</>
					),
				]}
				confirmButton={{
					name: 'Confirm',
					event: closeModal,
				}}
				cancelButton={{
					name: 'Cancel',
					event: hideConfirmModal,
				}}
			/>

			<ConfirmModal
				open={isConfirmVoidShowing}
				disableClose={false}
				onClose={hideConfirmVoidShowing}
				headerText="Void Transaction"
				bodyText={[
					() => (
						<>
							<div>Are you sure you want to proceed?</div>
						</>
					),
				]}
				confirmButton={{
					name: 'Confirm',
					event: handleSubmit((v) => {
						hideConfirmVoidShowing();
						onSubmit(v);
					}),
				}}
				cancelButton={{
					name: 'Cancel',
					event: hideConfirmVoidShowing,
				}}
			/>

			<SuccessModal open={isSuccessModalOpen} onClose={hideSuccessModal}>
				<SuccessModalBody>
					<SuccessText>
						<div className={styles.successHeader}>
							<b>Void Transaction Successful!</b>
						</div>
						<div className={styles.successMessage}>
							<p className={styles.subtext}>You have successfully voided </p>
							<div className={styles.trnText}>
								<b>{details?.trn}</b>
							</div>
						</div>
					</SuccessText>
				</SuccessModalBody>
				<SuccessModalActions>
					<PrimaryButton
						className={styles.addressErrorCloseBtn}
						onClick={close}
					>
						Done
					</PrimaryButton>
				</SuccessModalActions>
			</SuccessModal>
			<ErrorModal open={isErrorVoidShowing} onClose={hideErrorVoidShowing}>
				<ErrorModalBody>
					<ErrorText>Timeout Error</ErrorText>
					<ErrorSubText>
						A problem occured while submitting the data please try again.
					</ErrorSubText>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						className={styles.contactDetailsErrorCloseBtn}
						onClick={hideErrorVoidShowing}
					>
						Close
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};
export default connect(
	(state: VoidTransactionProps & UserInfoProps) => ({
		showModal: state.voidTransaction.showModal,
		details: state.voidTransaction.data.details,
		voidDetails: state.voidTransaction.data.voidDetails,
		isLoading: state.voidTransaction.isLoading,
		selectedId: state.voidTransaction.selectedId,
		userInfo: state.userInfo,
	}),
	{ closeModal, closeLoader, openLoader }
)(VoidTransactionModal);
