import { SLDSDropdownOptionType } from 'types';

const channelType: SLDSDropdownOptionType[] = [
	{ label: 'Center', value: 'Center' },
	{ label: 'Partner', value: 'Partner' },
	{ label: 'Agent', value: 'Agent' },
	{ label: 'Machine', value: 'Machine' },
	{ label: 'Collect', value: 'Collect' },
	{ label: 'Digital', value: 'Digital' },
];

export default channelType;
