import cx from 'classnames';
import React from 'react';
import styles from './Filter.module.css';

const FilterInner: React.FC = ({ children, ...props }) => (
	<div className={cx('slds-grid_vertical', styles.innerContainer)} {...props}>
		<div className='slds-col'>Filter:</div>
		<div
			className={cx(
				styles.filterLabel,
				'slds-col',
				'slds-p-vertical_x-small'
			)}
		>
			{children}
		</div>
	</div>
);

export default FilterInner;
