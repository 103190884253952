import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type TimeoutSliceState = {
	showModal: boolean;
	uploadRequest: any;
	isInProgress: boolean;
};

const initialState: TimeoutSliceState = {
	showModal: false,
	isInProgress: false,
	uploadRequest: axios.CancelToken.source(),
};

const sessionTimeout = createSlice({
	name: 'sessionTimeout',
	initialState,
	reducers: {
		toggleModal: (state) => {
			state.showModal = !state.showModal;
		},
		setIsInProgress: (state, { payload }) => {
			state.isInProgress = payload;
		},
		cancelRequest: (state) => {
			state.uploadRequest.cancel();
			state.uploadRequest = axios.CancelToken.source();
		},
	},
});

export const { toggleModal, setIsInProgress, cancelRequest } =
	sessionTimeout.actions;
export default sessionTimeout.reducer;
