import SelectField, {
	SelectFieldProps,
	SelectOption,
} from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import Grid from 'components/Grid/Grid';
import { Control } from 'react-hook-form';

import styles from './SelectFieldWithOthers.module.css';

export type SelectFieldWithOthersProps = Omit<
	SelectFieldProps,
	'label' | 'options'
> & {
	label: string;
	disabled?: boolean;
	showOthersInputField?: boolean;
	control: Control<any>;
	othersInputName?: string;
	options: SelectOption[];
};

export const SelectFieldWithOthers: React.FC<SelectFieldWithOthersProps> = ({
	label,
	required = true,
	disabled,
	showOthersInputField = false,
	control,
	name,
	othersInputName,
	defaultValue,
	options,
	...rest
}) => {
	if (showOthersInputField) {
		return (
			<Grid container verticalAlign="start" className={styles.otherMain}>
				<Grid column size={1} of={2} className={styles.othersSelectField}>
					<SelectField
						label={label}
						required={required}
						disabled={disabled}
						options={options}
						name={name}
						control={control}
						{...rest}
						defaultValue={defaultValue}
					/>
				</Grid>
				<Grid column size={1} of={2} className={styles.othersTextField}>
					<TextField
						label="Other"
						disabled={disabled}
						placeholder="Others"
						name={othersInputName}
						control={control}
						labelClassName={styles.otherLabel}
					/>
				</Grid>
			</Grid>
		);
	}
	return (
		<SelectField
			label={label}
			required={required}
			disabled={disabled}
			name={name}
			control={control}
			options={options}
			{...rest}
		/>
	);
};
