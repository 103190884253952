import React, { useState, useEffect, useMemo } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { connect, useSelector } from 'react-redux';
import { formatDate } from 'utils/common';
import { useToggle } from 'utils/hooks';
import _, { isEmpty } from 'lodash';

import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';

import SuccessModal, {
	SuccessModalBody,
	SuccessText,
	SuccessModalActions,
} from 'components/Modal/SuccessModal';

import PartnerAuditModal from 'components/PartnerAuditModal/PartnerAuditModal';
import PartnerStatusSelect from '../PartnerStatusSelect/PartnerStatusSelect';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PartnerAuditTrailTable from './PartnerAuditTrailTable';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './PartnerAuditTrailList.module.css';
import Grid from 'components/Grid/Grid';
import HTTP from 'helpers/ApiClient';
import cx from 'classnames';

type GetAuditTrailArgs = {
	page?: number;
	limit?: number;
};

const PartnerAuditTrailList = ({
	selectedBiller: selectedBillerPerCategory,
	product_type_id,
}) => {
	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);
	const hasUserPermission = useHasUserPermission('products');

	const hasAuditTrailPermission = useMemo(
		() => hasUserPermission(productCategory, 'list.audit-trail'),
		[hasUserPermission, productCategory]
	);

	const selectedBiller = useMemo(
		() => selectedBillerPerCategory[productCategory],
		[productCategory, selectedBillerPerCategory]
	);

	const product_id = selectedBiller ? selectedBiller.id : null;
	const [loadingMessage, setLoadingMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const {
		value: isSuccessModalShown,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isPartnerAuditModalVisible,
		valueOn: showPartnerAuditModal,
		valueOff: hidePartnerAuditModal,
	} = useToggle();

	const [data, setData] = useState([]);
	const [auditDetailsList, setAuditDetailsList] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(25);
	const [count, setCount] = useState(0);

	const errorMessageDuringLoading = 'A problem occurred with loading the data';

	useEffect(() => {
		initialFetch();
	}, []);

	useEffect(() => {
		initialFetch();
		setPage(1);
	}, [selectedBiller]);

	const getAuditDetails = async (audit_id, product_type_id, product_id) => {
		showLoadingMessage(
			"Please wait while the selected audit log's entries are being loaded"
		);
		const result = await HTTP.get(
			`/v2/products/${product_type_id}/${product_id}/audit-trails/${audit_id}`
		)
			.then((resp) => {
				const data = resp.data.data;
				if (!data) {
					throw new Error('Invalid response data');
				}
				const formattedData = isEmpty(data.audit_detail)
					? []
					: typeof data.audit_detail === 'string'
					? JSON.parse(data.audit_detail)
					: data;
				setAuditDetailsList(formattedData);
			})
			.finally(() => {
				hideLoading();
			});
	};

	const getAuditTrails = async function (
		values: GetAuditTrailArgs,
		product_type_id,
		product_id
	) {
		const { page, limit } = values;
		const params = { page, limit };

		const result = await HTTP.get(
			`/v2/products/${product_type_id}/${product_id}/audit-trails`,
			{
				params,
			}
		);

		return result.data;
	};

	const fetchAuditTrailList = async (
		values,
		retry = false,
		withLoader = true
	) => {
		const doRequest = async (p) => {
			try {
				withLoader && showLoadingMessage(
					'Please wait while audit logs are being loaded'
				);
				const result = await getAuditTrails(p, product_type_id, product_id);
				const { data, meta } = result;
				setData(data);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e) {
				if (withLoader) {
					showErrorMessage(errorMessageDuringLoading);
					setRetryBtnOnClick({
						action: () => {
							fetchAuditTrailList(values, retry, withLoader);
						},
					});
					return;
				}
				throw e;
			} finally {
				withLoader && hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const params = {
			page,
			limit: pageSize,
			...values,
		};

		await doRequest(params);
	};

	const initialFetch = async () => {
		if (!hasAuditTrailPermission) return;

		try {
			await fetchAuditTrailList({}, false, true);
		} catch (e: any) {
			showErrorMessage(errorMessageDuringLoading);
			setRetryBtnOnClick({
				action: () => {
					initialFetch();
				},
			});
		} finally {
			hideLoading();
		}
	};

	const handleOnClickViewLogs = async (row) => {
		const audit_id = row.original.id;
		await getAuditDetails(audit_id, product_type_id, product_id);
		showPartnerAuditModal();
	};

	const handlePageChange = async (_page, _pageSize) => {
		const params = { page, pageSize };
		const { page: _, pageSize: __ } = params;
		if (page !== _page || pageSize !== _pageSize) {
			setPage(_page);
			setPageSize(_pageSize);
			await fetchAuditTrailList({ page: _page, limit: _pageSize });
		}
	};

	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
		showErrorModal();
	};

	const AUDIT_TRAIL_COLUMNS = (handleViewLogs) => [
		{
			Header: 'Date & Time',
			id: 'created_at',
			sortable: false,
			width: '20%',
			accessor: ({ created_at }) => formatDate(created_at),
		},
		{
			Header: 'Logged By',
			id: 'logged_by',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Action',
			id: 'action',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Source IP',
			id: 'ip_address',
			sortable: false,
			width: '16%',
		},
		{
			Header: 'Remarks',
			id: 'remarks',
			sortable: false,
			width: '16%',
		},
		{
			Header: '',
			id: 'logs',
			sortable: false,
			width: '16%',
			Cell: ({ row }) => (
				<div className={styles.actionContainer}>
					<div
						className={cx(styles.action)}
						onClick={() => handleOnClickViewLogs(row)}
					>
						View Logs
					</div>
				</div>
			),
		},
	];

	return selectedBiller ? (
		<>
			{isLoading && (
				<FullPageLoader open={isLoading} message={loadingMessage} />
			)}
			{isSuccessModalShown && (
				<SuccessModal open={isSuccessModalShown} onClose={hideSuccessModal}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successHeader}>Success!</div>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								hideSuccessModal();
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalShown && (
				<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>Timeout Error!</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>Please try again</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								hideErrorModal();
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			{selectedBiller && !isLoading && (
				<>
					<div className={styles.title}>{selectedBiller.name}</div>
					<Grid container gutters="xx-small">
						<Grid column>
							<div className={styles.statusContainer}>
								<div className={styles.statusLabel}>Partner Status:</div>
								<div className={styles.statusField}>
									<PartnerStatusSelect value={selectedBiller.status} disabled />
								</div>
							</div>
						</Grid>
					</Grid>
					<div className={styles.partnerAuditTrailTableContainer}>
						<PartnerAuditTrailTable
							columns={AUDIT_TRAIL_COLUMNS(handleOnClickViewLogs)}
							data={data}
							pageSize={pageSize}
							page={page}
							count={count}
							onPageChange={handlePageChange}
						/>
					</div>
				</>
			)}
			<PartnerAuditModal
				isOpen={isPartnerAuditModalVisible}
				data={auditDetailsList}
				onClose={() => {
					hidePartnerAuditModal();
				}}
			/>
		</>
	) : (
		<></>
	);
};

export default connect((state: any) => ({
	selectedBiller: state.products.selectedBiller,
	product_type_id: state.sidebar.itemId,
}))(PartnerAuditTrailList);