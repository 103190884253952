import { FaUserCircle } from 'react-icons/fa';
import { BsPlus } from 'react-icons/bs';
import cx from 'classnames';
import styles from './ChannelBasicInformation.module.css';
import Button from 'components/Buttons/Button';
import Section, { SectionRow } from 'components/Section/Section';
import SelectField from 'components/Inputs/SelectField/SelectField';
import TextField from 'components/Inputs/TextField/TextField';
import RadioGroup, {
	RadioOption,
} from 'components/Inputs/RadioGroup/RadioGroup';
import {
	BusinessGroup,
	ChannelTransactionType,
	ChannelTypePhysical,
	ChannelTypeDigital,
	StoreType,
	StoreFormat,
	BusinessType,
} from 'utils/lookup';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { PrimaryInformationFormData } from '../../PrimaryInformation/PrimaryInformation';
import SelectDatepicker, {
	DateValue,
} from 'components/Inputs/Datepicker/SelectDatepicker';
import Grid from 'components/Grid/Grid';
import { useEffect, useState } from 'react';
import { useToggle } from 'utils/hooks';
import {
	addThousandsSeparator,
	isANumber,
} from 'components/Format/NumberFormatter';

import UploadLogoModal from '../../../../UploadLogoModal/UploadLogoModal';
import { UploadLogoResponse } from 'components/UploadLogoModal/UploadLogoResponse';
import ChannelList from '../../../../ChannelList/ChannelList';
import { connect } from 'react-redux';
type LogoFieldProps = {
	onClick: () => void;
	onSuccess: (data: UploadLogoResponse) => void;
	image?: string;
	disabled?: boolean;
};

const inCFSType: RadioOption[] = [
	{
		label: 'Local',
		value: 'LOCAL',
	},
	{
		label: 'International',
		value: 'INTERNATIONAL',
	},
];
export type BasicInformationFields = {
	logo?: {
		objectKey: string;
		url: string;
		filename?: string;
	};

	channelId?: string;
	tin?: string;
	inCfs?: string;
	channelTransactionType?: string;
	businessType?: string;
	channelName?: string;
};

const LogoField: React.FC<LogoFieldProps> = ({
	onSuccess,
	onClick,
	image,
	disabled,
}) => {
	const {
		value: isUploadLogoModalOpen,
		valueOn: showUploadLogoModal,
		valueOff: hideUploadLogoModal,
	} = useToggle();

	return (
		<div className={styles.logoFieldContainer}>
			<div className={cx(styles.avatar, { [styles.hasImage]: !!image })}>
				{image ? (
					<img src={image} className={styles.image} alt="biller-logo" />
				) : (
					<FaUserCircle />
				)}
				{disabled ? (
					<Button className={styles.uploadBtn}>
						<BsPlus />
					</Button>
				) : (
					<Button className={styles.uploadBtn} onClick={showUploadLogoModal}>
						<BsPlus />
					</Button>
				)}
			</div>
			<div className={styles.logoLabelContainer}>
				<div className={styles.logoLabel}>Upload Logo</div>
				<div className={styles.logoSubtext}>
					<em>*Maximum file size accepted:</em>
					<span className={styles.logoSubtextValue}>10MB</span>
				</div>
				<div className={styles.logoSubtext}>
					<em>*File types accepted:</em>
					<span className={styles.logoSubtextValue}>.jpg, .jpeg, .png</span>
				</div>
			</div>
			<UploadLogoModal
				open={isUploadLogoModalOpen}
				onClose={hideUploadLogoModal}
				onSuccess={onSuccess}
				url="/v1/channels/logo"
			/>
		</div>
	);
};

type Props = {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	showOtherIndustry?: boolean;
	showOtherLegalStructure?: boolean;
	disabled?: boolean;
};

function useToggleOthersField(
	value: any,
	predicate: (v: any) => boolean,
	callback?: () => void
) {
	const {
		value: isOthersShown,
		valueOn: showOtherIndustry,
		valueOff: hideOtherIndustry,
	} = useToggle();

	useEffect(() => {
		if (predicate(value)) {
			showOtherIndustry();
		} else {
			hideOtherIndustry();
			callback && callback();
		}
	}, [value]);

	return isOthersShown;
}

const BasicInformation: React.FC<Props> = ({ control, setValue, disabled }) => {
	const inCfs = useWatch({
		control,
		name: `basicInformation.inCfs`,
	});

	const channelTransactionType = useWatch({
		control,
		name: `basicInformation.channelTransactionType`,
	});
	const channelType = useWatch({
		control,
		name: `basicInformation.channelType`,
	});

	const businessType = useWatch({
		control,
		name: `basicInformation.businessType`,
	});

	const isOtherBusinessType =
		businessType &&
		!BusinessType.find(
			(type) => type === businessType && 'others' !== businessType.toLowerCase()
		);

	const channelTypeOptions =
		channelTransactionType && channelTransactionType.toLowerCase() === 'digital'
			? ChannelTypeDigital.map((i) => ({ label: i, value: i }))
			: ChannelTypePhysical.map((i) => ({ label: i, value: i }));

	const isBusinessTypeShown =
		channelType && channelType.toLowerCase() === 'partner';

	const isOtherBusinessTypeShown =
		businessType &&
		(businessType.toLowerCase() === 'other' || isOtherBusinessType);

	const isShowThirdSection = isBusinessTypeShown;

	const [logo, setLogo] = useState('');

	const handleUploadSuccess = (data: UploadLogoResponse) => {
		const { objectKey, url, file } = data;
		setValue('basicInformation.logo', {
			objectKey,
			url,
			filename: file?.name,
		});

		setLogo(URL.createObjectURL(data.file));
	};

	const TIN = () => (
		<Grid column size={1} of={3}>
			<TextField
				label="TIN"
				disabled={disabled}
				required
				control={control}
				name="basicInformation.tin"
			/>
		</Grid>
	);

	return (
		<Section title="Channel Basic Information">
			<SectionRow verticalAlign="end">
				<Grid column size={1} of={3}>
					<LogoField
						onSuccess={handleUploadSuccess}
						image={logo}
						onClick={() => console.log('Upload Clicked')}
						disabled={disabled}
					/>
				</Grid>
				<Grid container column verticalAlign="start" size={2} of={3}>
					<TextField
						label="Channel Name"
						disabled={disabled}
						required
						control={control}
						name="basicInformation.channelName"
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<RadioGroup
						label="Is Customer Frontline Solutions (CFS)?"
						disabled={disabled}
						options={[
							{
								label: 'Yes',
								value: '1',
							},
							{
								label: 'No',
								value: '0',
							},
						]}
						name="basicInformation.inCfs"
						control={control}
						value={`${inCfs}`}
						required
						isVertical={true}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<TextField
						label="TPA Account ID"
						disabled={true}
						control={control}
						name="basicInformation.tpaAccountId"
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label="Business Group"
						disabled={disabled}
						control={control}
						required
						placeholder="Select Business Group"
						name="basicInformation.businessGroup"
						options={BusinessGroup.map((i) => ({ label: i, value: i }))}
					/>
				</Grid>
			</SectionRow>
			<SectionRow>
				<Grid column size={1} of={3}>
					<SelectField
						label="Channel Transaction Type"
						disabled={disabled}
						control={control}
						required
						placeholder="Select Channel Transaction Type"
						name="basicInformation.channelTransactionType"
						options={ChannelTransactionType.map((i) => ({
							label: i,
							value: i,
						}))}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label="Channel Type"
						disabled={disabled || !channelTransactionType}
						control={control}
						required
						placeholder="Select Channel Type"
						name="basicInformation.channelType"
						options={channelTypeOptions}
					/>
				</Grid>

				{isBusinessTypeShown ? (
					<Grid column size={1} of={3}>
						<SelectField
							label="Business Type"
							disabled={disabled}
							control={control}
							required
							placeholder="Select Business Type"
							name="basicInformation.businessType"
							defaultValue={
								disabled && isOtherBusinessType ? 'Other' : businessType
							}
							options={BusinessType.map((i) => ({ label: i, value: i }))}
						/>
					</Grid>
				) : (
					<TIN />
				)}
			</SectionRow>
			{isShowThirdSection && (
				<SectionRow>
					{isOtherBusinessTypeShown && (
						<Grid column size={1} of={3}>
							<TextField
								label="Other Business Type"
								disabled={disabled}
								required
								control={control}
								defaultValue={disabled && isOtherBusinessType && businessType}
								name="basicInformation.otherBusinessType"
							/>
						</Grid>
					)}

					<Grid column size={1} of={3}>
						<TextField
							label="TIN"
							disabled={disabled}
							required
							control={control}
							name="basicInformation.tin"
						/>
					</Grid>
				</SectionRow>
			)}
		</Section>
	);
};

export default BasicInformation;
