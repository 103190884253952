import Table from 'components/CWSTable/Table';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
	TransactionFilterForm,
	transactionColumnConfig,
	ViewTransactionDetails as ViewTransactionDetailsModal,
	VoidTransactionModal,
} from '../../components';
import {
	filterActionCreator,
	filterActionCreatorCount,
	searchBillersActionCreator,
	handlePagination,
	changeShowAll,
	billersPaginationActionCreator,
	handleSortBy,
} from '../../redux/modules/transaction';
import { viewActionCreator as handleView } from '../../redux/modules/viewtransaction';
import {
	viewActionCreator as handleVoidTransaction,
	openLoader,
} from '../../redux/modules/voidTransaction';

const CREATE_VOID_SCOPE = 'bip.create.void.request';

class TransactionList extends Component {
	constructor() {
		super();
		this.handleSort = this.handleSort.bind(this);
	}

	componentDidMount() {
		this.props.searchBillersActionCreator('');
		handlePagination(
			{ pageIndex: 1 },
			this.props.filterActionCreator,
			this.props.filterActionCreatorCount
		);
	}

	handleSort(sortBy, sort) {
		handleSortBy(
			{ column: sortBy, sort: sort },
			this.props.filterActionCreator,
			this.props.filterActionCreatorCount
		);
	}

	render() {
		const createVoidScope = this.props.userInfo?.scopes?.find(
			({ scope }) => scope === CREATE_VOID_SCOPE
		);
		return (
			<>
				<ViewTransactionDetailsModal />
				<VoidTransactionModal />
				<TransactionFilterForm />
				<Table
					data={this.props.data}
					controlledPageCount={this.props.pageCount}
					columns={transactionColumnConfig({
						canVoid: !!createVoidScope,
						handleView: this.props.handleView,
						handleVoid: (v) => {
							this.props.openLoader();
							this.props.handleVoidTransaction(v);
						},
					})}
					count={this.props.count}
					pageSize={this.props.limit}
					onPageChange={(pageIndex) => {
						handlePagination(
							{ pageIndex },
							this.props.filterActionCreator,
							this.props.filterActionCreatorCount
						);
					}}
					onSort={this.handleSort}
					sortBy={this.props.sorting.sort}
					sort={this.props.sorting.sort_by}
					page={this.props.page}
					preHeader={null}
					modalIsOpen={false}
				/>
			</>
		);
	}
}

export default connect(
	(state) => ({
		data: state.transaction.data,
		pageCount: state.transaction.pagination.pageCount,
		count: state.transaction.count,
		page: state.transaction.pagination.page,
		userInfo: state.userInfo,
		sorting: state.transaction.sorting,
	}),
	{
		filterActionCreator,
		filterActionCreatorCount,
		handleView,
		handleVoidTransaction,
		openLoader,
		searchBillersActionCreator,
		changeShowAll,
		billersPaginationActionCreator,
	}
)(TransactionList);
