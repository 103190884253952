import {
	Control,
	useForm,
	UseFormClearErrors,
	UseFormSetValue,
} from 'react-hook-form';
import styles from './PrimaryInformation.module.css';
import BasicInformation, {
	BasicInformationFields,
} from '../sections/ChannelBasicInformation/ChannelBasicInformation';
import ChannelAddress, {
	ChannelAddressFields,
} from '../sections/ChannelAddress/ChannelAddress';

import ChannelContactDetails, {
	ChannelContactDetailsFields,
} from '../sections/ChannelContactDetails/ChannelContactDetails';
import AccountOfficer, {
	AccountOfficerFields,
} from '../../../BillerForm/Tabs/PrimaryInformation/sections/AccountOfficer/AccountOfficer';
import { yupResolver } from '@hookform/resolvers/yup';
import primaryInformationSchema from 'utils/formSchemas/channelForm/primaryInformation';
import { update as updateAC } from 'redux/modules/form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ReducerStateType } from 'redux/modules/reducers';
import { submitted as submittedAC } from 'redux/modules/form';

export type PrimaryInformationFormData = {
	basicInformation?: BasicInformationFields;
	channelAddresses?: ChannelAddressFields;
	channelContactDetails: ChannelContactDetailsFields;
	accountOfficer?: AccountOfficerFields;
};

export type PrimaryInformationSectionProps = {
	setValue: UseFormSetValue<any>;
	clearErrors: UseFormClearErrors<any>;
	initialValues: PrimaryInformationFormData;
	control: Control<any>;
};

type Props = {
	initialValues: PrimaryInformationFormData;
	disabled?: boolean;
	onSubmit?: (values: any) => void;
	onChange: (values: any) => void;
	tabStatus?: any;
};

const PrimaryInformation: React.FC<Props> = ({
	initialValues,
	disabled,
	onSubmit,
}) => {
	const {
		control,
		setValue,
		clearErrors,
		formState: { isValid, isDirty, isSubmitting, isSubmitSuccessful },
		handleSubmit,
		reset,
	} = useForm<PrimaryInformationFormData>({
		mode: 'onChange',
		defaultValues: initialValues,
		resolver: yupResolver(primaryInformationSchema),
	});

	const dispatch = useDispatch();
	const submitting = useSelector<ReducerStateType>(
		(state) => state.form.status.submitting
	);

	useEffect(() => {
		if (submitting) {
			handleSubmit(async (values) => {
				onSubmit && (await onSubmit(values));
			})();
			dispatch(submittedAC());
		}
	}, [dispatch, handleSubmit, onSubmit, submitting]);

	useEffect(() => {}, [isSubmitting, isSubmitSuccessful]);

	return (
		<div className={styles.container}>
			<BasicInformation
				control={control}
				setValue={setValue}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<ChannelAddress
				control={control}
				setValue={setValue}
				clearErrors={clearErrors}
				maxEntries={20}
				initialValues={initialValues}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<ChannelContactDetails
				control={control}
				setValue={setValue}
				clearErrors={clearErrors}
				maxEntries={20}
				initialValues={initialValues}
				disabled={disabled}
			/>
			<hr className={styles.divider} />
			<AccountOfficer control={control} disabled={disabled} />
		</div>
	);
};

export default PrimaryInformation;
