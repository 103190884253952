import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styles from './LookupField.module.css';
import {
	Icon,
	Tooltip,
} from '@salesforce/design-system-react/module/components';
import { SelectOption } from '../SelectField/SelectField';
import cx from 'classnames';
import Label from '../Label/Label';
import { FixMeLater } from 'types';
import { Control, Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';

import textFieldStyles from '../TextField/TextField.module.css';
import successModalStyles from '../../Modal/SuccessModal.module.css';
import errorModalStyles from '../../Modal/ErrorModal.module.css';

const style = {
	...textFieldStyles,
	...successModalStyles,
	...errorModalStyles,
};

const renderMenuList =
	({ disabled, hasSeeMore, seeMoreClick }) =>
	(props) => {
		return (
			!disabled && (
				<components.MenuList {...props}>
					{props.children}
					{hasSeeMore && (
						<div className={styles.seeMore}>
							<a onClick={seeMoreClick}>See All Results</a>
						</div>
					)}
				</components.MenuList>
			)
		);
	};

const DropdownIndicator =
	({ isSearchable }) =>
	(props) => {
		return (
			<components.DropdownIndicator {...props}>
				<Icon
					category="utility"
					name={isSearchable ? 'search' : ''}
					size="xx-small"
					className={styles.dropdownIcon}
				/>
			</components.DropdownIndicator>
		);
	};

type Props = {
	onChange?: (e: Event) => void;
	onInputChange?: (e: Event) => void;
	onFocus?: (e: Event) => void;
	onBlur?: (e: Event) => void;
	onMenuOpen?: (e: Event) => void;
	filterOption?: (e: Event) => boolean;
	inputValue?: string;
	placeholder: string;
	hasSeeMore?: boolean;
	isLoading?: boolean;
	seeMoreClick?: () => void;
	isAsync?: boolean;
	options: SelectOption[];
	isSearchable?: boolean;
	loadOptions?: (input) => void;
	defaultOptions?: any[];
	openMenuOnFocus?: boolean;
	openMenuOnClick?: boolean;
	noOptionsMessage?: any;
	optionalStyle?: any;
	hasError?: boolean;
	value?: FixMeLater;
	disabled?: boolean;
	required?: boolean;
	label?: string;
	control?: any;
	name?: string;
	onSelect?: (e: Event) => void;
	subLabel?: string;
	shouldUnregister?: boolean;
	classNames: any;
};

export default React.forwardRef(
	({
		control,
		hasSeeMore,
		seeMoreClick,
		isAsync = false,
		hasError = false,
		onSelect,
		value = {},
		shouldUnregister = false,
		classNames,
		...rest
	}: Props & { control?: Control }) => {
		const [hasErrorMessage, setHasErrorMessage] = useState(false);

		const setErrorMessage = (error: any): void => {
			if (!isEmpty(error?.message)) {
				setHasErrorMessage(true);
			} else {
				setHasErrorMessage(false);
			}
		};

		const SelectComp = isAsync ? AsyncSelect : Select;
		const { onBlur } = rest;
		const parentComponent = (field?: any, error?: any) => {
			return (
				<>
					<div
						className={hasError || hasErrorMessage ? styles.manualError : ''}
					>
						{rest.label ? (
							<Label required={rest.required} subLabel={rest.subLabel}>
								{rest.label}
							</Label>
						) : null}
						<SelectComp
							label={rest.label}
							required={rest.required}
							disabled={rest.disabled}
							control={control}
							name={rest.name}
							onBlur={(e) => {
								onBlur?.(e);
								field.onBlur();
							}}
							components={{
								DropdownIndicator: DropdownIndicator({
									isSearchable: rest.isSearchable,
								}),
								MenuList: renderMenuList({
									disabled: rest.disabled,
									hasSeeMore,
									seeMoreClick,
								}),
							}}
							className={cx(styles.lookupField, classNames)}
							classNamePrefix="lookupPrefix"
							onChange={(v) => {
								field.onChange(v);
								onSelect && onSelect?.(v);
							}}
							value={
								typeof field?.value === 'string' && field?.value != ''
									? { label: field.value, value: field.value }
									: field?.value ?? {}
							}
							{...rest}
						/>
						{hasErrorMessage && (
							<div
								className={cx({
									'slds-has-error': !!error?.message,
								})}
							>
								<div className={cx(style.helper, 'slds-form-element__help')}>
									{error?.message}
								</div>
							</div>
						)}
					</div>
				</>
			);
		};

		if (control && rest.name) {
			return (
				<Controller
					control={control}
					name={rest.name}
					shouldUnregister={shouldUnregister}
					render={({ field, fieldState: { error } }) => {
						setErrorMessage(error);
						return parentComponent(field, error);
					}}
				/>
			);
		}
		return parentComponent();
	}
);
