import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/common';
const initialState = {
	data: {
		details: null,
		logs: [],
	},
	selectedId: 0,
	showModal: false,
	walletData: [],
};

const viewWalletDetails = createSlice({
	name: 'viewWalletDetails',
	initialState,
	reducers: {
		prep: (state, payload) => {
			state.selectedId = payload.payload;
		},

		load: (state, payload) => {
			state.walletData = payload;
			state.walletData = {
				...payload.result.data,
				date_created: formatDate(payload.result.data.date_created),
				date_updated: formatDate(payload.result.data.date_updated),
			};
			state.data.logs = payload.result.data.audit_logs;
			state.showModal = true;
		},
		updateWalletData: (state, payload) => {
			state.walletData = payload;
			state.showModal = true;
		},

		error: () => {
			console.log('Error');
		},
		closeModal: (state) => {
			state.showModal = false;
		},
		openModal: (state) => {
			state.showModal = true;
		},
	},
});

const { prep, load, error, updateWalletData, closeModal, openModal } =
	viewWalletDetails.actions;

function viewWalletDataActionCreator(payload) {
	return {
		types: [prep.type, load.type, error.type],
		promise: (client, state) => {
			return client.get(`/v1/wallets/${state.viewWalletDetails.selectedId}`);
		},
		payload,
	};
}

export default viewWalletDetails.reducer;

export { viewWalletDataActionCreator, closeModal, openModal, updateWalletData };
