import moment from 'moment';
import { IconSettings, Button } from '@salesforce/design-system-react';
import cx from 'classnames';

import styles from './WalletAdjustment.module.css';
import Table from 'components/CWSTable/Table';
import { DataTableProps } from 'types';
import {
	CashFlowServiceTypes,
	WalletAdjTypeObj,
	WalletTypeObj,
} from 'utils/lookup';
import { capitalize } from 'utils/common';
import { TextFieldFormatter } from 'components/Inputs/TextField/TextFieldFormatter';
import { useSingleSelection, useToggle } from 'utils/hooks';
import ConfirmModal from 'components/Modal/ConfirmModal';
import NumberFormatter from 'components/Format/NumberFormatter';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { useDispatch, useSelector } from 'react-redux';
import { showAccessDeniedModal } from 'redux/modules/access';
import { ReducerStateType } from 'redux/modules/reducers';
import { ExpandedSection } from './ExpandedSection';

const formatDate = (date) => moment.unix(date).format('MM/DD/YYYY hh:mm:ss A');

interface ICell {
	value: any;
	cell?: any;
}

const Cell: React.FC<{ value: string | number }> = ({ value }: ICell) => {
	if (value && value === 'MOTHER WALLET') {
		return (
			<div
				style={{
					fontWeight: value === 'MOTHER WALLET' ? 'bold' : 'normal',
				}}
				className={styles.cellBreak}
			>
				{value.toUpperCase()}
			</div>
		);
	}
	return <div className={styles.cellBreak}>{value}</div>;
};
type RowSelection = {
	amount: number;
	partnerName: string;
	transactionType: string;
	id: string;
};

const WALLET_ADJUSTMENT_COLUMNS = (
	showActions = true,
	shouldAllowApproval: Props['shouldAllowApproval'],
	onApprove: (row: RowSelection) => void,
	onDecline: (row: RowSelection) => void
) => [
	{
		Header: '',
		id: 'expand_button',
		width: '3%',
		Cell: ({
			cell: {
				row: { original },
			},
		}: ICell) => {
			return (
				<div className={styles.button}>
					<ExpandedSection
						data={original}
						onApprove={onApprove}
						onDecline={onDecline}
						shouldAllowApproval={shouldAllowApproval}
					/>
				</div>
			);
		},
	},
	{
		Header: 'Date Created',
		accessor: ({ date_created }) => formatDate(date_created),
		id: 'date_created',
		width: '10%',
		sortable: true,
	},
	{
		Header: 'Wallet ID',
		id: 'wallet_id',
		width: '13%',
		Cell,
	},
	{
		Header: 'Wallet Type',
		id: 'wallet_type',
		width: '12%',
		Cell: ({ value }) => {
			return Cell({ value: WalletTypeObj[value] || '-' });
		},
	},
	{
		Header: 'Partner Name',
		id: 'partner_name',
		width: '10%',
		sortable: true,
		Cell,
	},
	{
		Header: 'Request No.',
		id: 'request_number',
		width: '12%',
	},
	{
		Header: 'Transaction Type',
		id: 'service_type',
		width: '14%',
		accessor: ({ service_type, transaction_type = null }) =>
			CashFlowServiceTypes.find(
				({ value }) => value === (service_type || transaction_type)
			)?.label || '',
	},
	{
		Header: 'Transaction Reference No.',
		id: 'transaction_reference_number',
		width: '15%',
		Cell: ({ value }) => Cell({ value: value || '-' }),
	},
	{
		Header: 'Amount',
		id: 'amount',
		numeric: true,
		Cell: ({ value }) =>
			Cell({ value: TextFieldFormatter.amountFormat(value || 0) }),
	},
	{
		Header: 'Created By',
		id: 'created_by',
		width: '8%',
	},
	{
		Header: 'Date Updated',
		accessor: ({ date_updated }) => formatDate(date_updated),
		id: 'date_updated',
		width: '10%',
		sortable: true,
	},
	{
		Header: 'Updated By',
		id: 'updated_by',
		width: '8%',
	},
	{
		Header: 'Status',
		sortable: true,
		accessor: ({ status }) =>
			STATUS.find(({ name }) => name.toLowerCase() === status.toLowerCase()),
		Cell: ({ cell, value }) => {
			if (!value) return null;
			const className = value ? value.className : '';

			return (
				<div {...cell.getCellProps()} className={className}>
					{value.name}
				</div>
			);
		},
		id: 'status',
		width: '8%',
	},
];

const STATUS = [
	{
		name: 'Pending',
		code: 'PENDING',
		className: styles.statusPending,
	},
	{
		name: 'Approved',
		code: 'APPROVED',
		className: styles.statusApproved,
	},
	{
		name: 'Declined',
		code: 'DECLINED',
		className: styles.statusDeclined,
	},
];

type Props = DataTableProps & {
	hiddenColumns: string[];
	shouldAllowApproval: (status: string, amount: number) => boolean;
	onApprove: (id: string) => Promise<void>;
	onDecline: (id: string) => Promise<void>;
};

const APPROVE_WALLET_ADJUSTMENT_PERMISSION = {
	scope: 'bip.wallet.approval.adjustment',
	resource: '/v1/wallet-adjustments/{walletAdjustmentId}/approve',
};

const WalletAdjustmentTable: React.FC<Props> = ({
	data,
	pageSize,
	count,
	onPageChange,
	onSort,
	sortBy,
	sort,
	page,
	hiddenColumns,
	shouldAllowApproval,
	onApprove,
	onDecline,
}) => {
	const {
		value: isConfirmModalShown,
		valueOn: showConfirmModal,
		valueOff: hideConfirmModal,
	} = useToggle();

	const {
		value: selectedRow,
		setValue: selectRow,
		clearValue: clearSelectedRow,
	} = useSingleSelection<
		RowSelection & { confirmBtnLabel: string; action: 'approve' | 'decline' }
	>();

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const dispatch = useDispatch();

	const onApproveAttempt = (row: RowSelection) => {
		if (!verifyPermission(scopes, APPROVE_WALLET_ADJUSTMENT_PERMISSION)) {
			dispatch(showAccessDeniedModal());
		} else {
			selectRow({ ...row, action: 'approve', confirmBtnLabel: 'Approve' });
			showConfirmModal();
		}
	};
	const onDeclineAttempt = (row: RowSelection) => {
		selectRow({ ...row, action: 'decline', confirmBtnLabel: 'Decline' });
		showConfirmModal();
	};

	const handleConfirm = async () => {
		hideConfirmModal();
		if (!selectedRow) return;

		if (selectedRow.action === 'approve') {
			await onApprove(selectedRow.id);
			return;
		}
		await onDecline(selectedRow.id);
	};

	return (
		<>
			<Table
				columns={WALLET_ADJUSTMENT_COLUMNS(
					!hiddenColumns.includes('actions'),
					data,
					onApproveAttempt,
					onDeclineAttempt
				)}
				data={data}
				count={count}
				pageSize={pageSize}
				onPageChange={onPageChange}
				onSort={onSort}
				sort={sort}
				sortBy={sortBy}
				page={page}
				tableBodyProps={{
					row: {
						label: 'wallet_type',
						value: 'mother',
						css: 'motherRow',
					},
				}}
				preHeader={null}
				modalIsOpen={false}
				isNotEvenRowColor={undefined}
			/>
			{selectedRow && (
				<ConfirmModal
					open={isConfirmModalShown}
					disableClose={false}
					onClose={() => {
						clearSelectedRow();
						hideConfirmModal();
					}}
					headerText={`${capitalize(selectedRow.action)} Wallet Adjustment`}
					bodyText={[
						() => (
							<div className={styles.approveModalConfirmBody}>
								<div>
									Are you sure you want to {selectedRow.action}{' '}
									<strong>
										<NumberFormatter
											value={selectedRow.amount}
											hasThousandsSeparator
											decimalPlaces={2}
										/>
									</strong>
								</div>
								<div>
									<strong>
										{WalletAdjTypeObj[selectedRow.transactionType] ||
											'Wallet Adjustment'}
									</strong>{' '}
									for <strong>{selectedRow.partner_name}</strong>?
								</div>
							</div>
						),
					]}
					confirmButton={{
						name: selectedRow.confirmBtnLabel,
						event: handleConfirm,
					}}
					cancelButton={{
						name: 'Cancel',
						event: hideConfirmModal,
					}}
				/>
			)}
		</>
	);
};

export default WalletAdjustmentTable;
