import moment from 'moment';
import { IconSettings, Button } from '@salesforce/design-system-react';
import cx from 'classnames';

import styles from './VoidList.module.css';
import Table from '../../components/CWSTable/Table';

const formatDate = (date: string) =>
	moment(date).format('MM/DD/YYYY hh:mm:ss A');

const billerTypeMap = {
	RTP: '-',
};

interface ICell {
	value: any;
	cell?: any;
}

const VOID_REQUEST_LIST_COLUMNS = [
	{
		Header: 'Request Date and time',
		accessor: ({ created_at }: { created_at: string }) =>
			formatDate(created_at),
		id: 'created_at',
		width: '21%',
		sortable: true,
	},
	{
		Header: 'Transaction Reference No.',
		id: 'transaction_reference_number',
		width: '18%',
		Cell: ({ value }: ICell) => <div className={styles.voidId}>{value}</div>,
	},
	{
		Header: 'Created by',
		id: 'requestor_name',
		width: '12%',
		Cell: ({ value }: ICell) => <div className={styles.voidId}>{value}</div>,
	},
	{
		Header: 'Branch',
		id: 'branch',
		width: '15%',
		Cell: ({ value }: ICell) => <div className={styles.voidId}>{value}</div>,
	},
	{
		Header: 'Biller',
		id: 'biller_name',
		Cell: ({ value }: ICell) => <div className={styles.voidId}>{value}</div>,
	},
	{
		Header: 'Biller Type',
		id: 'biller_type',
		Cell: ({ value }: ICell) => <div className={styles.voidId}>{value}</div>,
	},
	{
		Header: 'Void Reference ID',
		id: 'reference_id',
		width: '20%',
		Cell: ({ value }: ICell) => <div className={styles.voidId}>{value}</div>,
	},
	{
		Header: 'For within the day cancellation',
		id: 'for_cancelation_within_day',
		width: '10%',
		accessor: ({ biller_type, for_cancelation_within_day }: any) =>
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			billerTypeMap[biller_type] || for_cancelation_within_day,
		Cell: ({ value }: ICell) => (
			<div className={styles.voidId}>
				{value === 0 ? 'No' : value === 1 ? 'Yes' : value}
			</div>
		),
	},
	{
		Header: 'Days Lapsed',
		id: 'days_lapsed',
		width: '10%',
		sortable: true,
		Cell: ({ value }: ICell) => <div className={styles.voidId}>{value}</div>,
	},
	{
		Header: 'Void Request Status',
		width: '15%',
		Cell: ({ cell, value }: ICell) => {
			const match = STATUS.find(
				({ code }) => code.toLowerCase() === value.toLowerCase()
			);
			const className = match ? match.className : '';

			return (
				<div {...cell.getCellProps()} className={className}>
					{match ? match.name : value}
				</div>
			);
		},
		id: 'status',
	},
	{
		Header: () => <div className={styles.actionHeader}>Actions</div>,
		id: 'actions',
		accessor: (values: any) => values,
		Cell: ({ value }: ICell) => {
			return (
				<>
					<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
						<div className={cx(styles.actionCell, 'slds-grid')}>
							<div className="slds-col slds-grid slds-grid_align-center slds-grid_vertical-align-center">
								<Button
									assistiveText={{ icon: 'Icon Bare Small' }}
									className={styles.crossIcon}
									iconCategory="utility"
									iconName="picklist_type"
									iconSize="small"
									iconVariant="bare"
									iconClassName={cx(styles.iconBtn)}
									onClick={() => {}}
									variant="icon"
								/>
							</div>
						</div>
					</IconSettings>
				</>
			);
		},
	},
];

const STATUS = [
	{
		name: 'Approved',
		code: 'APPROVED',
		className: styles.statusApproved,
	},
	{
		name: 'Rejected',
		code: 'REJECTED',
		className: styles.statusRejected,
	},
	{
		name: 'Pending',
		code: 'PENDING',
		className: styles.statusPending,
	},
	{
		name: 'Expired',
		code: 'EXPIRED',
		className: styles.statusExpired,
	},
];

interface IVoidListTable {
	data: any;
	pageSize: number;
	count: number;
	onPageChange: any;
	onSort: any;
	sortBy: string;
	sort: any;
	page: number;
}

const VoidListTable: React.FC<IVoidListTable> = ({
	data,
	pageSize,
	count,
	onPageChange,
	onSort,
	sortBy,
	sort,
	page,
}) => {
	return (
		<Table
			columns={VOID_REQUEST_LIST_COLUMNS}
			data={data}
			count={count}
			pageSize={pageSize}
			onPageChange={onPageChange}
			onSort={onSort}
			sort={sort}
			sortBy={sortBy}
			page={page}
			preHeader={null}
			modalIsOpen={false}
		/>
	);
};

export default VoidListTable;
