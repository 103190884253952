import { useMemo, useCallback } from 'react';
import {
	Control,
	useWatch,
	UseFormSetValue,
	UseFormGetValues,
	UseFormClearErrors,
} from 'react-hook-form';

import Grid from 'components/Grid/Grid';

import TimeInput from 'components/Inputs/TimeField/TimeField';
import { DaysOptions, Frequency, MonthOptions } from 'utils/lookup';
import SelectField from 'components/Inputs/SelectField/SelectField';
import styles from './ReportGenerationSchedule.module.css';
import cx from 'classnames';
import { SectionRow } from 'components/Section/Section';

type Props = {
	isEdit: boolean;
	control: Control<any>;
	data: any;
	name: any;
	getValues: UseFormGetValues<any>;
	setValue: UseFormSetValue<any>;
	trigger: any;
	clearErrors: UseFormClearErrors<any>;
};

type FrequencyTypeOption = 'DAILY' | 'MONTHLY' | 'YEARLY';
const frequencyTypes: Array<{
	label: string;
	value: FrequencyTypeOption;
}> = [
	{
		label: 'Daily',
		value: 'DAILY',
	},
	{
		label: 'Monthly',
		value: 'MONTHLY',
	},
	{
		label: 'Yearly',
		value: 'YEARLY',
	},
];

const ReportGenerationSchedule: React.FC<Props> = ({
	isEdit,
	control,
	getValues,
	setValue,
	trigger,
	clearErrors,
}) => {
	const name = 'reportSchedule.reportScheduleTime';
	const Row: React.FC<{ gutters?: boolean }> = ({ gutters, children }) => (
		<Grid container gutters={gutters} className={styles.vspaced}>
			{children}
		</Grid>
	);

	const scheduleFrequency = useWatch({
		control,
		name: `reportSchedule.frequency`,
	});
	const iwatchFrequencType = useWatch({
		control,
		name: `reportSchedule.frequencyType`,
	});
	const iWatchReportSchedule = useWatch({
		control,
		name: `reportSchedule`,
	});

	const fields = useMemo(() => {
		if (!scheduleFrequency || scheduleFrequency > 10) return 0;
		else if (scheduleFrequency < 0) return 0;
		return +scheduleFrequency;
	}, [scheduleFrequency]);

	const handleFrequencyTypeChange = useCallback(() => {
		const currentFrequency = scheduleFrequency;
		const reportSchedTime = getValues('reportSchedule.reportScheduleTime');

		let updatedReportScheduleTime = [...reportSchedTime];

		if (iwatchFrequencType === 'DAILY') {
			updatedReportScheduleTime = updatedReportScheduleTime.map((item) => ({
				...item,
				dayOfMonth: null,
				monthOfYear: null,
			}));
		} else if (iwatchFrequencType === 'MONTHLY') {
			updatedReportScheduleTime = updatedReportScheduleTime.map((item) => ({
				...item,
				monthOfYear: null,
			}));
		}

		if (currentFrequency !== reportSchedTime?.length) {
			updatedReportScheduleTime = updatedReportScheduleTime.slice(
				0,
				currentFrequency
			);
		}

		setValue('reportSchedule.reportScheduleTime', updatedReportScheduleTime);
		trigger('reportSchedule.reportScheduleTime');
	}, [scheduleFrequency, iwatchFrequencType, iWatchReportSchedule]);

	const handleFrequencyChange = () => {
		const currentFrequency = parseInt(getValues('reportSchedule.frequency'));
		const reportSchedTime = getValues('reportSchedule.reportScheduleTime');

		if (currentFrequency > reportSchedTime.length) {
			clearErrors('reportSchedule.reportScheduleTime');
			const nullArray = new Array(
				currentFrequency - reportSchedTime.length
			).fill(null);
			const nullItems = nullArray.map(() => ({
				time: null,
				dayOfMonth: null,
				monthOfYear: null,
			}));

			reportSchedTime.push(...nullItems);
		}

		const updatedReportScheduleTime = reportSchedTime.slice(
			0,
			currentFrequency
		);

		setValue('reportSchedule.reportScheduleTime', updatedReportScheduleTime, {
			shouldDirty: true,
		});
		trigger('reportSchedule.reportScheduleTime');
	};

	return (
		<>
			<SectionRow verticalAlign="start">
				<Grid column size={1} of={3}>
					<SelectField
						label="Frequency Type"
						disabled={!isEdit}
						control={control}
						required
						placeholder="Frequency Type"
						name="reportSchedule.frequencyType"
						options={frequencyTypes}
						onChange={handleFrequencyTypeChange}
					/>
				</Grid>
				<Grid column size={1} of={3}>
					<SelectField
						label="Frequency"
						name="reportSchedule.frequency"
						control={control}
						placeholder="Select Frequency"
						options={Frequency}
						disabled={!isEdit}
						required
						onChange={handleFrequencyChange}
					/>
				</Grid>
			</SectionRow>

			{Array(fields)
				.fill(0)
				.map((field, index) => (
					<>
						<div className={cx(styles.dateTimeBorder)}>
							<SectionRow verticalAlign="start">
								{iwatchFrequencType === 'MONTHLY' && (
									<Grid column size={1} of={3}>
										<SelectField
											label={`Day(${index + 1}/${fields})`}
											disabled={!isEdit}
											control={control}
											required
											placeholder="Day"
											name={`${name}[${index}].dayOfMonth`}
											options={DaysOptions}
										/>
									</Grid>
								)}

								{iwatchFrequencType === 'YEARLY' && (
									<>
										<Grid column size={1} of={3}>
											<SelectField
												label={`Month(${index + 1}/${fields})`}
												disabled={!isEdit}
												control={control}
												required
												placeholder="Month"
												name={`${name}[${index}].monthOfYear`}
												options={MonthOptions}
											/>
										</Grid>
										<Grid column size={1} of={3}>
											<SelectField
												label={`Day(${index + 1}/${fields})`}
												disabled={!isEdit}
												control={control}
												required
												placeholder="Day"
												name={`${name}[${index}].dayOfMonth`}
												options={DaysOptions}
											/>
										</Grid>
									</>
								)}

								<Grid column size={1} of={3}>
									<TimeInput
										required
										label={`Time(${index + 1}/${fields})`}
										control={control}
										disabled={!isEdit}
										name={`${name}[${index}].time`}
									/>
								</Grid>
							</SectionRow>
						</div>
					</>
				))}
		</>
	);
};

export default ReportGenerationSchedule;
