import {
	Modal,
	TabsPanel,
	Tabs,
	Textarea,
} from '@salesforce/design-system-react/module/components';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from './ReportDetails.module.css';
import OutlineButton from 'components/Buttons/OutlineButton';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useToggle } from 'utils/hooks';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { ReactComponent as EditIcon } from 'assets/icons/ic-edit.svg';
import Grid from 'components/Grid/Grid';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { ReducerStateType } from 'redux/modules/reducers';
import { EDIT_CHANNEL_WALLET_PERMISSION } from 'utils/permissions';
import { showAccessDeniedModal } from 'redux/modules/access';
import TextField from 'components/Inputs/TextField/TextField';
import {
	Control,
	FormProvider,
	UseFormSetValue,
	useForm,
	useFormState,
	useWatch,
} from 'react-hook-form';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import ReportForm from './ReportForm/ReportForm';
import ConfirmModal from 'components/Modal/ConfirmModal';
import ResponseModal from 'components/Modal/ResponseModal';
import successIcon from 'assets/icons/ic-success.svg';
import HTTP from 'helpers/ApiClient';
import failedIcon from '../../../assets/icons/ic-failed.svg';
import {
	remarksValidationSchema,
	report_maintenance,
	report_maintenance_schema,
} from './models/product_report_maintenance';
import React from 'react';
import { formatDate, restrictSpecialChars, timeFormat12Hr } from 'utils/common';
import SuccessModal, {
	SuccessModalActions,
	SuccessModalBody,
	SuccessText,
} from 'components/Modal/SuccessModal';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { FixMeLater, TPartnerModalConfirmProps } from 'types';
import AuditTrail from './AuditTrail/AuditTrail';
import { tabIndexNames } from 'components/BillerForm/BillerForm';
import { AxiosError } from 'axios';
type Props = {
	open?: boolean;
	onClose?: () => void;
	disabled?: boolean;
	action?: string;
	contentClassName?: string;
	state?: any;
	control?: Control<any>;
	setValue?: UseFormSetValue<any>;
	controlValues?: any;
	setHasUpdatedData: (value: boolean) => void;
	tabName: string;
	hasEditPermission: boolean;
	hasAuditTrailListPermission: boolean;
	hasAuditTrailLogsPermission: boolean;
	reportId: any;
	hasViewDetailsPermission: boolean;
};
export const EDIT_REPORT_PERMISSION = {
	scope: 'bip.report.edit.report-details',
	resource: '/reports/report-details/{report_detail_id}',
};

const getAuditTrails = async function (
	reportid: number | string,
	tabName: string
) {
	const result = await HTTP.get(
		`/v2/report-maintenance/${tabName.toLowerCase()}/report-details/${reportid}/audit-trails`
	);
	return result.data;
};

const getReportDetails = async function (
	reportid: number | string,
	tabName: string
) {
	const result = await HTTP.get(
		`/v2/report-maintenance/${tabName.toLowerCase()}/report-details/${reportid}`
	);
	return result.data;
};
const ReportDetails: React.FC<Props> = ({
	disabled = false,
	contentClassName = '',

	onClose = () => {},
	setHasUpdatedData,
	hasEditPermission,
	hasAuditTrailListPermission,
	hasAuditTrailLogsPermission,
	hasViewDetailsPermission,
	tabName,
	reportId,
}) => {
	const dispatch = useDispatch();
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<ReactNode>('');
	const [isDisabled, setIsDisabled] = useState(disabled);
	const [retryBtnOnClick, setRetryBtnOnClick] = useState({ action: () => {} });
	const [errorStatusRemarks, setErrorStatusRemarks] = useState({
		hasError: false,
		status: '',
	});
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
	const [auditTrailData, setAuditTrailData] = useState([]);
	const [data, setData] = useState([]);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [doneBtnOnClick, setDoneBtnOnClick] = useState({ action: () => {} });
	const [errorHeader, setErrorHeader] = useState<ReactNode>('');
	const [errorInstruction, setErrorInstruction] = useState<ReactNode>('');
	const [successMessage, setSuccessMessage] = useState<ReactNode>('');

	const [confirmationHeader, setConfirmationHeader] = useState<
		ReactNode | string
	>('');
	const [confirmBtnLabel, setConfirmBtnLabel] = useState('');
	const [closeBtnLabel, setCloseBtnLabel] = useState('');
	const [confirmationMessage, setConfirmationMessage] = useState<ReactNode>('');
	const [confirmBtnOnClick, setConfirmBtnOnClick] = useState({
		action: () => {},
	});
	const [onConfirmationClose, setOnConfirmationClose] = useState({
		action: () => {},
	});
	const [formData, setFormData] = useState<any>({});
	const dateTimeCreated = formatDate(formData?.createdAt);
	const updatedAt = formatDate(formData?.updatedAt);
	const showLoadingMessage = (message: string) => {
		setLoadingMessage(message);
		showLoading();
	};
	const {
		value: isSuccessModalOpen,
		valueOn: showSuccessModal,
		valueOff: hideSuccessModal,
	} = useToggle();

	const {
		value: isConfirmationModalOpen,
		valueOn: showConfirmationModal,
		valueOff: hideConfirmationModal,
	} = useToggle();
	const {
		value: isModalOpen,
		valueOn: showModal,
		valueOff: hideModal,
	} = useToggle();
	const {
		value: isExitConfirmationModalOpen,
		valueOn: showExitConfirmationModal,
		valueOff: hideExitConfirmationModal,
	} = useToggle();
	const {
		value: isErrorModalOpen,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const showConfirmationMessage = (
		header: any,
		message: any,
		onConfirmBtnClick?: () => void,
		onClose?: () => void,
		confirmBtnLabel?: string,
		closeBtnLabel?: string
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		setConfirmBtnLabel(confirmBtnLabel || 'Confirm');
		setCloseBtnLabel(closeBtnLabel || 'Cancel');
		showModal();
		showConfirmationModal();
		setConfirmBtnOnClick({
			action: () => {
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				remarksForm.reset();
				hideConfirmationModal();
				onClose && onClose();
			},
		});
	};

	const showExitConfirmationMessage = (
		header: any,
		message: any,
		onConfirmBtnClick?: () => void,
		onClose?: () => void,
		confirmBtnLabel?: string,
		closeBtnLabel?: string
	) => {
		setConfirmationHeader(header);
		setConfirmationMessage(message);
		setConfirmBtnLabel(confirmBtnLabel || 'Exit');
		setCloseBtnLabel(closeBtnLabel || 'Cancel');
		showExitConfirmationModal();
		setConfirmBtnOnClick({
			action: () => {
				onConfirmBtnClick && onConfirmBtnClick();
			},
		});
		setOnConfirmationClose({
			action: () => {
				hideExitConfirmationModal();
				onClose && onClose();
			},
		});
	};

	const showSuccessMessage = useCallback(
		(message: any, onDoneBtnClick?: () => void) => {
			setSuccessMessage(message);
			showSuccessModal();
			setDoneBtnOnClick({
				action: () => {
					hideSuccessModal();
					onDoneBtnClick && onDoneBtnClick();
				},
			});
		},
		[hideSuccessModal, showSuccessModal]
	);

	const showErrorMessage = useCallback(
		(
			header: any,
			message: any,
			instruction?: any,
			onRetryBtnClick?: () => void
		) => {
			setErrorHeader(header);
			setErrorMessage(message);
			setErrorInstruction(instruction || 'Please try again.');
			showErrorModal();
			setRetryBtnOnClick({
				action: () => {
					hideErrorModal();
					onRetryBtnClick && onRetryBtnClick();
				},
			});
		},
		[hideErrorModal, showErrorModal]
	);
	useEffect(() => {
		setFormData(data);
	}, [data]);

	const defaultValues = {
		...formData,
	};

	const reportDetailsForm = useForm<ReportsFormData>({
		defaultValues,
		mode: 'all',
		resolver: yupResolver(report_maintenance_schema),
	});
	type ReportsFormData = any;
	const {
		clearErrors,
		getValues,
		setValue,
		control,
		formState: { isDirty, isValid, isValidating, errors },
		handleSubmit,
		reset,
		resetField,
		setError,
		trigger,
	} = reportDetailsForm;

	const remarksForm = useForm({
		mode: 'all',
		reValidateMode: 'onChange',
		resolver: yupResolver(remarksValidationSchema),
	});

	useEffect(() => {
		remarksForm.setValue('remarks', '');
	}, [remarksForm]);

	const isRemarksValid = remarksForm.formState.isValid;
	const [isEmailValid, setIsEmailValid] = useState(true);

	const handleIsValidChange = (newIsValid) => {
		setIsEmailValid(newIsValid);
	};
	const validateRemarks = async () => {
		try {
			await remarksForm.trigger();
			return true;
		} catch {
			return false;
		}
	};
	const dirtyFields = useWatch({
		control: remarksForm.control,
		name: 'remarks',
		defaultValue: false,
	});

	useEffect(() => {
		if (dirtyFields.remarks) {
			validateRemarks();
		}
	}, [dirtyFields.remarks]);

	//revalidation of Report Schedule Timestamp to remove duplication error message if timestamp is valid
	useEffect(() => {
		debounce(() => {
			const errorFieldsString = JSON.stringify(errors);
			if (!isValidating)
				if (
					Object.keys(errors).length > 0 &&
					errorFieldsString.includes('reportSchedule') &&
					isValid
				) {
					showLoadingMessage(
						'Please wait while revalidating report timestamp.'
					);

					setTimeout(() => {
						const schedules = getValues().reportSchedule.reportScheduleTime;
						const schedulesDuplicate = schedules;

						const checkDuplicate = schedules?.some(
							({ time, dayOfMonth, monthOfYear }, index) =>
								schedulesDuplicate.indexOf({
									time,
									dayOfMonth,
									monthOfYear,
								}) != index
						);

						if (checkDuplicate) {
							clearErrors('reportSchedule');
						}
						hideLoading();
					}, 500);
				}
		}, 500)();
	}, [isValidating]);

	const values = useWatch({
		control,
	});

	const reportDetails = {
		id: reportId,
		reportName: values.reportName,
		fileNameFormat: values.fileNameFormat,
		fileType: values.fileType,
		modeOfTransmission: values.modeOfTransmission,
		emailRecipients: values.emailRecipients,
		businessReportCategory: values.businessReportCategory,
	};

	const handleSave = (values) => {
		showLoadingMessage('Please wait while Report is being updated.');
		const report_detail_id = reportId;
		const remarks = remarksForm.getValues('remarks');
		HTTP.put(
			`/v2/report-maintenance/${tabName.toLowerCase()}/report-details/${report_detail_id}`,
			{
				reportDetails: {
					...reportDetails,
					remarks,
					reportSchedule: reportDetailsForm.getValues('reportSchedule'),
				},
			}
		)
			.then((res) => {
				hideLoading();
				hideConfirmationModal();
				showSuccessMessage(
					' The changes you made were successfully saved!',
					() => {
						onClose && onClose();
						setHasUpdatedData(true);
					}
				);

				if (res.status === 424) {
					showErrorMessage(
						'Failed!',
						'A problem occurred while saving your changes.',
						null,
						() => {
							handleSave(values);
						}
					);
					showSuccessMessage(successMessage, () => {
						onClose && onClose();
					});
				}
			})
			.catch((e: any) => {
				console.log(e);

				hideLoading();
				hideConfirmationModal();

				if (e.isAxiosError) {
					if (e.response?.status === 403) {
						dispatch(showAccessDeniedModal());
					} else if (e.message === 'Network Error') {
						showErrorMessage(
							'Timeout Error!',
							'A problem occurred while saving your changes.',
							null,
							() => {
								handleSave(values);
							}
						);
					} else {
						showErrorMessage(
							'Timeout Error!',
							<>A problem occurred while saving your changes. </>,
							null,
							() => {
								handleSave(values);
							}
						);
					}
				}

				if (e.includes('403')) {
					dispatch(showAccessDeniedModal());
					return;
				}

				showErrorMessage(
					'Timeout Error!',
					<>A problem occurred while saving your changes. </>,
					null,
					() => {
						handleSave(values);
					}
				);
			});
	};
	const fetchAuditTrailList = async () => {
		const doRequest = async () => {
			try {
				showLoadingMessage('Please wait while audit trails are being loaded.');
				const result = await getAuditTrails(reportId, tabName);
				setAuditTrailData(result.data);
			} catch (e: any) {
				console.log(e);
				if (typeof e === 'object') {
					if (e.includes('403')) {
						dispatch(showAccessDeniedModal());
						return;
					}
				}
				showErrorMessage(
					'Timeout Error!',
					<>A problem occurred while loading the Audit Trail data.</>,
					null,
					() => {
						fetchAuditTrailList();
					}
				);
				throw e;
			} finally {
				hideLoading();
			}
		};

		await doRequest();
	};
	const fetchReportDetails = async () => {
		const doRequest = async () => {
			try {
				showLoadingMessage(
					'Please wait while report details are being loaded.'
				);
				const result = await getReportDetails(reportId, tabName);
				setData(result.data[0]);
				hideLoading();
				showModal();
			} catch (e: any) {
				hideLoading();
				console.log(e);
				if (e.isAxiosError) {
					if (e.response?.status === 403) {
						dispatch(showAccessDeniedModal());
					} else if (e.message === 'Network Error') {
						showErrorMessage(
							'Timeout Error!',
							'A problem occurred while loading the Report Details data.',
							null,
							() => {
								fetchReportDetails();
							}
						);
					} else {
						showErrorMessage(
							'Timeout Error!',
							'A problem occurred while loading the Report Details data.',
							null,
							() => {
								fetchReportDetails();
							}
						);
					}
				}
				throw e;
			}
		};

		await doRequest();
	};

	useEffect(() => {
		if (selectedTabIndex === 0) {
			if (!hasViewDetailsPermission) {
				dispatch(showAccessDeniedModal());
				setSelectedTabIndex(0);
				return;
			} else if (hasViewDetailsPermission) {
				fetchReportDetails();
			}
		}
	}, [selectedTabIndex]);
	const [response, setResponse] = useState({
		isOpen: false,
		icon: successIcon,
		bodyText: '',
		bodyHeader: 'The changes you made were successfully saved!',
		respondButton: {
			name: 'Done',
			event: () => {},
		},
		respondButton2: {
			name: '',
			event: () => {},
		},
		isRespondButton2Shown: false,
	});

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	useEffect(() => {
		if (!isModalOpen) setIsEdit(false);
	}, [isModalOpen]);

	useEffect(() => {
		if (
			selectedTabIndex === 1 &&
			(!hasAuditTrailListPermission || !hasAuditTrailLogsPermission)
		) {
			dispatch(showAccessDeniedModal());
			setSelectedTabIndex(0);
			return;
		} else if (
			selectedTabIndex === 1 &&
			(hasAuditTrailListPermission || hasAuditTrailLogsPermission)
		) {
			fetchAuditTrailList();
		}
	}, [selectedTabIndex]);

	const Row: React.FC<{ gutters?: boolean }> = ({ gutters, children }) => (
		<Grid container gutters={gutters} className={styles.vspaced}>
			{children}
		</Grid>
	);

	const handleClose = () => {
		if (isEdit && isModalOpen) {
			showExitConfirmationMessage(
				<h2 className={styles.approveHeading}>Leave Page</h2>,
				<>
					<div className={styles.exitConfirmationBody}>
						Are you sure you want to exit? Changes you made will not be saved
					</div>
				</>,
				() => {
					onClose && onClose();
				},
				() => {},
				'Cancel',
				'Exit'
			);
			return;
		}

		onClose && onClose();
		reset();
	};

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const handleShowEditModal = () => {
		!isEdit && setIsEdit(true);
	};

	const editReportConfirmModal = (values) => {
		showConfirmationMessage(
			<h2 className={styles.approveHeading}>Save Changes</h2>,
			<div className={styles.updateReport}>
				<div className={styles.editReportBody1}>
					Are you sure you want to save the changes made?
				</div>
				<div className={styles.editReportBody2}>
					<div className="slds-form-element__control">
						<Row gutters>
							<Grid column size={1} of={1}>
								<TextField
									control={remarksForm.control}
									name="remarks"
									label="Remarks"
									remarks
									required
								></TextField>
							</Grid>
						</Row>
						<Row gutters>
							<Grid column size={1} of={1}>
								<em className={styles.updateStatusSubLabel}>
									*Maximum of 1000 characters only.
								</em>
							</Grid>
						</Row>
					</div>
				</div>
			</div>,
			() => {
				handleSave(values);
			},
			() => {
				setErrorStatusRemarks({ hasError: false, status: '' });
			}
		);
	};

	return (
		<>
			<FullPageLoader open={isLoading} message={loadingMessage} />
			{isModalOpen && (
				<Modal
					isOpen={isModalOpen}
					disable={isLoading}
					onRequestClose={handleClose}
					headerClassName={styles.headerContainer}
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
					size="medium"
					footer={
						isEdit && (
							<div className={styles.footer}>
								<OutlineButton onClick={handleClose} className={styles.btn}>
									Cancel
								</OutlineButton>

								<PrimaryButton
									onClick={async () => {
										editReportConfirmModal(values);
									}}
									className={styles.btn}
									disabled={!isValid || !isEmailValid}
								>
									Save
								</PrimaryButton>
							</div>
						)
					}
					heading={
						<div>
							<div className={styles.titleLeftPanel}>
								<div className={styles.titleTextContainer}>
									<h1 className={styles.headerH1}>{`${
										isEdit ? 'Edit' : 'View'
									} Report Details`}</h1>
								</div>
								<div className={styles.detailsTextContainer}>
									<br />
									<br />
									<Grid container vertical>
										<Row>
											<Grid column size={1} of={1}>
												<h5 className={styles.header}>
													Date and Time Created:{' '}
													<div className={styles.headerInfo}>
														{dateTimeCreated}
													</div>
												</h5>
											</Grid>
										</Row>
										<Row>
											<Grid column size={1} of={1}>
												<h5 className={styles.header}>
													Updated at:{' '}
													<div className={styles.headerInfo}>{updatedAt}</div>
												</h5>
											</Grid>
										</Row>
										<Row>
											<Grid column size={1} of={1}>
												<h5 className={styles.header}>
													Updated by:{' '}
													<div className={styles.headerInfo}>
														{formData?.lastUpdatedBy}
													</div>
												</h5>
											</Grid>
										</Row>
									</Grid>
								</div>
							</div>
							{!isDisabled && (
								<div className={styles.titleRightPanel}>
									<div className={styles.titleActionBtnContainer}>
										<OutlineButton
											onClick={() => {
												if (!hasEditPermission) {
													dispatch(showAccessDeniedModal());
													return;
												}
												handleShowEditModal();
												setIsDisabled(true);
											}}
											fullWidth
											className={styles.btn}
										>
											<EditIcon className={styles.btnIcon} /> Edit Report
											Details
										</OutlineButton>
									</div>
								</div>
							)}
						</div>
					}
				>
					<div className={styles.body}>
						{!isEdit ? (
							<Tabs
								id="tabs-report-maintenance-details"
								className={styles.tabs}
								defaultSelectedIndex={selectedTabIndex}
								selectedIndex={selectedTabIndex}
								onSelect={(index: number) => {
									setSelectedTabIndex(index);
								}}
							>
								<TabsPanel label={'View Report'} key={0}>
									<FormProvider {...reportDetailsForm}>
										<ReportForm
											error={errors}
											onIsValidChange={handleIsValidChange}
											isValid={isValid}
											getValues={getValues}
											setError={setError}
											clearErrors={clearErrors}
											resetField={resetField}
											data={formData}
											control={control}
											setValue={setValue}
											isEdit={isEdit}
											trigger={trigger}
										/>
									</FormProvider>
								</TabsPanel>
								<TabsPanel
									label={'Audit Trail'}
									key={1}
									disable={
										!hasAuditTrailListPermission || !hasAuditTrailLogsPermission
									}
								>
									<div className={cx(styles.content, contentClassName)}>
										<AuditTrail
											auditTrailData={auditTrailData}
											reportid={reportId}
											tabName={tabName}
											hasAuditTrailLogsPermission={hasAuditTrailLogsPermission}
										/>
									</div>
								</TabsPanel>
							</Tabs>
						) : (
							<div className={cx(styles.content)}>
								<FormProvider {...reportDetailsForm}>
									<ReportForm
										error={errors}
										onIsValidChange={handleIsValidChange}
										isValid={isValid}
										getValues={getValues}
										setError={setError}
										clearErrors={clearErrors}
										resetField={resetField}
										data={data}
										control={control}
										setValue={setValue}
										isEdit={isEdit}
										trigger={trigger}
									/>
								</FormProvider>
							</div>
						)}
					</div>
				</Modal>
			)}
			{isModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmationModalOpen}
					isDisabled={isLoading}
					isPrimaryBtnDisabled={!isRemarksValid}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={() => {
						onConfirmationClose.action();
					}}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
				/>
			)}
			{isModalOpen && (
				<ConfirmationModal
					isOpen={isExitConfirmationModalOpen}
					isDisabled={isLoading}
					heading={confirmationHeader}
					message={confirmationMessage}
					onClose={() => {
						onConfirmationClose.action();
					}}
					onCancelBtnClick={() => {
						onConfirmationClose.action();
					}}
					onConfirmBtnClick={() => {
						confirmBtnOnClick.action();
					}}
					cancelBtnLabel={confirmBtnLabel}
					confirmBtnLabel={closeBtnLabel}
				/>
			)}
			{isModalOpen && (
				<SuccessModal open={isSuccessModalOpen}>
					<SuccessModalBody>
						<SuccessText>
							<div className={styles.successBody}>{successMessage}</div>
						</SuccessText>
					</SuccessModalBody>
					<SuccessModalActions>
						<PrimaryButton
							className={styles.successModalBtn}
							onClick={() => {
								doneBtnOnClick.action();
							}}
						>
							Done
						</PrimaryButton>
					</SuccessModalActions>
				</SuccessModal>
			)}
			{isErrorModalOpen && (
				<ErrorModal open={isErrorModalOpen} onClose={hideErrorModal}>
					<ErrorModalBody>
						<div className={styles.errorHeader}>{errorHeader}</div>
						<div className={styles.errorBody}>{errorMessage}</div>
						<div className={styles.errorFooter}>{errorInstruction}</div>
					</ErrorModalBody>
					<ErrorModalActions>
						<PrimaryButton
							fullWidth
							onClick={() => {
								retryBtnOnClick.action();
							}}
							className={styles.errorModalBtn}
						>
							Retry
						</PrimaryButton>
					</ErrorModalActions>
				</ErrorModal>
			)}
			<ResponseModal {...response} />
		</>
	);
};
export default ReportDetails;
