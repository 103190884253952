import { ExpandableButton } from 'containers/CentralWalletSystem/WalletManagement/WalletReplenishment/ExpandableButton';
import useOpenController from 'containers/CentralWalletSystem/WalletManagement/WalletReplenishment/useOpenController';
import styles from './WalletReplenishment.module.css';
import ExpandedRow from './ExpandedRow';

const ExpandedSection = (props) => {
	const { isOpen, toggle } = useOpenController(false);
	return (
		<>
			<tbody>
				<tr>
					<td>
						<ExpandableButton isOpen={isOpen} toggle={toggle} />
					</td>
				</tr>
			</tbody>
			<br />
			<div>
				{isOpen && (
					<ExpandedRow
						data={props.data}
						onApprove={props.onApprove}
						onDecline={props.onDecline}
						shouldAllowApproval={props.shouldAllowApproval}
					/>
				)}
			</div>
		</>
	);
};

export default ExpandedSection;
