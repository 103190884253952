import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryScrollableList from '../ScrollableList/PrimaryScrollableList/PrimaryScrollableList';
import PrimaryCard from '../Cards/PrimaryCard/PrimaryCard';
import ChannelTypes from '../../constants/channelType';
import {
	getChannels,
	GetChannelsParams,
	selectChannel,
} from 'redux/modules/channelList';
import OutlineButton from 'components/Buttons/OutlineButton';
import { ReducerStateType } from 'redux/modules/reducers';
import { useToggle } from 'utils/hooks';
import styles from './ChannelList.module.css';

type Props = {
	addNewChannelButtonClick: () => void;
};

const DEFAULT_PAGE_SIZE = 25;
const initChannelTube = {
	items: [],
	meta: { total: 0, page: 1, pageSize: DEFAULT_PAGE_SIZE },
};

const ChannelList: React.FC<Props> = ({ addNewChannelButtonClick }) => {
	const [limit] = useState(DEFAULT_PAGE_SIZE);
	const [page, setPage] = useState(1);
	const dispatch = useDispatch();
	const channels = useSelector<ReducerStateType>((state) => {
		const { channels: { channels: items = [], meta = {} } = {} } = state;
		return {
			items,
			meta,
		};
	}) as unknown as {
		items: any[];
		meta: any;
	};

	const load = (params?: GetChannelsParams) => dispatch(getChannels(params));
	const [allChannels, setAllChannels] =
		useState<{ items: any[]; meta: any }>(initChannelTube);

	const {
		value: isLoading,
		valueOn: startLoading,
		valueOff: stopLoading,
	} = useToggle();

	const [channelType, setChannelType] = useState('');
	const [channelName, setChannelName] = useState('');

	const pageParams = { page, limit, channelType, name: channelName };
	const scrollableListProps = useMemo(
		() => ({
			primaryButtonOnClick: addNewChannelButtonClick,
			primaryButtonTitle: 'Add New Channel',
			listTitle: 'Channel List',
			dropdownOptions: ChannelTypes,
			dropdownPlaceholder: 'Channel Type',
			dropdownOnSelect: (value: any) => {
				setAllChannels(initChannelTube);
				setPage(1);
				setChannelType(value);
			},
			dropdownOnClick: () => {
				setAllChannels(initChannelTube);
				setPage(1);
				setChannelType('');
			},
			searchFieldOnChange: (value: any) => {
				setAllChannels(initChannelTube);
				setPage(1);
				setChannelName(value);
			},
			searchPlaceholder: 'Search Channel Name',
		}),
		[page, channelType, channelName]
	);

	useEffect(() => {
		if (isLoading) {
			return;
		}

		setAllChannels({
			...channels,
			items: channels.items.reduce((acc, curr) => {
				const ids = acc.map((a) => a.id);
				if (ids.includes(curr.id)) return acc;
				return acc.concat(curr);
			}, allChannels.items),
		});
	}, [channels.items, isLoading]);

	const loadMore = useCallback(
		async (limit: number = DEFAULT_PAGE_SIZE) => {
			startLoading();
			await load({ ...pageParams, limit, page });
			stopLoading();
		},
		[page, channelType, channelName]
	);

	useEffect(() => {
		loadMore();
	}, [loadMore]);

	const hasMoreChannels = useMemo(() => {
		const { total = 0 } = channels.meta;

		const lastPage = Math.ceil(total / DEFAULT_PAGE_SIZE);
		return page < lastPage;
	}, [channels.meta, page]);

	const handleLoadMore = () => {
		setPage(page + 1);
	};
	return (
		<PrimaryScrollableList {...scrollableListProps}>
			{allChannels.items.map((channel: any, index: number) => (
				<PrimaryCard
					key={index}
					name={channel.name}
					logo={channel.logo}
					status={channel.status}
					isSelected={channel.isSelected}
					onClick={() => {
						selectChannel({ selected: channel.id, cnname: channel.name });
					}}
				/>
			))}
			{hasMoreChannels && (
				<OutlineButton
					disabled={isLoading}
					className={styles.loadMoreBtn}
					onClick={handleLoadMore}
				>
					{isLoading ? 'Loading...' : 'Load More'}
				</OutlineButton>
			)}
		</PrimaryScrollableList>
	);
};

export default ChannelList;
