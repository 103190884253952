import { Modal } from '@salesforce/design-system-react/module/components';
import RenderByType from '../common/RenderByType';
import { ReactComponent as Icon } from 'assets/icons/ic-failed.svg';
import styles from './ErrorModal.module.css';
import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import Grid from 'components/Grid/Grid';
import cx from 'classnames';
import { isEmpty } from 'lodash';

type ErrorModalSectionProps = {
	children: ReactNode;
	className?: any;
};

export const ErrorModalBody: React.FC<ErrorModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};
export const ErrorModalActions: React.FC<ErrorModalSectionProps> = ({
	children,
	...props
}) => {
	return <div {...props}>{children}</div>;
};

const ErrorIcon: React.FC = () => <Icon className={styles.errorIcon} />;

type ErrorModalProps = {
	className?: any;
	open?: boolean;
	onClose?: () => void;
	Icon?: React.FC;
	children: ReactNode;
};

export const ErrorText: React.FC<any> = ({ children }) => (
	<div className={cx(styles.errorModalText)}>{children}</div>
);
export const ErrorSubText: React.FC = ({ children }) => (
	<div className={styles.errorModalSubtext}>{children}</div>
);

const ErrorModal: React.FC<ErrorModalProps> = ({
	open,
	onClose,
	Icon = ErrorIcon,
	children,
	className,
}) => {
	const portalDOM = document.getElementById('portal-denied');
	if (!portalDOM) return <></>;

	return ReactDOM.createPortal(
		<Modal
			ariaHideApp={false}
			isOpen={open}
			onRequestClose={onClose}
			headerClassName={styles.errorModalHeader}
			size="small"
			containerClassName={cx(styles.errorModalContainer, className)}
			contentClassName={styles.errorModal}
		>
			<Grid vertical verticalAlign="center" className={styles.errorModalContent}>
				{Icon && (
					<Grid column className={styles.errorModalIconContainer}>
						<Icon />
					</Grid>
				)}
				<Grid column className={styles.errorModalBody}>
					<RenderByType type={ErrorModalBody}>{children}</RenderByType>
				</Grid>
				<Grid column className={styles.errorModalActions}>
					<RenderByType type={ErrorModalActions}>{children}</RenderByType>
				</Grid>
			</Grid>
		</Modal>,
		portalDOM
	);
};

export default ErrorModal;
