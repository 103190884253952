import Grid from 'components/Grid/Grid';
import React, { ReactNode, useState } from 'react';
import styles from './ChannelDetails.module.css';
import ChannelForm from '../ChannelForm/ChannelForm';
import BillerStatusSelect from '../BillerForm/BillerStatusSelect/BillerStatusSelect';

import client from '../../helpers/ApiClient';
import { connect } from 'react-redux';
import { useEffect } from 'react';

import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import PanelPlaceholder from '../BillerList/BillerTabPanelPlaceholder';
import { SelectOption } from '../Inputs/SelectField/SelectField';
import { useToggle } from '../../utils/hooks';
import _ from 'lodash';
type Props = {
	selectedChannel: any;
};

type BasicInfoFields = {
	merchant_id?: string;
	channelPrimaryInfo?: any;
	channelAddresses?: any;
	channelContactDetails?: any;
	channelAccountOfficer?: any;
	channelName?: any;
	tpaAccountId?: any;
	businessGroup?: any;
	channelTransactionType?: any;
	channelType?: any;
	tin?: string;
};

type LocalBillerAddressFields = {
	location_base?: any;
	country?: SelectOption;
	area?: string;
	region?: string;
	address?: Array<any>;
	address_category?: SelectOption;
	province?: SelectOption;
	city?: SelectOption;
	barangay?: SelectOption;
	category?: SelectOption;
	street?: string;
	building_no?: string;
	zip_code?: string;
};

type BillerContactFields = {
	contact_details?: Array<any>;
};

type AccountOfficerFields = {
	officer_name?: string;
	email?: Array<any>;
	officer_contact_number?: any;
};

const defaultValue: any = {
	primaryInformation: {
		basicInformation: {},
		billerAddress: [
			{
				locationBase: 'LOCAL',
				country: {
					label: 'Philippines',
					value: '1',
				},
			},
		],
		billerContactDetails: [{ name: '', email: [], contactNumber: [] }],
	},
};

const capitalize = (s: any) =>
	(s && s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()) || 'Engaged';

const ChannelDetails: React.FC<Props> = ({ selectedChannel }) => {
	const [channelInfo, setChannelInfo] = useState<
		BasicInfoFields &
			LocalBillerAddressFields &
			BillerContactFields &
			AccountOfficerFields
	>();

	const [billerStatusSTG, setBillerStatusSTG] = useState<any>();
	const [billerStatusPROD, setBillerStatusPROD] = useState<any>();

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const formattedChannelInfo: any = channelInfo
		? {
				primaryInformation: {
					basicInformation: {
						tpaAccountId: channelInfo.channelPrimaryInfo.tpaAccountId,
						channelName: channelInfo.channelName,
						businessGroup: channelInfo.channelPrimaryInfo.businessGroup,
						channelTransactionType:
							channelInfo.channelPrimaryInfo.transactionType,
						channelType: channelInfo.channelPrimaryInfo.channelType,
						tin: channelInfo.channelPrimaryInfo.tin,
						inCfs: channelInfo.channelPrimaryInfo.inCfs ? '1' : '0',
						businessType: channelInfo.channelPrimaryInfo.businessType,
						otherBusinessType: channelInfo.channelPrimaryInfo.otherBusinessType,
					},
					channelAddresses: !_.isEmpty(channelInfo?.channelAddresses)
						? channelInfo.channelAddresses?.map((address: any) => ({
								locationBase: address.locationBase,
								country: address.country,
								category: address.address_category,
								province: address.province,
								city: address.municipality,
								barangay: address.barangay,
								street: address.street,
								buildingNo: address.buildingName,
								zipCode: address.zipCode,
								area: address.area,
								region: address.region,
						  }))
						: defaultValue.primaryInformation.billerAddress,
					channelContactDetails: _.isEmpty(channelInfo?.contact_details)
						? channelInfo.channelContactDetails?.map((contact: any) => ({
								name: contact.name,
								areaHandled: contact.area_handled,
								position: contact.position,
								department: contact.department,
								email:
									contact.email.length > 1
										? contact.email.split('|')
										: contact.email,
								contactNumber:
									contact.contactNumber.length > 1
										? contact.contactNumber.split('|')
										: contact.contactNumber,
						  }))
						: defaultValue.primaryInformation.channelContactDetails,
					accountOfficer: {
						name: channelInfo.channelAccountOfficer.name,
						email:
							channelInfo.channelAccountOfficer.email.length > 1
								? channelInfo.channelAccountOfficer.email.split('|')
								: channelInfo.channelAccountOfficer.email,
						contactNumber:
							channelInfo.channelAccountOfficer.contactNumber.length > 1
								? channelInfo.channelAccountOfficer.contactNumber.split('|')
								: channelInfo.channelAccountOfficer.contactNumber,
					},
				},
		  }
		: defaultValue;

	useEffect(() => {
		if (selectedChannel) {
			showLoading();

			const fetchBillerDetails = async () => {
				try {
					const response = await client.get(
						`/v1/channels/${selectedChannel.id}/primary-info`
					);
					console.log('Channel API', response.data.result);
					setChannelInfo({
						...response.data.result,
						channelName: selectedChannel.name,
					});
					await client
						.get(`/v1/passthru/biller/${selectedChannel.id}/status`)
						.then(({ data }) => {
							// console.log(
							// 	data,
							// 	`BILLER STAT STG /v1/passthru/biller/${selectedChannel.id}/status`
							// );
							setBillerStatusSTG(data);
						})
						.catch((e) => {
							setBillerStatusSTG('Engage');
							hideLoading();
						});

					await client
						.get(`/v1/biller/${selectedChannel.id}/status`)
						.then(({ data }) => {
							// console.log(
							// 	data,
							// 	`BILLER STAT PROD /v1/biller/${selectedChannel.id}/status`
							// );
							setBillerStatusPROD(data);
						})
						.catch((e) => {
							// console.log(e, 'ERROR BILLER STAT PROD');
							setBillerStatusPROD('Engage');
							hideLoading();
						});
				} finally {
					hideLoading();
				}
			};

			fetchBillerDetails();
		}
	}, [selectedChannel]);

	return selectedChannel ? (
		<div className={styles.container}>
			<FullPageLoader
				open={isLoading}
				message="Please wait while biller details is being loaded"
			/>
			{selectedChannel && !isLoading && (
				<>
					<div className={styles.title}>
						{selectedChannel ? selectedChannel.name : 'Biller Name'}
					</div>
					<div className={styles.header}>
						<Grid container gutters="xx-small">
							<Grid column size={1} of={6}>
								<BillerStatusSelect
									labelPrefix="In STG"
									value={capitalize(billerStatusSTG?.staging?.biller_status)}
									disabled
								/>
							</Grid>
							<Grid column size={1} of={6}>
								<BillerStatusSelect
									labelPrefix="In Prod"
									value={capitalize(billerStatusPROD?.dev?.biller_status)}
									disabled
								/>
							</Grid>
						</Grid>
					</div>

					<ChannelForm disabled initialValues={formattedChannelInfo} />
				</>
			)}
		</div>
	) : (
		<>
			<PanelPlaceholder />
		</>
	);
};

export default connect(
	(state: any) => ({ selectedChannel: state.channels.selectedChannel }),
	{}
)(ChannelDetails);
