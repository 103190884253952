import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import { Sidebar, Gradient, Header } from '../../components';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter as Router } from 'connected-react-router';
import TransactionList from '../TransactionList/TransactionList';
import VoidList from '../VoidList/VoidList';
import ReportSummary from '../ReportSummary/ReportSummary';
import { IconSettings } from '@salesforce/design-system-react/module/components';
import SessionTimeoutModal from '../SessionHandler/SessionTimeoutModal';
import { toggleModal } from '../../redux/modules/timeout';
import { closeAccessDeniedModal } from 'redux/modules/access';
import ChangePassword from '../../components/Password/ChangePassword';
import CentralWalletSystem from '../CentralWalletSystem';
import BillerManagement from '../BillerManagement';
import ChannelSystem from '../ChannelManagement';
import ProductManagement from '../ProductManagement';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
	ErrorSubText,
	ErrorText,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { Worker } from '@react-pdf-viewer/core';
import styles from './App.module.css';
import BusinessReports from 'containers/BusinessReports';
type AppProps = {
	history: History;
};

type TimeoutStateProps = {
	timeout: {
		showModal: boolean;
	};
};

type AccessDeniedStateProps = {
	access: {
		showDeniedModal: boolean;
	};
};

export default function App({ history }: AppProps) {
	const showModal = useSelector<TimeoutStateProps>(
		(state) => state.timeout.showModal
	);

	const showAccessDeniedModal: any = useSelector<AccessDeniedStateProps>(
		(state) => state.access.showDeniedModal
	);

	const dispatch = useDispatch();

	return (
		<Worker workerUrl="/lib/pdfjs/pdf.worker.min.js">
			<IconSettings iconPath="/lib/lightningdesignsystem/assets/icons">
				{showAccessDeniedModal && (
					<ErrorModal open={showAccessDeniedModal}>
						<ErrorModalBody>
							<ErrorText>Access Denied</ErrorText>
							<ErrorSubText>
								Sorry, you don't have access to this function. Please contact
								your admin to grant access permission. Thank you.
							</ErrorSubText>
						</ErrorModalBody>
						<ErrorModalActions>
							<PrimaryButton
								style={{
									width: '120px',
								}}
								onClick={() => dispatch(closeAccessDeniedModal())}
							>
								Close
							</PrimaryButton>
						</ErrorModalActions>
					</ErrorModal>
				)}

				{showModal && (
					<SessionTimeoutModal toggleModal={() => dispatch(toggleModal())} />
				)}
				<Router history={history}>
					<div className="slds-grid">
						<Gradient />
						<div
							className="slds-col slds-size_2-of-12"
							style={{
								width: '15%',
								minHeight: '100vh',
							}}
						>
							<Sidebar />
						</div>
						<div
							className="slds-col slds-size_10-of-12"
							style={{
								zIndex: 1000,
								padding: '2vh 1.5vw',
								width: '85%',
							}}
						>
							<Header />
							<Switch>
								<Route exact path="/" component={TransactionList} />
								<Route exact path="/report-summary" component={ReportSummary} />
								<Route exact path="/void-list" component={VoidList} />
								<Route
									exact
									path="/change-password"
									component={ChangePassword}
								/>
								<Route path="/wallet-system" component={CentralWalletSystem} />
								<Route path="/biller-management" component={BillerManagement} />
								<Route path="/channel-management" component={ChannelSystem} />
								<Route path="/business-reports" component={BusinessReports} />
								<Route path="/products" component={ProductManagement} />
							</Switch>
						</div>
					</div>
				</Router>
			</IconSettings>
		</Worker>
	);
}
