import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from '@salesforce/design-system-react/module/components';
import cx from 'classnames';
import styles from './Select.module.css';

type Option = {
	label: string;
	value: string;
};
type Props = {
	placeholder?: string;
	options: Option[];
	onChange?: (value: string) => void;
	controlled?: boolean;
	value?: string;
	menuClassName: any;
};

const Select: React.FC<Props> = ({
	placeholder,
	options,
	onChange,
	controlled = false,
	value: controlledValue,
	menuClassName,
}) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [value, setValue] = useState('');
	const [menuStyle, setMenuStyle] = useState<any>({
		minWidth: dropdownRef.current?.clientWidth,
		maxWidth: dropdownRef.current?.clientWidth,
	});

	useEffect(() => {
		if (controlled) {
			const match =
				options && options.find(({ value: v }) => controlledValue === v);
			if (match) {
				setValue(match.label);
			} else {
				setValue('');
			}
		}
	}, [controlledValue]);

	useEffect(() => {
		setMenuStyle({
			minWidth: dropdownRef.current?.clientWidth,
			// maxWidth: dropdownRef.current?.clientWidth,
		});
	}, [dropdownRef?.current, value]);

	useEffect(() => {
		window.addEventListener('resize', () =>
			setMenuStyle({
				minWidth: dropdownRef.current?.clientWidth,
				maxWidth: dropdownRef.current?.clientWidth,
			})
		);
		return () => window.removeEventListener('resize', () => { });
	}, [setMenuStyle]);

	return (
		<div ref={dropdownRef}>
			<Dropdown
				hasStaticAlignment={true}
				align="left"
				iconCategory="utility"
				iconName="down"
				menuPosition="overflowBoundaryElement"
				className={menuClassName}
				iconPosition="right"
				label={value === '' ? placeholder : value}
				triggerClassName={cx(styles.select, { [styles.empty]: !value })}
				options={options.map((o) => ({
					className: cx(styles.selectItem, {
						[styles.selectItemActive]: value === o.label,
					}),
					...o,
				}))}
				menuStyle={menuStyle}
				value={value}
				width="x-small"
				style={{ width: '100%' }}
				onSelect={(selected: Option) => {
					setValue(selected.label);
					if (onChange) {
						onChange(selected.value);
					}
				}}
			/>
		</div>
	);
};

export default Select;
