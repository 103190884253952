import {
	Tabs,
	TabsPanel,
} from '@salesforce/design-system-react/module/components';
import styles from './PrimaryTab.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateType } from 'redux/modules/reducers';
import { useHasUserPermission } from 'utils/permissions';
import { useCallback, useState } from 'react';
import { showAccessDeniedModal } from 'redux/modules/access';

type TabsInfoType = {
	title: string;
	scope?: string;
	component: any;
};

type Props = {
	tabs: TabsInfoType[];
	defaultIndex?: number;
	onSelect?: (index: number) => void;
};

const PrimaryTab: React.FC<Props> = ({ tabs, defaultIndex = 0, onSelect }) => {
	const dispatch = useDispatch();
	const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
	const productCategory = useSelector<ReducerStateType, string>(
		(state) => state.products.productCategory
	);

	const hasUserPermission = useHasUserPermission('products');
	const hasViewPermission = useCallback(
		(scope: string) => {
			switch (scope) {
				case 'audit-trail':
					return hasUserPermission(productCategory, `list.${scope}`);
				default:
					return false;
			}
		},
		[hasUserPermission, productCategory]
	);

	return (
		<div className={'slds-col'}>
			<Tabs
				variant="scoped"
				id="tabs-example-scoped"
				className={styles.tabs}
				defaultSelectedIndex={selectedIndex}
				selectedIndex={selectedIndex}
				onSelect={(index: number) => {
					const scope = tabs[index].scope;
					if (scope && !hasViewPermission(scope)) {
						dispatch(showAccessDeniedModal());
						return;
					}
					onSelect && onSelect(index);
					setSelectedIndex(index);
				}}
			>
				{tabs.map((tab: TabsInfoType, index: number) => (
					<TabsPanel key={index} label={tab.title}>
						{tab.component}
					</TabsPanel>
				))}
			</Tabs>
		</div>
	);
};

export default PrimaryTab;
