import cx from 'classnames';
import styles from './ChannelProfileDH.module.css';
import { useToggle } from '../../../utils/hooks';
import ChannelList from 'components/ChannelList/ChannelList';
import ChannelTab from 'components/ChannelList/ChannelTab';
import ChannelModal from 'components/ChannelModal/ChannelModal';

import { connect } from 'react-redux';
import { clearSelectedChannel } from 'redux/modules/channelList';

type Props = {
	clearSelectedChannel: any;
};

export const initialValues = {
	primaryInformation: {
		basicInformation: {
			channelName: '',
		},
		channelAddresses: [
			{
				locationBase: 'LOCAL',
				country: '1',
				area: '',
				region: '',
			},
		],
		channelContactDetails: [{ name: '', email: [], contactNumber: [] }],
		accountOfficer: { name: '' },
	},
};

const ChannelProfile: React.FC<Props> = ({ clearSelectedChannel }) => {
	const {
		value: isAddChannelProfileModalShowing,
		valueOn: showAddChannelProfileModal,
		valueOff: hideAddChannelProfileModal,
	} = useToggle();

	const showAddChannelProfileModalViewClear = () => {
		showAddChannelProfileModal();
		clearSelectedChannel();
	};

	return (
		<div className={cx('slds-card', styles.paperContainer)}>
			<div className={cx(styles.title)}>Channel Profile</div>
			<div className={cx('slds-grid slds-gutters', styles.mainContainer)}>
				<ChannelList
					addNewChannelButtonClick={showAddChannelProfileModalViewClear}
				/>
				<ChannelTab />
			</div>
			<ChannelModal
				open={isAddChannelProfileModalShowing}
				onClose={hideAddChannelProfileModal}
				initialValues={initialValues}
			/>
		</div>
	);
};

export default connect((state: any) => state, { clearSelectedChannel })(
	ChannelProfile
);
