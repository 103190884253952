import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { categories, reportFiles, reportName } from 'utils/queries/reports';
import _ from 'lodash';

const DEFAULT_PAGINATION = {
	page: 1,
	count: 0,
	offset: 0,
	limit: 25,
};

const DEFAULT_FILTER = {
	file_name: '',
	date_from: null,
	date_to: null,
	file_types: [],
};

const categoryInit = {
	has_report_name_error: false,
	has_report_files_error: false,
	is_report_filtering: false,
	report_name_id: 0,
	report_names: [],
	report_files: [],
	filter: {
		report_name_id: 0,
		file_types: [],
		file_name: '',
		date_from: null,
		date_to: null,
	},
	pagination: {
		page: 1,
		count: 0,
		offset: 0,
		limit: 25,
	},
	sorting: {
		sort_by: 'DESC',
		sort: 'created_at',
	},
};

const initialState = {
	data: [],
	count: 0,
	product: categoryInit,
	channel: categoryInit,
	reconciliation: categoryInit,
	finance: categoryInit,
	accounting: categoryInit,
	rpa: categoryInit,
	other: categoryInit,
	report_category_id: 1,
	loading: false,
	error: false,
	modal_open: false,
	report_file_id: null,
	modal_title: null,
	selectedReportSize: 0,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const convertToOptions = (report_table) => {
	return report_table.map(({ id, reportName, ...extra }) => ({
		value: id,
		label: reportName,
		extra,
	}));
};

const fetchReportNames = createAsyncThunk(
	'reports/fetchReportNames',
	reportName
);

const fetchCategoryTable = createAsyncThunk(
	'reports/fetchCategoryTable',
	reportFiles
);

const reports = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setReportFile: (state, { payload }) => {
			state.report_file_id = payload.id;
			state.selectedReportSize = payload.fileSize;
		},
		setClearStates: (state) => {
			state.report_category_id = 1;

			for (let i = 1; i <= 7; i++) {
				const categoryKey = categories[i];
				state[categoryKey] = categoryInit;
			}
		},
		setClearFilter: (state) => {
			const categoryKey = categories[state.report_category_id];
			state[categoryKey].filter = {
				...state[categoryKey].filter,
				file_name: '',
				file_types: [],
				date_from: undefined,
				date_to: undefined,
			};
		},
		setCategoryId: (state, { payload }) => {
			state.report_category_id = payload;
		},
		setPagination: (state, { payload }) => {
			const categoryName = categories[state.report_category_id];
			state[categoryName].pagination = payload;
		},
	},
	extraReducers: (builder) => {
		// ----------------- REPORT NAMES ----------------- //
		builder
			.addCase(fetchReportNames.pending, (state) => {
				const categoryName = categories[state.report_category_id];
				state[categoryName].has_report_name_error = false;
				state.loading = true;
				state.error = false;
			})
			.addCase(fetchReportNames.fulfilled, (state, { payload }: any) => {
				const categoryName = categories[state.report_category_id];
				state.loading = false;
				state[categoryName].report_names = convertToOptions(payload.data.data);
			})
			.addCase(fetchReportNames.rejected, (state, { payload }) => {
				const categoryName = categories[state.report_category_id];
				const errorStatus = _.get(payload, 'request.status');
				state[categoryName].is_report_filtering = false;
				state.loading = false;

				if (errorStatus !== 403) {
					state.error = true;
					state[categoryName].has_report_name_error = true;
				} else {
					state.error = false;
				}
			});

		// ----------------- REPORT TABLE ----------------- //
		builder
			.addCase(fetchCategoryTable.pending, (state, { meta: { arg } }) => {
				const categoryName = categories[state.report_category_id];
				state[categoryName].is_report_filtering = arg.is_report_filtering;
				state[categoryName].filter = {
					...state[categoryName].filter,
					...arg,
				};
				state[categoryName].pagination = {
					limit: arg.limit,
					offset: arg.offset,
					page: arg.page,
				};
				state[categoryName].has_report_files_error = false;
				state.loading = true;
				state.error = false;
			})
			.addCase(fetchCategoryTable.fulfilled, (state, { payload }: any) => {
				const categoryName = categories[state.report_category_id];
				const payloadData = payload?.data?.data;
				const data = _.get(payloadData, ['reportDetails']);
				const totalCount = _.get(payloadData, ['totalCount']);
				state[categoryName].report_files = data?.map((d) => ({
					...d,
				}));
				state[categoryName].pagination.count = totalCount;
				state[categoryName].is_report_filtering = false;
				state.loading = false;
			})
			.addCase(fetchCategoryTable.rejected, (state, { payload }) => {
				const errorStatus = _.get(payload, 'request.status');
				const categoryName = categories[state.report_category_id];
				state[categoryName].is_report_filtering = false;
				state.loading = false;

				if (errorStatus !== 403) {
					state.error = true;
					state[categoryName].has_report_files_error = true;
				} else {
					state.error = false;
				}
			});
	},
});

export default reports.reducer;

export const {
	setClearFilter,
	setClearStates,
	setCategoryId,
	setPagination,
	setReportFile,
} = reports.actions;

export {
	convertToOptions as converToOptions,
	fetchCategoryTable,
	fetchReportNames,
	DEFAULT_PAGINATION,
	DEFAULT_FILTER,
	categories,
};
