import yup, { defaultInvalidCharacter, hasSpecialCharacter } from '../common';
import { isPositive } from 'components/Format/NumberFormatter';

const walletReplenishmentForm = yup.object().shape({
	walletType: yup
		.string()
		.label('Wallet Type')
		.when('replenishmentType', {
			is: '0',
			then: yup.string().required('Select Wallet Type'),
		}),
	partnerName: yup
		.string()
		.label('Partner Name')
		.when('replenishmentType', {
			is: '0',
			then: yup.string().required('Select Partner Name'),
		}),
	walletId: yup
		.string()
		.label('Wallet ID')
		.when('replenishmentType', {
			is: '0',
			then: yup.string().required('Invalid Wallet ID'),
		}),
	bankName: yup
		.string()
		.label('Bank Name')
		.when('replenishmentType', {
			is: '0',
			then: yup.string().required('Select Bank Name'),
		}),
	bankReferenceNumber: yup
		.string()
		.label('Bank Reference Number')
		.when('replenishmentType', {
			is: '0',
			then: yup
				.string()
				.required()
				.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
		}),
	modeOfPayment: yup
		.string()
		.label('Mode of Payment')
		.when('replenishmentType', {
			is: '0',
			then: yup.string().required('Select Mode of Payment'),
		}),
	amount: yup
		.string()
		.label('Amount')
		.when('replenishmentType', {
			is: '0',
			then: yup
				.string()
				.required('Enter Amount')
				.transform((curr) => curr.replaceAll(',', ''))
				.test(
					'is-positive',
					'Please enter amount greater than P0.00',
					(value) => isPositive(value)
				)
				.test(
					'is-above-max-amount',
					'You can only input a maximum amount of 999,999,999,999,999.99',
					(value) => {
						const length = value
							?.toString()
							.replaceAll(',', '')
							.split('.')[0]?.length;
						return length && length <= 15 ? true : false;
					}
				),
		}),
	replenishmentType: yup.string().default('1').required(),
	replenishRemarks: yup
		.string()
		.label('Remarks')
		.when('replenishmentType', {
			is: '0',
			then: yup
				.string()
				.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
		}),
	replenishmentFile: yup
		.mixed()
		.label('File')
		.when('replenishmentType', {
			is: '1',
			then: yup
				.mixed()
				.required('File is required.')
				.test('type', 'Invalid file type', (value: File) => {
					if (!value) return false;
					const fileExt = value[0]?.name?.split('.').pop();
					const isCSV =
						fileExt === 'csv' &&
						(value[0]?.type === 'application/vnd.ms-excel' ||
							value[0]?.type === 'text/csv');
					return isCSV;
				})
				.test(
					'fileSize',
					'Maximum allowed file size per attachement exceeded. Only 15 mb per attachment is allowed.',
					(value: File) => value && value[0].size < 1024 * 1024 * 16
				)
				.test(
					'countLength',
					'Maximum allowed of users exceeded. Only 200 users are allowed.',
					async (value: File) => {
						if (value) {
							const isExceeded: boolean = await new Promise((resolve, _) => {
								try {
									const reader = new FileReader();
									reader.onload = (e) => {
										if (e?.target?.result) {
											const value: string = e?.target?.result.toString();
											resolve(value.trim().split('\n').length > 200);
										}
									};
									value && reader.readAsText(value[0]);
								} catch {
									console.log("Can't read the file");
									resolve(false);
								}
							});
							return !isExceeded;
						}
						return false;
					}
				),
		}),
});

export default walletReplenishmentForm;
