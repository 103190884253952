import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import styles from './WalletList.module.css';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import cx from 'classnames';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { debounce as _debounce, filter } from 'lodash';
import FilterSelect from '../../Filter/FilterSelect';
import DatePicker, {
	validateDateFrom,
	validateDateTo,
} from '../../Filter/Datepicker';
import ChannelNameFilter from '../../Filter/ChannelNameFilter';
import CreateChannelWallet from 'components/ChannelWallet/CreateChannelWallet';
import { useToggle } from 'utils/hooks';
import { showAccessDeniedModal } from 'redux/modules/access';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { ReducerStateType } from 'redux/modules/reducers';
import DownloadButton from 'components/Buttons/DownloadButton/DownloadButton';
import FileTypeSelectModal from 'components/Modal/FileTypeSelectModal';
import PasswordModal from 'components/Modal/PasswordModal';
import HTTP from 'helpers/ApiClient';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import { filterObjectEmpty } from 'utils/common';
import moment from 'moment';
import { calibrateDateTo } from 'utils/date';

const CREATE_CHANNEL_WALLET_PERMISSION = {
	scope: 'cws.wallet.create',
	resource: '/wallets',
};

const DOWNLOAD_WALLET = {
	scope: 'bip.wallet.download.report',
	resource: '/v1/wallets/download-balance',
};

const shouldDisableSubmission = (values) => {
	const {
		dateFrom,
		dateTo,
		channelName,
		serviceType,
		status,
		walletIdentifier,
		walletType,
		channelLevel,
	} = values;
	return ![
		channelName,
		serviceType,
		status,
		dateFrom,
		dateTo,
		walletIdentifier,
		walletType,
		channelLevel,
	].some((v) => v);
};

export type WalletListFilterFormData = {
	filter: {
		transactionNumber?: string;
		dateTo?: string;
		dateFrom?: string;
		walletId?: string;
		walletType?: string;
		channelLevel?: string;
		channelName?: string;
	};
};

type Props = {
	onSubmit: (values: any, isSearch?: boolean) => void;
	initialValues: Partial<WalletListFilterFormData['filter']>;
	page: number;
	pageSize: number;
	sortBy: string;
	sort: string;
	canCreateChannelWallet?: boolean;
};

const WalletListFilter: React.FC<Props> = ({
	onSubmit,
	initialValues = {},
	page,
	pageSize,
	sortBy,
	sort,
}) => {
	const {
		register,
		getValues,
		setValue,
		clearErrors,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid, isDirty },
		reset,
	} = useForm<WalletListFilterFormData>({
		mode: 'all',
		defaultValues: { filter: initialValues },
	});

	const filter = { ...filterObjectEmpty(getValues('filter')) };

	const filterSort = {
		page,
		pageSize,
		sortBy,
		sort,
		...filter,
		channelName: undefined,
		dateFrom: filter.dateFrom && moment(filter.dateFrom).format('X'),
		dateTo: filter.dateTo && moment(filter.dateTo).format('X'),
		partnerName: filter.channelName && filter.channelName.label,
	};

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const [initialized, setInitialized] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const [password, setPassword] = useState('');

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isModalShown,
		valueOn: showModal,
		valueOff: hideModal,
	} = useToggle();

	const {
		value: isSelectFileTypeModalShown,
		valueOn: showFileModal,
		valueOff: hideFileModal,
	} = useToggle();

	const {
		value: isPasswordModalShown,
		valueOn: showPasswordModal,
		valueOff: hidePasswordModal,
	} = useToggle();

	const {
		value: isDownloadErrorModalShown,
		valueOn: showDownloadErrorModal,
		valueOff: hideDownloadErrorModal,
	} = useToggle();

	const handleFilterSubmit = (values, isSearch = false) => {
		onSubmit(values || {}, isSearch);
	};

	const walletId = watch('filter.walletId');
	const walletType = watch('filter.walletType');
	const allValues = watch('filter');
	const dispatch = useDispatch();

	console.log(walletId, 'walletId');

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues('filter') || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, walletId: value }, true);
			}
		}, 500),
		[isDirty, isValid, walletId]
	);

	const handleReset = () => {
		if (isDirty) {
			reset({ filter: initialValues });
			setValue('filter', initialValues);
			handleFilterSubmit(initialValues);
			setIsReset(true);
		}
	};

	const handleShowCreateModal = () => {
		if (verifyPermission(scopes, CREATE_CHANNEL_WALLET_PERMISSION)) {
			showModal();
		} else {
			dispatch(showAccessDeniedModal());
		}
	};

	useEffect(() => {
		setInitialized(true);
	}, []);
	useEffect(() => {
		if (initialized && !isReset) {
			handleSearch(walletId);
		}
	}, [walletId]);

	useEffect(() => {
		if (isReset) {
			setIsReset(false);
		}
	}, [isReset]);

	const openDownloadModal = () => {
		if (!verifyPermission(scopes, DOWNLOAD_WALLET)) {
			dispatch(showAccessDeniedModal());
		} else {
			showFileModal();
		}
	};

	const handleDownload = (v) => {
		showLoading();
		filterSort.dateTo = calibrateDateTo(filter.dateTo);

		HTTP.get('/v1/wallets/download-balance', {
			params: {
				...filterSort,
				type: v,
			},
		})
			.then((value) => {
				setPassword(value.data.password);
				showPasswordModal();
			})
			.catch((error) => {
				showDownloadErrorModal();
				console.log(error);
			})
			.finally(() => {
				hideLoading();
				hideFileModal();
			});
	};

	return (
		<>
			{isModalShown && (
				<CreateChannelWallet open={isModalShown} onClose={hideModal} />
			)}
			<Filter title="Wallet List">
				<FilterOuter>
					<div className="slds-col">
						<div className="slds-grid slds-grid_align-end slds-gutters">
							<div className="slds-col slds-large-size_3-of-11">
								<PrimaryButton
									type="submit"
									fullWidth
									onClick={handleShowCreateModal}
								>
									Add New Channel Wallet
								</PrimaryButton>
							</div>
							<div className="slds-col slds-size_5-of-12">
								<InputWithIcon
									placeholder="Search Wallet ID"
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
									// {...register('filter.walletId', {
									// 	minLength: 3,
									// })}
									name="filter.walletId"
									control={control}
								/>
							</div>
							<div className="slds-col slds-size_2-of-12">
								<DownloadButton onClick={openDownloadModal} />
							</div>
						</div>
					</div>
				</FilterOuter>
				<FilterInner>
					<div
						className={cx(
							'slds-grid',
							'slds-grid_align-spread',
							'slds-grid_vertical-align-start',
							styles.filters
						)}
					>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<DatePicker
								control={control}
								name="filter.dateFrom"
								label="Date Created From"
								errors={errors}
								validate={(v) =>
									validateDateFrom(v, getValues('filter.dateTo'), () =>
										clearErrors('filter.dateFrom')
									)
								}
							/>
						</div>
						<div
							className={cx(
								styles.datePickerContainer,
								'slds-col',
								'slds-size_2-of-12'
							)}
						>
							<DatePicker
								control={control}
								name="filter.dateTo"
								label="Date Created To"
								errors={errors}
								validate={(v) =>
									validateDateTo(getValues('filter.dateFrom'), v, () =>
										clearErrors('filter.dateTo')
									)
								}
							/>
						</div>
						<div className="slds-col slds-size_3-of-12">
							<FilterSelect
								name="filter.walletType"
								label="Wallet Type"
								control={control}
								options={[
									{
										label: 'Channel Wallet - Prefunded',
										value: 'prefunded',
									},
									{ label: 'Channel Wallet - Bonded', value: 'bonded' },
									{ label: 'Product Wallet', value: 'cws' },
								]}
								menuClassName={styles.walletTypeDropdown}
							/>
						</div>
						<div className="slds-col slds-size_3-of-12">
							<ChannelNameFilter
								name="filter.channelName"
								displayName="Partner Name"
								control={control}
								isSearchable={false}
								walletType={walletType}
								value={allValues.channelName}
							/>
						</div>
						<div className="slds-col slds-size_3-of-12">
							<FilterSelect
								name="filter.channelLevel"
								label="Channel Level"
								control={control}
								options={[
									{
										label: 'TPA Account',
										value: 'TPA_ACCOUNT',
									},
									{
										label: 'Branch',
										value: 'BRANCH',
									},
									{
										label: 'TPA ID',
										value: 'TPA_ID',
									},
								]}
							/>
						</div>
						<div className="slds-col slds-size_3-of-12">
							<FilterSelect
								name="filter.status"
								label="Status"
								control={control}
								options={[
									{ label: 'Active', value: 'ACTIVE' },
									{ label: 'Inactive', value: 'INACTIVE' },
									{
										label: 'Deactivated',
										value: 'DEACTIVATED',
									},
								]}
							/>
						</div>
						<div
							className={cx(
								'slds-col',
								'slds-size_3-of-12',
								'slds-grid',
								'slds-grid_vertical-align-end',
								'slds-grid_align-spread',
								styles.innerButtons
							)}
						>
							<div
								className={cx(
									styles.clearFilterBtnContainer,
									'slds-col',
									'slds-size_4-of-7'
								)}
							>
								<OutlineButton fullWidth onClick={handleReset}>
									Clear All Filters
								</OutlineButton>
							</div>
							<div className="slds-col slds-size_3-of-7">
								<PrimaryButton
									disabled={shouldDisableSubmission(allValues)}
									onClick={handleSubmit(({ filter = {} }) =>
										handleFilterSubmit(filter, true)
									)}
									fullWidth
								>
									Filter
								</PrimaryButton>
							</div>
						</div>
					</div>
				</FilterInner>
			</Filter>
			<FullPageLoader
				open={isLoading}
				message="Please wait while the wallet balance report is being generated"
			/>
			<FileTypeSelectModal
				open={isSelectFileTypeModalShown}
				onClose={hideFileModal}
				handleDownload={handleDownload}
			/>
			<PasswordModal
				password={password}
				open={isPasswordModalShown}
				onClose={hidePasswordModal}
			/>
			<ErrorModal
				open={isDownloadErrorModalShown}
				onClose={hideDownloadErrorModal}
			>
				<ErrorModalBody>
					<div className={styles.errorBody}>
						<div>A problem occurred while</div>
						<div>generating the wallet balance report.</div>
					</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton onClick={hideDownloadErrorModal}>Close</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default WalletListFilter;
