import Filter, { FilterInner, FilterOuter } from 'components/Filter';
import InputWithIcon from 'components/Inputs/InputWithIcon/InputWithIcon';
import styles from './WalletAdjustment.module.css';
import DownloadButton from 'components/Buttons/DownloadButton/DownloadButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { debounce as _debounce } from 'lodash';
import DatePicker, {
	validateDateFrom,
	validateDateTo,
} from '../../Filter/Datepicker';
import ChannelNameFilter from '../../Filter/ChannelNameFilter';
import FilterSelect from 'containers/CentralWalletSystem/Filter/FilterSelect';
import Grid from 'components/Grid/Grid';
import { useToggle } from 'utils/hooks';
import PasswordModal from 'components/Modal/PasswordModal';
import HTTP from 'helpers/ApiClient';
import { useDispatch, useSelector } from 'react-redux';
import verifyPermission, { IScope } from 'utils/verifyPermission';
import { ReducerStateType } from 'redux/modules/reducers';
import { showAccessDeniedModal } from 'redux/modules/access';
import CreateWalletAdjustment from './CreateWalletAdjustment';
import { CashFlowServiceTypes, WalletStatuses } from 'utils/lookup';

const CREATE_CHANNEL_ADJUSTMENT_PERMISSION = {
	scope: 'bip.wallet.create.adjustment',
	resource: '/v1/wallet-adjustments',
};

const DOWNLOAD_WALLET_ADJUSTMENT = {
	scope: 'bip.wallet.download.adjustment',
	resource: '/v1/wallet-adjustments/{walletAdjustmentId}/download',
};

const shouldDisableSubmission = (values) => {
	const { dateFrom, dateTo, partnerName, transactionType, status } = values;
	return ![partnerName, transactionType, status, dateFrom, dateTo].some(
		(v) => v
	);
};

type WalletAdjusmentFilterFormData = {
	filter: {
		requestNumber?: string;
		dateTo?: string;
		dateFrom?: string;
		partnerName?: string | null;
	};
};

type Props = {
	onSubmit: (values: any) => void;
	onHandleSuccess: () => void;
	initialValues: Partial<WalletAdjusmentFilterFormData['filter']>;
};

const WalletAdjustmentFilter: React.FC<Props> = ({
	onSubmit,
	initialValues = { partnerName: null },
	onHandleSuccess,
}) => {
	const {
		register,
		getValues,
		setValue,
		clearErrors,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid, isDirty },
		reset,
	} = useForm<WalletAdjusmentFilterFormData>({
		mode: 'all',
		defaultValues: { filter: initialValues },
	});

	const [initialized, setInitialized] = useState(false);
	const [isReset, setIsReset] = useState(false);

	const handleFilterSubmit = (values) => {
		onSubmit(values || {});
	};

	const requestNumber = watch('filter.requestNumber');
	const allValues = watch('filter');

	const handleSearch = useCallback(
		_debounce((v) => {
			const value = v || '';
			const filters = getValues('filter') || {};
			if (value.length >= 3 || value === '') {
				handleFilterSubmit({ ...filters, requestNumber: value });
			}
		}, 500),
		[isDirty, isValid, requestNumber]
	);

	const handleReset = () => {
		if (isDirty) {
			reset({ filter: initialValues });
			setValue('filter', initialValues);
			setValue('filter.partnerName', '');
			handleFilterSubmit(initialValues);
			setIsReset(true);
		}
	};

	useEffect(() => {
		setInitialized(true);
	}, []);
	useEffect(() => {
		if (initialized && !isReset) {
			handleSearch(requestNumber);
		}
	}, [requestNumber]);

	useEffect(() => {
		if (isReset) {
			setIsReset(false);
		}
	}, [isReset]);

	const {
		value: isOpenCreate,
		valueOn: showCreateModal,
		valueOff: hideCreateModal,
	} = useToggle();

	const scopes = useSelector<ReducerStateType>(
		(state) => state.userInfo?.scopes || []
	) as unknown as IScope[];

	const dispatch = useDispatch();

	const handleShowCreateModal = () => {
		if (verifyPermission(scopes, CREATE_CHANNEL_ADJUSTMENT_PERMISSION)) {
			showCreateModal();
		} else {
			dispatch(showAccessDeniedModal());
		}
	};
	const {
		value: isPasswordModalShown,
		valueOn: showPasswordModal,
		valueOff: hidePasswordModal,
	} = useToggle();
	const [password, setPassword] = useState('');
	const handleDownloadFiles = () => {
		if (verifyPermission(scopes, DOWNLOAD_WALLET_ADJUSTMENT)) {
			HTTP.get('/v2/wallet/adjustment-list/download', {}).then((value) => {
				setPassword(value.data.data.password);
				showPasswordModal();
			});
		} else {
			dispatch(showAccessDeniedModal());
		}
	};
	const WalletAdjustmentList = 'Wallet Adjustment List';
	return (
		<>
			<CreateWalletAdjustment
				open={isOpenCreate}
				handleClose={hideCreateModal}
				handleSuccess={hideCreateModal}
				handleSuccessClose={onHandleSuccess}
			/>
			<Filter title="Wallet Adjustment">
				<FilterOuter>
					<Grid container>
						<Grid column container align="end" gutters="x-small">
							<Grid column size={3} of={11}>
								<PrimaryButton
									onClick={handleShowCreateModal}
									type="submit"
									fullWidth
								>
									Add New Adjustment
								</PrimaryButton>
							</Grid>
							<Grid column size={5} of={12}>
								<InputWithIcon
									placeholder="Search Request No."
									className={styles.searchBar}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
											className: styles.inputIcon,
										},
									]}
									{...register('filter.requestNumber', {
										minLength: 3,
									})}
								/>
							</Grid>
							<Grid column size={2} of={12}>
								<DownloadButton
									className={styles.downloadButton}
									fullWidth
									onClick={handleDownloadFiles}
								/>
							</Grid>
						</Grid>
					</Grid>
				</FilterOuter>
				<FilterInner>
					<Grid
						container
						align="spread"
						verticalAlign="start"
						gutters="x-small"
						className={styles.filters}
					>
						<Grid
							column
							size={2}
							of={12}
							className={styles.datePickerContainer}
						>
							<DatePicker
								control={control}
								name="filter.dateFrom"
								label="Date From"
								errors={errors}
								validate={(v) =>
									validateDateFrom(v, getValues('filter.dateTo'), () =>
										clearErrors('filter.dateFrom')
									)
								}
							/>
						</Grid>
						<Grid
							column
							size={2}
							of={12}
							className={styles.datePickerContainer}
						>
							<DatePicker
								control={control}
								name="filter.dateTo"
								label="Date To"
								errors={errors}
								validate={(v) =>
									validateDateTo(getValues('filter.dateFrom'), v, () =>
										clearErrors('filter.dateTo')
									)
								}
							/>
						</Grid>
						<Grid column size={4} of={12}>
							<ChannelNameFilter
								displayName="Partner Name"
								name="filter.partnerName"
								control={control}
								value={allValues.partnerName}
							/>
						</Grid>
						<Grid
							column
							size={3}
							of={12}
							className={styles.serviceTypeContainer}
						>
							<FilterSelect
								name="filter.transactionType"
								label="Transaction Type"
								control={control}
								options={CashFlowServiceTypes.filter((_, i) => i !== 0)}
							/>
						</Grid>
						<Grid column size={3} of={12}>
							<FilterSelect
								name="filter.status"
								label="Status"
								control={control}
								options={WalletStatuses}
							/>
						</Grid>
						<Grid
							container
							column
							size={4}
							of={12}
							verticalAlign="end"
							align="spread"
							className={styles.innerButtons}
						>
							<Grid
								column
								size={4}
								of={7}
								className={styles.clearFilterBtnContainer}
							>
								<OutlineButton fullWidth onClick={handleReset}>
									Clear All Filters
								</OutlineButton>
							</Grid>
							<Grid column size={3} of={7}>
								<PrimaryButton
									disabled={shouldDisableSubmission(allValues)}
									onClick={handleSubmit(({ filter = {} }) =>
										handleFilterSubmit(filter)
									)}
									fullWidth
								>
									Filter
								</PrimaryButton>
							</Grid>
						</Grid>
					</Grid>
				</FilterInner>
			</Filter>
			<PasswordModal
				password={password}
				open={isPasswordModalShown}
				onClose={hidePasswordModal}
				title={WalletAdjustmentList}
			/>
		</>
	);
};

export default WalletAdjustmentFilter;
