import { Modal } from '@salesforce/design-system-react/module/components';
import PrimaryButton from '../Buttons/PrimaryButton';
import OutlineButton from '../Buttons/OutlineButton';
import styles from './ConfirmModal.module.css';
import cx from 'classnames';
import Grid from 'components/Grid/Grid';
import { useToggle } from 'utils/hooks';
import { ReactNode, useEffect } from 'react';

type Props = {
	open?: boolean;
	headerText: string |ReactNode;
	bodyText: (string | React.FC)[];
	onClose?: () => void;
	disableClose?: boolean;
	cancelButton?: {
		name?: string;
		event?: () => void;
	};
	confirmButton?: {
		name?: string;
		event?: () => void;
	};
	disableOnClick?: boolean;
	isPrimaryBtnDisabled: boolean;
};

const ConfirmModal: React.FC<Props> = ({
	open,
	onClose = () => {},
	headerText,
	bodyText,
	cancelButton = {
		name: 'Cancel',
		event: () => {},
	},
	confirmButton = {
		name: 'Confirm',
		event: () => {},
	},
	disableClose = true,
	disableOnClick = false,
	isPrimaryBtnDisabled,
}) => {
	const {
		value: isDisabled,
		valueOn: disable,
		valueOff: enable,
	} = useToggle(false);

	useEffect(() => {
		enable();
	}, [open]);

	return (
		<Modal
			isOpen={open}
			disableClose={disableClose}
			onRequestClose={onClose}
			headerClassName={styles.headerText}
			size="small"
			containerClassName={styles.modal}
			header={headerText}
			footer={
				<Grid container align="end" gutters="xx-small">
					<Grid column size={1} of={4}>
						<OutlineButton
							onClick={cancelButton?.event ? cancelButton.event : () => {}}
							fullWidth
							className={styles.actionBtn}
							disabled={isDisabled}
						>
							{cancelButton.name}
						</OutlineButton>
					</Grid>
					<Grid column size={1} of={4}>
						<PrimaryButton
							onClick={
								confirmButton.event
									? () => {
											if (disableOnClick) {
												disable();
											}
											if (confirmButton.event) confirmButton.event();
									  }
									: () => {}
							}
							fullWidth
							className={styles.actionBtn}
							disabled={isDisabled || isPrimaryBtnDisabled}
						>
							{confirmButton.name}
						</PrimaryButton>
					</Grid>
				</Grid>
			}
		>
			<div
				className={cx(
					styles.bodyText,
					'slds-p-around_large',
					'slds-text-align--center'
				)}
			>
				{bodyText.map((Value, idx) => {
					return (
						<span key={idx}>
							{typeof Value === 'function' ? <Value /> : Value}
						</span>
					);
				})}
			</div>
		</Modal>
	);
};

export default ConfirmModal;
