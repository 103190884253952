import { RadioOption } from 'components/Inputs/RadioGroup/RadioGroup';
import { SelectOption } from 'components/Inputs/DropdownChecbox/DropdownCheckbox';
export const AddressCategory: string[] = ['Head Office', 'Business Office'];
export const AddressCategoryPartner: RadioOption[] = [
	{ label: 'Head Office', value: 'HEAD' },
	{ label: 'Business Office', value: 'BUSINESS' },
];

export const PartnerCodeType: RadioOption[] = [
	{
		label: 'Auto-generated (default)',
		value: 'AUTO',
	},
	{
		label: 'Existing Partner Code',
		value: 'EXISTING',
	},
];

export const AddressType: RadioOption[] = [
	{
		label: 'Local',
		value: 'LOCAL',
	},
	{
		label: 'International',
		value: 'INTERNATIONAL',
	},
];

export const AddressDefaultValue = {
	location_base: 'LOCAL',
	country_id: '175',
	country_obj: {
		name: 'Philippines',
		id: '175',
		country_code: 'PH',
	},
	address_category: '',
	province_id: '',
	city_id: '',
	barangay_id: '',
	building_no: '',
	street: '',
	zip_code_id: '',
};

export const ContactDefaultValue = {
	city_id: '',
	name: '',
	position: '',
	department: '',
	email: [],
	tel_no: [],
	area_handled: '',
};

export const Industries: string[] = [
	'Airlines',
	'Cable/Internet',
	'Cash-in/Wallets',
	'Charity',
	'Corporate Payments',
	'Credit Cards',
	'Distribution',
	'E-Commerce',
	'Electricity',
	'Government',
	'Hospitality',
	'Insurance',
	'Loans',
	'Memorial',
	'Payment Gateway',
	'Real Estate',
	'School',
	'Telecoms',
	'Toll',
	'Transportation',
	'Water Utility',
	'Others',
];

export const LegalStructure: string[] = [
	'Sole Proprietorship',
	'Partnership',
	'Corporate',
	'Others',
];

export const BusinessGroup: string[] = [
	'Group Accounts - Payment Partner',
	'Group Accounts - Payment Machine',
	'Digital Enterprise',
	'Financial Institutions, Merchants and Strategic Partnership',
	'Regional Operations',
	'Wallets and Mobile App',
	'Payments',
];

export const ChannelTransactionType: string[] = ['Digital', 'Physical'];
export const ChannelTypePhysical: string[] = [
	'Center',
	'Partner',
	'Agent',
	'Machine',
	'Collect',
];
export const ChannelTypeDigital: string[] = ['Online', 'Mobile App'];

export const StoreType: string[] = ['Full Booth ', 'Booth'];
export const StoreFormat: string[] = ['Standalone', 'Mall-based'];

export const BusinessType: string[] = [
	'Bank',
	'Courier Services',
	'Remittance Outlet',
	'Department Store',
	'Supermarket',
	'Pawnshop',
	'Convenience Store',
	'Gas Station',
	'Pharmacy',
	'Payment Center',
	'Insurance Agency',
	'Travel Agency',
	'Sari-Sari Store',
	'Others',
];

export const Area: string[] = ['GMA', 'NOL', 'SOL', 'VIS', 'MIN', 'Nationwide'];

export const BillerType: string[] = ['RTP', 'Non-RTP'];
export const VoidReason: string[] = [
	'Altered Check',
	'Cancelled Payment',
	'Cash encoded as Check',
	'Check encoded as Cash',
	'Post Dated Check',
	'Staled Check',
	'Unsigned Check',
	'Wrong Bank Code',
	'Wrong Check Number',
	'Wrong Amount in Words & Figures',
	'Wrong Amount',
	'Wrong Biller',
	'Wrong Bill Presented',
	'Wrong System Date',
	'Wrong Customer Account / Reference No.',
	'Invalid Account No.',
	'Wrong Payment Type',
	'Already paid by the Customer',
	'Voided/Cancelled by Biller',
	'For Refund/Reversal',
	'Customer Error',
	'Customer Initiated Request',
	'Teller Error',
	'No Payment Received',
	'Erroneous Deposit',
	'Over Deposit',
	'Under Deposit',
	'Double Posting',
	'System Error',
	'No Teller Code',
	'Unique Constraint',
	'Others',
];
export const DaysOptions: SelectOption[] = [
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
	{ label: '6', value: '6' },
	{ label: '7', value: '7' },
	{ label: '8', value: '8' },
	{ label: '9', value: '9' },
	{
		label: '10',
		value: '10',
	},
	{ label: '11', value: '11' },
	{ label: '12', value: '12' },
	{ label: '13', value: '13' },
	{ label: '14', value: '14' },
	{
		label: '15',
		value: '15',
	},
	{ label: '16', value: '16' },
	{ label: '17', value: '17' },
	{
		label: '18',
		value: '18',
	},
	{ label: '19', value: '19' },
	{ label: '20', value: '20' },
	{ label: '21', value: '21' },
	{ label: '22', value: '22' },
	{ label: '23', value: '23' },
	{ label: '24', value: '24' },
	{ label: '25', value: '25' },
	{ label: '26', value: '26' },
	{ label: '27', value: '27' },
	{ label: '28', value: '28' },
	{ label: '29', value: '29' },
	{ label: '30', value: '30' },
	{ label: '31', value: '31' },
];
export const MonthOptions: SelectOption[] = [
	{ label: 'January', value: 'January' },
	{ label: 'February', value: 'February' },
	{ label: 'March', value: 'March' },
	{ label: 'April', value: 'April' },
	{ label: 'May', value: 'May' },
	{ label: 'June', value: 'June' },
	{ label: 'July', value: 'July' },
	{ label: 'August', value: 'August' },
	{ label: 'September', value: 'September' },
	{
		label: 'October',
		value: 'October',
	},
	{ label: 'November', value: 'November' },
	{ label: 'December', value: 'December' },
];

export const MonthValueOptions: SelectOption[] = [
	{ label: 'January', value: '1' },
	{ label: 'February', value: '2' },
	{ label: 'March', value: '3' },
	{ label: 'April', value: '4' },
	{ label: 'May', value: '5' },
	{ label: 'June', value: '6' },
	{ label: 'July', value: '7' },
	{ label: 'August', value: '8' },
	{ label: 'September', value: '9' },
	{
		label: 'October',
		value: '10',
	},
	{ label: 'November', value: '11' },
	{ label: 'December', value: '12' },
];
export const VoidReasonMultiple: SelectOption[] = [
	{ label: 'Altered Check', value: 'Altered Check' },
	{ label: 'Cancelled Payment', value: 'Cancelled Payment' },
	{ label: 'Cash encoded as Check', value: 'Cash encoded as Check' },
	{ label: 'Check encoded as Cash', value: 'Check encoded as Cash' },
	{ label: 'Post Dated Check', value: 'Post Dated Check' },
	{ label: 'Staled Check', value: 'Staled Check' },
	{ label: 'Unsigned Check', value: 'Unsigned Check' },
	{ label: 'Wrong Bank Code', value: 'Wrong Bank Code' },
	{ label: 'Wrong Check Number', value: 'Wrong Check Number' },
	{
		label: 'Wrong Amount in Words & Figures',
		value: 'Wrong Amount in Words & Figures',
	},
	{ label: 'Wrong Amount', value: 'Wrong Amount' },
	{ label: 'Wrong Biller', value: 'Wrong Biller' },
	{ label: 'Wrong Bill Presented', value: 'Wrong Bill Presented' },
	{ label: 'Wrong System Date', value: 'Wrong System Date' },
	{
		label: 'Wrong Customer Account / Reference No.',
		value: 'Wrong Customer Account / Reference No.',
	},
	{ label: 'Invalid Account No.', value: 'Invalid Account No.' },
	{ label: 'Wrong Payment Type', value: 'Wrong Payment Type' },
	{
		label: 'Already paid by the Customer',
		value: 'Already paid by the Customer',
	},
	{ label: 'Voided/Cancelled by Biller', value: 'Voided/Cancelled by Biller' },
	{ label: 'For Refund/Reversal', value: 'For Refund/Reversal' },
	{ label: 'Customer Error', value: 'Customer Error' },
	{ label: 'Customer Initiated Request', value: 'Customer Initiated Request' },
	{ label: 'Teller Error', value: 'Teller Error' },
	{ label: 'No Payment Received', value: 'No Payment Received' },
	{ label: 'Erroneous Deposit', value: 'Erroneous Deposit' },
	{ label: 'Over Deposit', value: 'Over Deposit' },
	{ label: 'Under Deposit', value: 'Under Deposit' },
	{ label: 'Double Posting', value: 'Double Posting' },
	{ label: 'System Error', value: 'System Error' },
	{ label: 'No Teller Code', value: 'No Teller Code' },
	{ label: 'Unique Constraint', value: 'Unique Constraint' },
	{ label: 'Others', value: 'Others' },
];

export const WalletType: SelectOption[] = [
	{ label: 'Channel Wallet - Prefunded', value: 'prefunded' },
	{ label: 'Channel Wallet - Bonded', value: 'bonded' },
	{ label: 'Product Wallet', value: 'cws' },
];

export const WalletTypeObj: {
	cws: string;
	bonded: string;
	prefunded: string;
} = {
	cws: 'Product Wallet',
	bonded: 'Channel Wallet - Bonded',
	prefunded: 'Channel Wallet - Prefunded',
};

export const ChannelLevel: SelectOption[] = [
	{
		label: 'TPA Account',
		value: 'TPA_ACCOUNT',
	},
	{
		label: 'Branch',
		value: 'BRANCH',
	},
	{
		label: 'TPA ID',
		value: 'TPA_ID',
	},
];

export const FileFormat: string[] = ['CSV', 'XSL', 'TXT'];

// export const UploadSchedule: string[] = ['Same Day', 'Next Day'];

export const UploadSchedule: SelectOption[] = [
	{
		label: 'Same Day',
		value: 'SAME',
	},
	{
		label: 'Next Day',
		value: 'NEXT',
	},
];

export const CashFlowServiceTypes: SelectOption[] = [
	{ label: 'Wallet Replenishment', value: 'WALLET_REPLENISHMENT' },
	{ label: 'Wallet Adjustment - Credit', value: 'WALLET_ADJUSTMENT_CREDIT' },
	{ label: 'Wallet Adjustment - Debit', value: 'WALLET_ADJUSTMENT_DEBIT' },
	{ label: 'Wallet Adjustment - Auto Reversal', value: 'REVERSAL' },
];

export const CashFlowThresholdStatuses: SelectOption[] = [
	{
		label: 'Above Threshold',
		value: 'ABOVE_THRESHOLD',
	},
	{
		label: 'On Threshold',
		value: 'ON_THRESHOLD',
	},
	{
		label: 'Below Threshold',
		value: 'BELOW_THRESHOLD',
	},
];

export const ProductApprovalListStatuses: SelectOption[] = [
	{
		label: 'For Review',
		value: 'FOR_REVIEW',
	},
	{
		label: 'Approved',
		value: 'APPROVED',
	},
	{
		label: 'Rejected',
		value: 'REJECTED',
	},
];

export const WalletReplenishmentServiceTypes: SelectOption[] = [
	{
		label: 'Wallet Replenishment - Credit',
		value: 'WALLET_REPLENISHMENT',
	},
];

export const WalletStatuses: SelectOption[] = [
	{ label: '', value: null },
	{ label: 'Pending', value: 'Pending' },
	{ label: 'Approved', value: 'Approved' },
	{ label: 'Declined', value: 'Declined' },
];

export const WalletAdjTypeObj: {
	WALLET_ADJUSTMENT_CREDIT: string;
	WALLET_ADJUSTMENT_DEBIT: string;
} = {
	WALLET_ADJUSTMENT_CREDIT: 'Wallet Adjustment - Credit',
	WALLET_ADJUSTMENT_DEBIT: 'Wallet Adjustment - Debit',
};

export const Frequency: SelectOption[] = [
	{ label: '1', value: '1' },
	{ label: '2', value: '2' },
	{ label: '3', value: '3' },
	{ label: '4', value: '4' },
	{ label: '5', value: '5' },
	{ label: '6', value: '6' },
	{ label: '7', value: '7' },
	{ label: '8', value: '8' },
	{ label: '9', value: '9' },
	{ label: '10', value: '10' },

];
export const FrequencyType: SelectOption[] = [
	{
		label: 'Daily',
		value: 'DAILY',
	},
	{
		label: 'Monthly',
		value: 'MONTHLY',
	},
	{
		label: 'Yearly',
		value: 'YEARLY',
	},

];
