import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import createStore from './redux/createStore';
import { App } from './containers';
import Login from './containers/Login/Login';
import { callback } from './utils/callback';
import { showSessionExpiry } from './utils/sessionTimeout';
import { toggleModal } from './redux/modules/timeout';
import { createBrowserHistory } from 'history';
import { setupInterceptors } from './helpers/ApiClient';
import { ReducerStateType } from 'redux/modules/reducers';
import { showAccessDeniedModal } from 'redux/modules/access';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './helpers/ApolloClient';
import { transformScopesToPermissions } from 'utils/permissions';
import { SnackbarProvider } from 'notistack';

const root = document.getElementById('root');
const token = localStorage.getItem('token');

const urlParams = new URLSearchParams(window.location.search);
const code = urlParams.get('code');
const state = urlParams.get('state');

if (token) {
	let userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
	const { scopes } = userInfo;

	const permissions = transformScopesToPermissions(scopes);

	userInfo = { ...userInfo, permissions };

	const initialState: Partial<ReducerStateType> = {
		userInfo,
	};

	const history = createBrowserHistory();
	const store = createStore(initialState, history);
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: false,
				refetchOnWindowFocus: false,
			},
		},
	});

	setupInterceptors(store, history);

	store.subscribe(() => {
		if (window.resetTimeout) {
			window.resetTimeout = false;
			clearTimeout(window.sessionTimeout);
			showSessionExpiry(() => {
				store.dispatch(toggleModal());
			});
		}
	});

	window['showAccessDeniedModal'] = () =>
		store.dispatch(showAccessDeniedModal());

	showSessionExpiry(() => {
		store.dispatch(toggleModal());
	});

	ReactDOM.render(
		// <React.StrictMode>
		<SnackbarProvider maxSnack={3} style={{ backgroundColor: '#FFF', color: '#3b3b3b' }}>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<ApolloProvider client={apolloClient}>
						<App history={history} />
					</ApolloProvider>
				</QueryClientProvider>
			</Provider>
		</SnackbarProvider>,
		// </React.StrictMode>,
		root
	);
} else if (code && state) {
	if (!(state && state.length > 0 && state === localStorage.getItem('state'))) {
		ReactDOM.render(<h1>State did not match.</h1>, root);
	}
	callback(code);
	ReactDOM.render(<h2>Loading...</h2>, root);
} else {
	ReactDOM.render(<Login />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
