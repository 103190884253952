import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	channelType: '',
	search: '',
	channels: [],
	meta: {},
	selectedChannel: null,
};

export type GetChannelsParams = {
	name?: string;
	channelType?: string;
	sortBy?: string;
	sort?: string;
	limit?: number;
	page?: number;
};

const channelList = createSlice({
	name: 'channels',
	initialState,
	reducers: {
		prepare: (state: any, { payload }) => {
			if (payload.channelType != null) {
				state.channelType = payload.channelType;
			}

			if (payload.name != null) {
				state.search = payload.name;
			}
		},
		load: (state, payload: any) => {
			state.channels = payload.result.result;
			state.meta = payload.result.meta;
		},
		error: (state, payload) => {},
		selectChannel: (state: any, { payload }) => {
			state.channels = state.channels.map((channel: any) => {
				if (channel.id === payload.selected) {
					state.selectedChannel = channel;
					return {
						...channel,
						isSelected: true,
					};
				}
				return {
					...channel,
					isSelected: false,
				};
			});
		},
		clearSelectedChannel: (state: any) => {
			state.selectedChannel = null;
		},
	},
});

const { prepare, load, error, selectChannel, clearSelectedChannel } =
	channelList.actions;

const getChannels = (payload: GetChannelsParams = {}) => {
	return {
		types: [prepare.type, load.type, error.type],
		promise: (client: any, state: any) => {
			const { limit, page } = payload;
			const params: GetChannelsParams = {
				sortBy: 'name',
				sort: 'ASC',
				limit,
				page,
			};
			const { channelType, search } = state.channels;

			if (channelType !== '') {
				params.channelType = channelType.toUpperCase();
			}

			if (search !== '') {
				params.name = search;
			}

			return client.get('/v1/cws/util/accounts', {
				params,
			});
		},
		payload,
	};
};

export { getChannels, selectChannel, clearSelectedChannel };

export default channelList.reducer;
