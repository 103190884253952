import React, { useEffect, useState } from 'react';
import { SectionRow } from 'components/Section/Section';
import Grid from 'components/Grid/Grid';
import TextField from '../TextField/TextField';
import Checkbox from 'components/Checkbox/Checkbox';
import InputWithIcon from '../InputWithIcon/InputWithIcon';
import styles from './SearchableCheckbox.module.css';
import { Control, Controller } from 'react-hook-form';
import cx from 'classnames';
import { array } from 'yup';
import Label from '../Label/Label';

type Props = {
	label: string;
	name: string;
	control?: Control<any>;
	options: Array<any>;
	itemPerColumn?: number;
	others?: boolean;
	required?: boolean;
	otherTextboxName?: string;
	defaultValue?: {
		choices: string;
		otherText: string | undefined;
	};
};

type TOptionState = {
	option: string;
	key: number;
	isSelected: boolean;
};

const SearchableCheckbox: React.FC<Props> = ({
	control,
	label,
	name,
	options,
	itemPerColumn = 5,
	others = true,
	required = false,
	otherTextboxName = 'others',
	defaultValue = {},
}) => {
	const _arrSet = new Set(
		defaultValue?.choices && defaultValue?.choices.split(',')
	);

	const [optionsState, setOptionsState] = useState<Array<TOptionState>>(
		options.map((option: string, i: number): TOptionState => {
			return {
				option,
				key: i,
				isSelected: _arrSet.has(option),
			};
		})
	);

	const [allOptions, setAllOptions] =
		useState<Array<TOptionState>>(optionsState);
	const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
	const [otherText, setOtherText] = useState<string>('');

	let firstColumnIndex = 0,
		secondColumnIndex = 0;

	if (options.length) {
		firstColumnIndex = Math.ceil(
			allOptions.length / 2 >= itemPerColumn
				? allOptions.length / 2
				: allOptions.length < itemPerColumn
				? allOptions.length
				: allOptions.length - (allOptions.length - itemPerColumn)
		);
		secondColumnIndex =
			firstColumnIndex >= itemPerColumn
				? allOptions.length - firstColumnIndex
				: 0;
	}

	const clearOthersTextbox = (selectedOption: any) => {
		if (
			selectedOption.option?.toLocaleLowerCase() === 'others' &&
			selectedOption.isSelected === false
		)
			setOtherText('');
	};

	const clickSelectAll = (callback = (fieldChange: any) => {}) => {
		setIsSelectAll(!isSelectAll);
		const optionArray = optionsState.map((options) => {
			clearOthersTextbox(options);
			return { ...options, isSelected: !isSelectAll };
		});
		setOptionsState(optionArray);
		callback && callback(populatedSelected(optionArray));
	};

	const onChangeSearch = (v: string) => {
		const optionsFound = optionsState.filter(({ option }) =>
			option.toLowerCase().includes(v.trim().toLowerCase())
		);
		setAllOptions(v.trim() ? optionsFound : optionsState);
	};

	const onClickCheckboxItem = (i: number) => {
		const shadowOptions = optionsState.slice();
		shadowOptions[i].isSelected = !shadowOptions[i].isSelected;

		if (!shadowOptions[i].isSelected && isSelectAll) {
			setIsSelectAll(!isSelectAll);
		} else {
			const _found = optionsState.some((_oS) => _oS.isSelected === false);

			if (!_found) {
				setIsSelectAll(true);
			}
		}

		clearOthersTextbox(shadowOptions[i]);
		setOptionsState(shadowOptions);
	};

	const populatedSelected = (options = optionsState, text = otherText) => {
		const optionObj = {
			options: options
				.filter((option) => option.isSelected)
				.map(({ option }) => option),
			[otherTextboxName]:
				options?.find(
					({ option, isSelected }) =>
						option?.toLowerCase() === 'others' && isSelected
				) && text,
		};
		return optionObj;
	};

	const renderCheckBoxItems = ({
		option,
		key,
		field,
	}: any): React.ReactNode => (
		<>
			<Checkbox
				className=""
				key={key}
				checked={optionsState[key]?.isSelected}
				label={option}
				onChange={() => {
					onClickCheckboxItem(key);
					field.onChange(populatedSelected());
				}}
				isUseState={false}
			/>
			{others &&
				option?.toLowerCase() === 'others' &&
				optionsState[key]?.isSelected && (
					<TextField
						name={`${name}.${otherTextboxName}`}
						label=""
						placeholder="Others"
						multiLine
						value={otherText}
						control={control}
						onChange={(v) => {
							setOtherText(v);
						}}
						defaultValue={defaultValue?.otherText}
					/>
				)}
		</>
	);

	return (
		<Controller
			name={name}
			render={(props: any) => {
				const {
					field,
					fieldState: { error },
				} = props;

				return (
					<div>
						<Label required={required}>{label}</Label>
						<div
							className={cx(
								error
									? styles.componentContainerError
									: styles.componentContainer
							)}
						>
							<Checkbox
								className=""
								checked={isSelectAll}
								label="Select All"
								onChange={() => {
									clickSelectAll(field.onChange);
								}}
								isUseState={false}
							/>
							<div className={styles.searchBar}>
								<InputWithIcon
									placeholder="Search"
									onChange={(e: any) => onChangeSearch(e.target?.value)}
									icons={[
										{
											path: 'utility/search',
											isLeft: true,
										},
									]}
								/>
							</div>

							<div className={styles.itemContainer}>
								<SectionRow>
									<Grid column size={1} of={2}>
										<div className={styles.firstItemColumn}>
											{firstColumnIndex ? (
												allOptions
													.slice(
														0,
														allOptions.length > itemPerColumn
															? firstColumnIndex
															: undefined
													)
													.map(
														({ option, key }): React.ReactNode =>
															renderCheckBoxItems({ option, key, field })
													)
											) : (
												<></>
											)}
										</div>
									</Grid>

									<Grid column size={1} of={2}>
										<div className={styles.secondItemColumn}>
											{secondColumnIndex > 0 &&
												allOptions
													.slice(-secondColumnIndex)
													.map(
														({ option, key }): React.ReactNode =>
															renderCheckBoxItems({ option, key, field })
													)}
										</div>
									</Grid>
								</SectionRow>
							</div>
						</div>
						{error && error?.options?.message && (
							<div
								className={cx({
									'slds-has-error': !!error,
								})}
							>
								<div className={cx(styles.helper, 'slds-form-element__help')}>
									{error?.options?.message}
								</div>
							</div>
						)}
					</div>
				);
			}}
			control={control}
		/>
	);
};

export default SearchableCheckbox;
