import VoidListFilter from './VoidListFilter';
import VoidListTable from './VoidListTable';
import HTTP from 'helpers/ApiClient';
import { useEffect, useMemo, useState } from 'react';
import FullPageLoader from 'components/Loader/FullPageLoader/FullPageLoader';
import { useToggle } from 'utils/hooks';
import ErrorModal, {
	ErrorModalActions,
	ErrorModalBody,
} from 'components/Modal/ErrorModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import styles from './VoidList.module.css';
import { resolveValue } from 'utils/common';
import { useSelector, useDispatch } from 'react-redux';
import { get as _get } from 'lodash';
import moment from 'moment';
import { showAccessDeniedModal } from 'redux/modules/access';
import { calibrateDate } from 'utils/date';

const CREATE_CHANNEL_WALLET_SCOPE_NAME = 'cws.wallet.create';

type TInitialVal = {
	dateFrom: any;
	dateTo: any;
	channelName: string;
	voidReason: string;
	walletType: string;
	status: string;
	transactionNumber: any;
};
const initialValues: TInitialVal = {
	dateFrom: null,
	dateTo: null,
	channelName: '',
	voidReason: '',
	walletType: '',
	status: '',
	transactionNumber: '',
};
type TValues = {
	page: number;
	limit: number;
	sortBy: string;
	sort: any;
	dateCreatedFrom: any;
	dateCreatedTo: any;
	dateFromAndTo: string;
	channelName: string;
	status: string;
	transactionNumber: string;
	voidReason: string;
};

async function getVoidList(values: any = {}) {
	const {
		page = 1,
		limit = 25,
		sortBy,
		sort,
		dateCreatedFrom,
		dateCreatedTo,
		dateFromAndTo,
		channelName,
		status,
		transactionNumber,
		voidReason,
	}: TValues = values;

	const params = {
		sortBy,
		sort,
		page,
		limit,
		dateCreatedFrom,
		dateCreatedTo,
		dateFromAndTo,
		channelName: resolveValue(channelName),
		status: resolveValue(status),
		transactionNumber: resolveValue(transactionNumber),
		voidReason,
	};

	const result = await HTTP.get('/v1/void/request', { params });
	return result.data;
}

const VoidList = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(0);
	const [pageSize, setPageSize] = useState(25);
	const [sortBy, setSortBy] = useState('date_created');
	const [sort, setSort] = useState('desc');

	const {
		value: isLoading,
		valueOn: showLoading,
		valueOff: hideLoading,
	} = useToggle();

	const {
		value: isErrorModalShown,
		valueOn: showErrorModal,
		valueOff: hideErrorModal,
	} = useToggle();

	const dispatch = useDispatch();

	const [lastParams, setLastParams] = useState({});

	const scopes = useSelector((state) =>
		_get(state, ['userInfo', 'scopes'], [])
	);

	const createChannelWalletScope = useMemo(
		() =>
			scopes.find(
				({ scope }: any) => scope === CREATE_CHANNEL_WALLET_SCOPE_NAME
			),
		[scopes]
	);

	const fetchVoids = async (values = {}, retry = false) => {
		const doRequest = async (p: any) => {
			try {
				showLoading();
				setLastParams(p);
				const result = await getVoidList(p);
				const { meta } = result;
				setData(result.data);
				setPage(meta.page);
				setPageSize(meta.pageSize);
				setCount(meta.total);
			} catch (e: any) {
				if (e.response.status === 403) {
					dispatch(showAccessDeniedModal());
				} else {
					showErrorModal();
				}
			} finally {
				hideLoading();
			}
		};

		if (retry) {
			doRequest(values);
			return;
		}

		const {
			dateFrom: dateCreatedFrom,
			dateTo: dateCreatedTo,
			...rest
		}: any = values;

		const dates = calibrateDate(dateCreatedFrom, dateCreatedTo);

		const params = {
			sortBy,
			sort,
			page,
			limit: pageSize,
			...dates,
			...rest,
			...values,
		};

		await doRequest(params);
	};
	useEffect(() => {
		fetchVoids();
	}, []);

	const handleFilterSubmit = async (values: any) => {
		const {
			channelName = {},
			dateFrom,
			dateTo,
			serviceType,
			status,
			transactionNumber,
			voidReason,
		} = values;
		await fetchVoids({
			channelName: channelName.label,
			dateFrom,
			dateTo,
			serviceType,
			status,
			transactionNumber,
			voidReason,
		});
	};

	const handlePageChange = async (page: number, pageSize: number) => {
		setPage(page);
		setPageSize(pageSize);
		await fetchVoids({ page, limit: pageSize });
	};

	const handleSort = async (sortBy: string, sort: any) => {
		setSortBy(sortBy);
		setSort(sort);
		await fetchVoids({ sortBy, sort });
	};

	const handleRetry = () => {
		hideErrorModal();
		if (lastParams) {
			fetchVoids(lastParams, true);
		}
	};

	return (
		<>
			<VoidListFilter
				initialValues={initialValues}
				onSubmit={handleFilterSubmit}
				canCreateChannelWallet={!!createChannelWalletScope}
				initialArray={[]}
			/>
			<VoidListTable
				page={page}
				data={data}
				pageSize={pageSize}
				count={count}
				onPageChange={handlePageChange}
				onSort={handleSort}
				sortBy={sortBy}
				sort={sort}
			/>
			<FullPageLoader
				open={isLoading}
				message="Please wait while void request list is being loaded"
			/>
			<ErrorModal open={isErrorModalShown} onClose={hideErrorModal}>
				<ErrorModalBody>
					<div className={styles.errorBody}>
						<div>A problem occurred while</div>
						<div>loading the void request list.</div>
					</div>
					<div className={styles.errorFooter}>Please try again</div>
				</ErrorModalBody>
				<ErrorModalActions>
					<PrimaryButton
						fullWidth
						onClick={handleRetry}
						className={styles.retryBtn}
					>
						Retry
					</PrimaryButton>
				</ErrorModalActions>
			</ErrorModal>
		</>
	);
};

export default VoidList;
