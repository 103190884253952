import yup, {
	multipleContactNumberSchema,
	multipleEmailSchema,
	selectDefaultRequiredTemplate,
	defaultInvalidCharacter,
	hasSpecialCharacter,
} from '../common';

const basicInformationSchema = yup.object().shape({
	channelName: yup
		.string()
		.label('Channel Name')
		.required()
		.max(255)
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	inCfs: yup.mixed().label('Customer Frontline Solutions (CFS)').required(),
	businessGroup: yup.string().label('Business Group').required().max(255),
	channelTransactionType: yup
		.string()
		.label('Channel Transaction Type')
		.required(selectDefaultRequiredTemplate),
	channelType: yup.string().label('Channel Type').required(),
	businessType: yup
		.string()
		.label('Business Type')
		.when('channelType', {
			is: (channelType?: string) => {
				return channelType && channelType.toLowerCase() === 'partner';
			},
			then: yup.string().required(selectDefaultRequiredTemplate),
		}),
	otherBusinessType: yup
		.string()
		.label('Other Business Type')
		.when('businessType', {
			is: (businessType?: string) => {
				return businessType && businessType.toLowerCase() === 'others';
			},
			then: yup
				.string()
				.required()
				.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
		})
		.max(255),
	tin: yup
		.string()
		.label('TIN')
		.required()
		.matches(/^[0-9]{12}$/, {
			excludeEmptyString: true,
			message: 'Input 12 numeric character only',
		}),
});

const channelAddressEntrySchema = yup.object().shape({
	locationBase: yup.string().oneOf(['LOCAL', 'INTERNATIONAL']),
	country: yup
		.string()
		.label('Country')
		.when('locationBase', {
			is: 'INTERNATIONAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
			otherwise: (s) => s.transform(() => '1').notRequired(),
		}),
	province: yup
		.string()
		.label('State/Province')
		.required()
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	city: yup
		.string()
		.label('Municipality/City')
		.required()
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	barangay: yup
		.string()
		.label('Barangay')
		.required()
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	zipCode: yup.string().label('Zip Code'),
	area: yup
		.string()
		.label('Area')
		.required()
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	region: yup
		.string()
		.label('Region')
		.required()
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) => s.required(selectDefaultRequiredTemplate),
		}),
	street: yup
		.string()
		.label('Street')
		.required()
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) =>
				s
					.required()
					.max(255, 'You have reached maximum character input')
					.test('', defaultInvalidCharacter, (v: any) =>
						hasSpecialCharacter(v)
					),
		}),
	buildingNo: yup
		.string()
		.label('Building Name/No')
		.when('locationBase', {
			is: 'LOCAL',
			then: (s) =>
				s
					.required()
					.max(255, 'You have reached maximum character input')
					.test('', defaultInvalidCharacter, (v: any) =>
						hasSpecialCharacter(v)
					),
		}),
});

const channelAddressSchema = yup.array().of(channelAddressEntrySchema);

const channelContactDetailsEntrySchema = yup.object().shape({
	name: yup
		.string()
		.label('Channel Contact Name')
		.max(255, 'You can only input a max of 255 characters')
		.required()
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	areaHandled: yup.string().label('Area Handled').notRequired(),
	position: yup
		.string()
		.label('Position')
		.max(255, 'You can only input a max of 255 characters')
		.required()
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	department: yup
		.string()
		.label('Department')
		.max(255)
		.required()
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	email: multipleEmailSchema.label('Channel Contact Email Address'),
	contactNumber: multipleContactNumberSchema.label('Channel Contact Number'),
});

const channelContactDetailsSchema = yup
	.array()
	.of(channelContactDetailsEntrySchema);

const accountOfficerSchema = yup.object().shape({
	name: yup
		.string()
		.label('Account Officer Name')
		.max(255)
		.required()
		.test('', defaultInvalidCharacter, (v: any) => hasSpecialCharacter(v)),
	email: multipleEmailSchema.label('Account Officer Email Address'),
	contactNumber: multipleContactNumberSchema.label(
		'Account Officer Contact Number'
	),
});

const primaryInformationSchema = yup.object().shape({
	basicInformation: basicInformationSchema,
	channelAddresses: channelAddressSchema,
	channelContactDetails: channelContactDetailsSchema,
	accountOfficer: accountOfficerSchema,
});

export default primaryInformationSchema;
