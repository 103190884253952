export const handleArrayValues = (value) => {
	return Array.isArray(value) ? value.map((v) => v.trim()).join(', ') : value;
};
export const handleArrayWithSpaceValues = (value) =>
	Array.isArray(value)
		? value.map((v) => v.trim()).join(', ')
		: typeof value === 'string' || typeof value === 'number'
		? String(value).replace(/[\[\]']/g, '')
		: value;

export const removeCharacterInStringValues = (value) => {
	return value ? value.substring(37) : value;
};

export const filterArrayValues = (v) => {
	const hasValidNewValue =
		v && typeof v.new_values === 'string' && v.new_values.trim() !== '';
	const hasValidOldValue =
		v && typeof v.old_values === 'string' && v.old_values.trim() !== '';
	return hasValidOldValue || hasValidNewValue || (v && v.old_values === '-');
};

export const convertValue = (value) => {
	return value === '1' || value === 1
		? 'Yes'
		: value === '0' || value === 0
		? 'No'
		: value;
};
export const convertValueToString = (value) => {
	return typeof value === 'number' ? String(value) : value;
};
