import { Modal } from '@salesforce/design-system-react/module/components';
import { useEffect, useState } from 'react';
import Grid from 'components/Grid/Grid';
import styles from './FilePreviewModal.module.css';
import { ImageFileTypes } from 'components/FilePreview/FileThumbnail';
import { Viewer } from '@react-pdf-viewer/core';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as NoPreviewIcon } from 'assets/icons/ic-no-preview.svg';
import { ReactComponent as TimeoutIcon } from 'assets/icons/ic-broken-image.svg';
import downloadButton from '../../assets/icons/ic-download4.svg';
import { useToggle } from 'utils/hooks';
import HTTP from 'helpers/ApiClient';
import Button from 'components/Buttons/Button';

type FilePreviewModalProps = {
	open?: boolean;
	onClose?: () => void;
	file?: any;
	downloadUrl?: string;
	handleRetry?: () => void;
};
type ImagePreviewProps = {
	url: string;
	alt?: string;
};

const ImagePreview: React.FC<ImagePreviewProps> = ({
	url: filePath,
	alt = 'preview',
}) => <img src={filePath} alt={alt} className={styles.imagePreview} />;

const FilePreview: React.FC<{
	fileType: string;
	filePath: string;
	error?: boolean;
	handleRetry?: () => void;
}> = ({ filePath, fileType, error, handleRetry }) => {
	const f = fileType.toLowerCase();
	if (error) {
		return (
			<div className={styles.noPreview}>
				<TimeoutIcon />
				<span style={{ fontSize: '0.7vw' }}>
					Failed to load, Kindly Click Reload.
				</span>
				{handleRetry && (
					<Button onClick={handleRetry} className={styles.retryButton}>
						Retry
					</Button>
				)}
			</div>
		);
	}
	if (ImageFileTypes.includes(f)) {
		return <ImagePreview url={filePath} />;
	}

	if (f === 'pdf') {
		return <Viewer fileUrl={filePath} />;
	}

	return (
		<div className={styles.noPreview}>
			<NoPreviewIcon />
			<span style={{ fontSize: '0.7vw' }}>This file has no preview.</span>
		</div>
	);
};

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({
	open,
	onClose,
	file,
	downloadUrl,
	handleRetry,
}) => {
	const [fileUrl, setFileUrl] = useState<any>('');
	useEffect(() => {
		if (file.url) {
			setFileUrl(file.url);
		} else {
			setFileUrl(URL.createObjectURL(file.file));
		}
	}, [file]);
	const [response, setResponse] = useState({
		isOpen: false,
		bodyText: '',
		bodyHeader: 'File is being downloaded',
		respondButton: {
			name: 'Done',
			event: () => {
				setResponse({
					...response,
					isOpen: false,
				});
			},
		},
		isRespondButton2Shown: false,
		small: true,
	});
	const handleDownload = () => {
		fetch(file.url, { params: { key: file.fileKey } })
			.then((resp) => resp.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = file.fileName.slice(37);
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			});
	};
	return (
		<>
			{file?.name}
			{file && (
				<Modal
					isOpen={open}
					onRequestClose={onClose}
					headerClassName={styles.header}
					size="small"
					containerClassName={styles.modalContainer}
					contentClassName={styles.modal}
				>
					<Grid vertical verticalAlign="center" className={styles.container}>
						<div className={styles.title}>
							{file.fileName}
							{downloadUrl && (
								<img
									src={downloadButton}
									key={file}
									alt="download"
									className={styles.download}
									onClick={() => handleDownload()}
									type="button"
								/>
							)}
							<span className={styles.closeButton} onClick={onClose}>
								<CloseIcon height="22px" width="22px" />
							</span>
						</div>

						<Grid column className={styles.modalBody}>
							<FilePreview
								fileType={file.fileName?.split('.').slice(-1)[0]}
								filePath={fileUrl}
								// error={file?.url?.error ?? false}
								handleRetry={handleRetry}
							/>
						</Grid>
					</Grid>
				</Modal>
			)}
		</>
	);
};

export default FilePreviewModal;
